import React, {useState, useEffect, useRef} from 'react'
import ReactLoading from 'react-loading'

import Swal from 'sweetalert2'

import {GoogleMap, useLoadScript, Marker, InfoWindow} from '@react-google-maps/api'
import {FaFileCsv, FaFilePdf, FaCheck} from 'react-icons/fa'
import {FiArrowLeft, FiExternalLink, FiFilter} from 'react-icons/fi'
import {AiOutlineArrowUp, AiOutlineArrowDown} from 'react-icons/ai'
import {ImCompass2} from 'react-icons/im'
import {GoLocation} from 'react-icons/go'

//
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import {Impressao} from '../../services/pdImage'
import {CSVLink} from 'react-csv'
//

import api from '../../services/api'
import Menu from '../../components/Menu'

import NoImg from "../../assets/sem_foto.png"

import ExportCSV from '../../components/exportCSV'
import ExportPDF from '../../components/exportPDF'
import HalfPieChart from '../../components/ReCharts/HalfPieChart'
import { Container, Row, Col, Table, Button, Dropdown, Form, Modal, FloatingLabel, CloseButton, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'

// @mui
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import { useTranslation } from 'react-i18next'
import {useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { BsArrowRight } from 'react-icons/bs'
import { Backdrop, Chip, CircularProgress, InputAdornment, Pagination, Stack, TextField, Typography } from '@mui/material'

import EditItem from './EditItens'
import Dashboard from './Dashboard'
import { Icon } from '@iconify/react/dist/iconify.js'
import axios from 'axios'

const libraries = ['places']
const mapContainerStyle = {
    width: '100%',
    height: '600px'
}
const optionsMap = {}

const filterOptions = [
    'Encontrado',
    'Ausente',
    'Auditado',
    'Excedente',
    'Outro_Ambiente',
    'Incorporado'
];

function Inventories() {
    const { t } = useTranslation();
    const history = useNavigate()

    const [countPage, setCountPage] = useState()

    const [totalItens, setTotalItens] = useState()


    const [items, setItems] = useState([])
    const [dataInicio, setDataInicio] = useState(dates(7, '-'))//(dates(31, '-'))
    const [dataFim, setDataFim] = useState(dates(1, '+'))
    const [identificador, setIdentificador] = useState('*')
    const [parceiroList, setParceiroList] = useState([])
    const [parceiro, setParceiro] = useState('*')
    const [changeNew, setChangeNew] = useState(0)
    const [idRegistro, setIdRegistro] = useState('0')
    const [tipoRegistro, setTipoRegistro] = useState('')
    const [modoInventario, setModoInventario] = useState('p')
    const [identificadorInventario, setIdentificadorInventario] = useState('')
    const [statusInventario, setStatusInventario] = useState('')
    const [qtdEstimada, setQtdEstimada] = useState(0)
    const [encontradoInventario, setEncontradoInventario] = useState('')
    const [excedenteInventario, setExcedenteInventario] = useState('')
    const [dtRegistro, seDtRegistro] = useState(now())
    const [dtAlteracao, setDtAlteracao] = useState(now())
    const [tempoInventario, setTempoInventario] = useState('')
    const [nivelLoc1List, setNivelLoc1List] = useState([])
    const [nivelLoc2List, setNivelLoc2List] = useState([])
    const [nivelLoc3List, setNivelLoc3List] = useState([])
    const [nivelLoc4List, setNivelLoc4List] = useState([])
    const [nivelLoc1, setNivelLoc1] = useState('*')
    const [nivelLoc2, setNivelLoc2] = useState('*')
    const [nivelLoc3, setNivelLoc3] = useState('*')
    const [nivelLoc4, setNivelLoc4] = useState('*')
    const [itemName, setItemName] = useState('')
    const [nivelLoc1ListInventario, setNivelLoc1ListInventario] = useState([])
    const [nivelLoc2ListInventario, setNivelLoc2ListInventario] = useState([])
    const [nivelLoc3ListInventario, setNivelLoc3ListInventario] = useState([])
    const [nivelLoc4ListInventario, setNivelLoc4ListInventario] = useState([])
    const [nivelLoc1Inventario, setNivelLoc1Inventario] = useState('*')
    const [nivelLoc2Inventario, setNivelLoc2Inventario] = useState('*')
    const [nivelLoc3Inventario, setNivelLoc3Inventario] = useState('*')
    const [nivelLoc4Inventario, setNivelLoc4Inventario] = useState('*')
    const [parceiroInventario, setParceiroInventario] = useState('*')
    const [eanInventario, setEanInventario] = useState('')
    const [prazoInventario, setPrazoInventario] = useState('')
    const [observacaoInventario, setObservacaoInventario] = useState('')
    const [latitude, setLatitude] = useState('-23.485230351504622')
    const [longitude, setLongitude] = useState('-46.72570559924746')
    const [foto, setFoto] = useState(NoImg)
    const [pedidoListItens, setPedidoListItens] = useState([])
    const [pedidoFilterListItens, setPedidoFilterListItens] = useState([])
    const [pedidoFilter, setPedidoFilter] = useState("Todas")
    const [tagListItens, setTagListItens] = useState([])
    const [allItens, setAllItens] = useState([])
    const [alertNew ,setAlertNew] = useState('')
    const [inputNew, setInputNew] = useState('')
    const [change, setChange] = useState(0)
    const [editing, setEditing] = useState(0)
    const [reverse, setReverse] = useState(false)
    const [leitorInventario, setLeitorInventario] = useState('0')
    const [leitorInventarioInfo, setLeitorInventarioInfo] = useState(null)
    const [usuarioInventario, setUsuarioInventario] = useState('0')
    const [tipoList, setTipoList] = useState('registro')
    const [showMap, setShowMap] = useState(false)
    const [markList, setMarkList] = useState([])
    const [selectedMark, setSelectedMark] = useState(null)
    const [pageNumber, setPageNumber] = useState(0)
    const [exportList, setExportList] = useState([])
    const [exportListSelected, setExportListSelected] = useState([])
    const [itList, setItList] = useState([])
    const [itemsLoaded, setItemsLoaded] = useState(false)
    const [exportLoaded, setExportedLoaded] = useState(true)
    const [details, setDetails] = useState([])
    const [itemDetails, setItemDetails] = useState([])
    const [descriptionsList, setDescriptionsList] = useState(['', '', '', '', '', ''])
    const [pedidoLoaded, setPedidoLoaded] = useState(true)
    const [listItemsLoaded, setListItemsLoaded] = useState(true)
    const [listExportLoaded, setListExportLoaded] = useState(true)

    const [IDRotas, setIDRotas] = useState()

    const [limitPerPage, setLimitPerPage] = useState(10)

    const [page, setPage] = useState(1)

    const isFirstRender = useRef(true);

    const [tagFilter, setTagFilter] = useState(filterOptions)

    const [runningInventories, setRunningInventories] = useState([])

    const [showRunningInventories, setShowRunningInventories] = useState(false);

    const handleCloseConfirmModal = () => setShowRunningInventories(false);

    const handleShowConfirmModal = () => setShowRunningInventories(true);
    
    const handleNewInventory = () => {
        secureLocalStorage.clear()
        history('/collections/create')
    }

    const [ordenationExport, setOrdenationExport] = useState('')
    const [listaFilterExport, setListaFilterExport] = useState([
        'Ausente',
        'Encontrado',
        'Excedente',
        'Outro_Ambiente',
        'Auditado',
        'Incorporado'
    ])
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
    };

    useEffect(() => {
        if (!isFirstRender.current) {
            handlePagination();
        } else {
            isFirstRender.current = false;
        }
    }, [limitPerPage, page]);

    const handleFilter = ({ target: { value } }) => {
        const tagFilterUpdate = Array.isArray(value) ? value : value.split(',');
        setTagFilter(tagFilterUpdate);
        handleChangeStatus(tagFilterUpdate);
    };
    
    const filterByStatus = (tagList, statusList) => {
        setTagListItens(tagList.filter(item => statusList.includes(item.status)));
    };
    
    const handleChangeStatus = async (statusList) => {
        if (!allItens.length) {
            try {
                const { data } = await api.get(`/registro_tag/*/${idRegistro}/0`);
                getDetails(data.pop());
                const tagsData = data.map(item => ({ ...item, status: getStatus(item) }));
                setAllItens(tagsData);
                filterByStatus(tagsData, statusList);
            } catch (error) {
                setListItemsLoaded(true);
                console.error("Error fetching data:", error);
            }
        } else {
            filterByStatus(allItens, statusList);
        }
    }; 

    const handleChangePage = (event, value) => {
        setPage(value);
    };



    const [categoriaNew, setCategoriaNew] = useState('')
    const [tagNew, setTagNew] = useState('')
    const [ativoNew, setAtivoNew] = useState('1')
    const [inf1New, setInf1New] = useState('')
    const [inf2New, setInf2New] = useState('')
    const [inf3New, setInf3New] = useState('')
    const [inf4New, setInf4New] = useState('')
    const [inf5New, setInf5New] = useState('')
    const [nivelLoc1New, setNivelLoc1New] = useState('')
    const [nivelLoc2New, setNivelLoc2New] = useState('')
    const [nivelLoc3New, setNivelLoc3New] = useState('')
    const [nivelLoc4New, setNivelLoc4New] = useState('')
    const [nivelLoc1NewList, setNivelLoc1NewList] = useState([])
    const [nivelLoc2NewList, setNivelLoc2NewList] = useState([])
    const [nivelLoc3NewList, setNivelLoc3NewList] = useState([])
    const [nivelLoc4NewList, setNivelLoc4NewList] = useState([])
    const [inventariosInativos, setInventariosInativos] = useState(true)
    const [searchItem, setSearchItem] = useState('')
    const [parceiroNew, setParceiroNew] = useState('')
    const [observacaoNew, setObservacaoNew] = useState('')
    const [codExternoNew, setCodExternoNew] = useState('')
    const [categoriaList, setCategoriaList] = useState([])
    const [fotoUp, setFotoUp] = useState('')
    const [fotoToUp, setFotoToUp] = useState(false)
    const [infoNamesList, setInfoNamesList] = useState([])

    const [statusList, setStatusList] = useState(
        [
            { name: 'Ausentes', value: 0 },
            { name: 'Encontrados', value: 0 },
            { name: 'Excedentes', value: 0 },
            { name: 'Outros Amb.', value: 0 },
            { name: 'Auditados', value: 0 },
            { name: 'Incorporados', value: 0 }
        ]
    )
    const [dashItens, setDashItens] = useState([])
    const [dashItensFound, setDashItensFound] = useState([])
    const colorInfo = [
        '#B40C0C',
        '#4EA90B',
        '#A33BBF',
        '#E3AE1B',
        '#08B08C',
        '#26C6DA'
    ]

    const [looper, setLooper] = useState(false)

    const fotoNone = ['', '/static/media/sem_foto.b83884cf.png', 'assets/imgs/sem_foto.png']

    const [exportType, setExportType] = useState(-1)
    const [dataProps, setDataProps] = useState()
    const headersCSV = [
        "ID",
        "ORDEM",
        "DESCRICAO",
        "QUANTIDADE",
        "LIDO",
    ]

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
                getLoop()
            }, 5000)
            return () => clearInterval(interval)
    }, [looper])

    function getLoop() {
        if(looper){
            registroLoopGet()
        }
    }

    function treeCond(cond1, cond2, campo) {
        if(cond1) {
            if(cond1._id && cond1 != '') {
                return cond1[campo]
            }else if(cond1) {
                return cond1
            }else{
                return ''
            }
        }
        else if(cond2 && cond2 != '' ) {
            return cond2
        }else{
            return ''
        }
    }

    useEffect(() => {
        if(change != 0) {
            setLooper(false)
        }
    }, [change])
    
    useEffect(() => {
        getInfoNames()
    }, [])

    useEffect(()=>{
        const controller = new AbortController();
        controller.abort()
    },[change])

    function getInfoNames() {
        setLoading(true)
        api.get(`/conta_config/${sessionStorage.getItem('idConta')}`).then(
            response => {
                let data = response.data[0] ? response.data[0] : []
                const id_rotas = data?.token_id_rotas
                setIDRotas(id_rotas)
                let lista = [
                    data.info_compl_1 && data.info_compl_1 != '' ? data.info_compl_1 : '' ,
                    data.info_compl_2 && data.info_compl_2 != '' ? data.info_compl_2 : '' ,
                    data.info_compl_3 && data.info_compl_3 != '' ? data.info_compl_3 : '' ,
                    data.info_compl_4 && data.info_compl_4 != '' ? data.info_compl_4 : '' ,
                    data.info_compl_5 && data.info_compl_5 != '' ? data.info_compl_5 : '' ,
                    data.info_compl_6 && data.info_compl_6 != '' ? data.info_compl_6 : '' ,
                    data.info_compl_7 && data.info_compl_7 != '' ? data.info_compl_7 : '' ,
                    data.info_compl_8 && data.info_compl_8 != '' ? data.info_compl_8 : '' ,
                    data.info_compl_9 && data.info_compl_9 != '' ? data.info_compl_9 : '' ,
                    data.info_compl_10 && data.info_compl_10 != '' ? data.info_compl_10 : '' 
                ]
                setInfoNamesList(lista)
            }
        ).finally(
            setLoading(false)
        )
    }

    function ordenateListBy(filterList, data) {
        let dataSorted = data
        if(filterList.length > 0 && filterList[0] != '') {
            dataSorted = dataSorted.sort((a,b) => {
                if(filterList.length == 1) {
                    if((a[filterList[0]].length < b[filterList[0]].length)) return -1
                    if((a[filterList[0]].length == b[filterList[0]].length) && (a[filterList[0]] < b[filterList[0]])) return -1
                    if((a[filterList[0]].length == b[filterList[0]].length) && (a[filterList[0]] > b[filterList[0]])) return 1
                    if((a[filterList[0]].length > b[filterList[0]].length)) return 1
                    return 0
                }else if(filterList.length == 4) {
                    if((a[filterList[0]].length < b[filterList[0]].length)) return -1
                    else if((a[filterList[0]].length > b[filterList[0]].length)) return 1
                    else if((a[filterList[0]].length == b[filterList[0]].length) && (a[filterList[0]] < b[filterList[0]])) return -1
                    else if((a[filterList[0]].length == b[filterList[0]].length) && (a[filterList[0]] > b[filterList[0]])) return 1
                    else{
                        if((a[filterList[1]].length < b[filterList[1]].length)) return -1
                        else if((a[filterList[1]].length > b[filterList[1]].length)) return 1
                        else if((a[filterList[1]].length == b[filterList[1]].length) && (a[filterList[1]] < b[filterList[1]])) return -1
                        else if((a[filterList[1]].length == b[filterList[1]].length) && (a[filterList[1]] > b[filterList[1]])) return 1
                        else{
                            if((a[filterList[2]].length < b[filterList[2]].length)) return -1
                            else if((a[filterList[2]].length > b[filterList[2]].length)) return 1
                            else if((a[filterList[2]].length == b[filterList[2]].length) && (a[filterList[2]] < b[filterList[2]])) return -1
                            else if((a[filterList[2]].length == b[filterList[2]].length) && (a[filterList[2]] > b[filterList[2]])) return 1
                            else{
                                if((a[filterList[3]].length < b[filterList[3]].length)) return -1
                                else if((a[filterList[3]].length > b[filterList[3]].length)) return 1
                                else if((a[filterList[3]].length == b[filterList[3]].length) && (a[filterList[3]] < b[filterList[3]])) return -1
                                else if((a[filterList[3]].length == b[filterList[3]].length) && (a[filterList[3]] > b[filterList[3]])) return 1
                                else if((a[filterList[3]].length == b[filterList[3]].length) && (a[filterList[3]] == b[filterList[3]])) return 0
                            }
                        }
                    }
                    return 0
                }
                return 0
            })
        }
        return dataSorted
    }

    function filterPedido(e){
        const value = e.target.value;
        let list;
        list = pedidoListItens.filter((item)=> {
            if(value=="Correto"){
                return item.quantidade == item.quantidade_lida
            }else if(value =="Falta"){
                return Number(item.quantidade) > Number(item.quantidade_lida)
            } else if(value == "Excedente"){
                return Number(item.quantidade) < Number(item.quantidade_lida)
            } else if(value == "*"){
                return pedidoListItens
            }
        })
        
        setPedidoFilterListItens(list)
    }

    function registroLoopGet() {
        if(looper) {
            if(tipoList == 'registro') {
                axios.get(`https://inovabo.herokuapp.com/bd?c=_rota_mov&id_conta=${sessionStorage.getItem('idConta')}`, {}
                ).then(response => {
                    setItems(response.data)
                })
            }else if(tipoList == 'item') {
                let tots = {}
                let ids = []
                api.get(`/registroPage/${sessionStorage.getItem('idConta')}/*/inventario/${dataInicio}/${dataFim}/*/${parceiro}/*/*/${pageNumber * 30}`, {}
                ).then(response => {
                    tots = response.data[response.data.length - 1]
                    ids = response.data.filter((item) => {
                        if(item._id) {
                            return item
                        }
                    }).map((item) => {
                        return item._id
                    })
                }).finally(
                    () => {
                        api.get(`/registro_tag_date/${sessionStorage.getItem('idConta')}/${dataInicio}/${dataFim}?itemName=${itemName}`, {}
                        ).then(subResponse => {
                            setItems([...subResponse.data.filter((item, index) => {
                                if(subResponse.data.length - 1 == index) {
                                }
                                if(ids.includes(item.id_registro)) {
                                    return item
                                }
                            }), tots])
                        })
                    }
                )
            }
        }
    }

    function markersLoader(itensList) {
        window.scroll(0, 0)
        let preList = itensList.filter((item) => {
            if(item.longitude) {
                return item
            }
        })
        if(preList.length == 0) {
            window.alert('Itens não possuem localização')
            
        }else {
            setMarkList(preList)
            setShowMap(true)
        }
    }
    
    function markersUnloader() {
        setMarkList([])
        setShowMap(false)
        setSelectedMark(null)
    }

    useEffect(() => {
        parceiroGet()
    }, [])

    useEffect(() => {
        nivel1Get()
    }, [])

    useEffect(() => {
        nivel2Get(nivelLoc1Inventario)
    }, [nivelLoc1Inventario])

    useEffect(() => {
        nivel3Get(nivelLoc2Inventario)
    }, [nivelLoc2Inventario])
    
    useEffect(() => {
        nivel4Get(nivelLoc3Inventario)
    }, [nivelLoc3Inventario])

    useEffect(() => {
        registroGet()
    }, [IDRotas])

    useEffect(() => {
        nivel1GetList()
    }, [])

    useEffect(() => {
        nivel2GetList(nivelLoc1)
    }, [nivelLoc1])

    useEffect(() => {
        nivel3GetList(nivelLoc2)
    }, [nivelLoc2])
    
    useEffect(() => {
        nivel4GetList(nivelLoc3)
    }, [nivelLoc3])

    useEffect(() => {
        nivel1GetListNew()
    }, [])

    useEffect(() => {
        nivel2GetListNew(nivelLoc1New)
    }, [nivelLoc1New])

    useEffect(() => {
        nivel3GetListNew(nivelLoc2New)
    }, [nivelLoc2New])
    
    useEffect(() => {
        nivel4GetListNew(nivelLoc3New)
    }, [nivelLoc3New])

    useEffect(() => {
        if(change == 5) {
            categoriaGet()
        }
    }, [change])

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_API_G_KEY,
        libraries,
    })

    if(loadError) return (
        <div className="mainContainer"
        style={JSON.parse(sessionStorage.getItem('collors'))}
        >
            <Menu/>
            <div id="background">
                Error loading maps
            </div>
        </div>
    )
    if(!isLoaded) return (
        <div className="mainContainer"
        style={JSON.parse(sessionStorage.getItem('collors'))}
        >
            <Menu/>
            <div id="background">
            </div>
        </div>
    )

    // Função para calcular a porcentagem
    function getPercentage(value, items) {
        const totalValue = items.qtd_ausente + items.qtd_encontrado + items.qtd_auditado + items.qtd_incorporado + items.qtd_outro_ambiente + items.qtd_excedente
        var percentage = (value / totalValue) * 100;
        if(percentage === 100){
            percentage = percentage.toFixed(0)
        }else{
            percentage = percentage.toFixed(2)
        }
        return percentage + "%";
    }

    // Função para calcular a porcentagem
    function getPercentageFound(value, items) {
        const totalValue = items.qtd_ausente + items.qtd_encontrado + items.qtd_auditado
        var percentage = (value / totalValue) * 100;
        if(percentage === 100){
            percentage = percentage.toFixed(0)
        }else{
            percentage = percentage.toFixed(2)
        }
        return percentage + "%";
    }

    function addOrRemoveExportFilter(status) {
        if(status == 'Todos') {
            setListaFilterExport([
                'Ausente',
                'Encontrado',
                'Excedente',
                'Outro_Ambiente',
                'Auditado',
                'Incorporado'
            ])
        }else{
            let lista = listaFilterExport
            let index = lista.findIndex((filter) => filter == status)
            if(index < 0) {
                lista.push(status)
            }else{
                lista.splice(index,1)
            }
            setListaFilterExport(lista.filter((f) => {return f}))
        }
    }

    function registroGet() {
        setItemsLoaded(false)
        if(tipoList == 'registro') {
            const id_conta = IDRotas
            const populate = "&pop=origem_id_localizacao1&pop=origem_id_localizacao2&pop=origem_id_localizacao3&pop=origem_id_localizacao4&pop=destino_id_localizacao1&pop=destino_id_localizacao2&pop=destino_id_localizacao3&pop=destino_id_localizacao4"
            api.get(`https://inovabo.herokuapp.com/bd?c=_rota_mov&id_conta=${id_conta}${populate}&sort=data_reg`, {}
            ).then(response => {
                setItems(response.data)
                setItemsLoaded(true)
            })
        }else if(tipoList == 'item') {
            let tots = {}
            let ids = []
            api.get(`/registroPage/${IDRotas}/*/inventario/${dataInicio}/${dataFim}/*/${parceiro}/*/*/${pageNumber * 30}`, {}
            ).then(response => {
                tots = response.data[response.data.length - 1]
                ids = response.data.filter((item) => {
                    if(item._id) {
                        return item
                    }
                }).map((item) => {
                    return item._id
                })
            }).finally(
                () => {
                    api.get(`/registro_tag_date/${sessionStorage.getItem('idConta')}/${dataInicio}/${dataFim}?itemName=${itemName}`, {}
                    ).then(subResponse => {
                        setItems([...subResponse.data.filter((item, index) => {
                            if(subResponse.data.length - 1 == index) {
                                setItemsLoaded(true)
                            }
                            if(ids.includes(item.id_registro)) {
                                return item
                            }
                        }), tots])
                    })
                }
            )
        }
    }

    async function handleRegisterFilter() {
        setItemsLoaded(false)

        try {
            const id_conta = IDRotas
            const populate = "&pop=origem_id_localizacao1&pop=origem_id_localizacao2&pop=origem_id_localizacao3&pop=origem_id_localizacao4&pop=destino_id_localizacao1&pop=destino_id_localizacao2&pop=destino_id_localizacao3&pop=destino_id_localizacao4"
            api.get(`https://inovabo.herokuapp.com/bd?c=_rota_mov&id_conta=${id_conta}${populate}${ searchItem ? `&id_mov=${searchItem}`: ''}`, {}
            ).then(response => {
                setItems(response.data)
                setItemsLoaded(true)
            })
        } catch (error) {
            console.error("Error fetching tags:", error);
        } finally {
            setListItemsLoaded(true);
        }
    } 

    async function getExportListItems(item) {
        let lista = []
        await api.get(`/registro_tag_date/${sessionStorage.getItem('idConta')}/${'*'}/${dataFim}?itemName=${itemName}`, {}
        ).then(
            response => {
                for(let i = 0; i < response.data.length; i++) {
                    if(response.data[i].descricao == item.descricao){
                        lista.push(response.data[i])
                    }
                }
                setTagListItens(lista)
                setExportType(2)
                setChange(8)
            }
        )        
    }

    function getExportList() {
        setListExportLoaded(false)
        let lista = []
        api.get(`/registro/${sessionStorage.getItem('idConta')}/*/*/${dataInicio}/${dataFim}/*/${parceiro}/*`
        ).then(
            async response => {
                await response.data.map((item, index) => {
                    if(!(item.modo == 'p' || item.modo == 'l')) {
                        if(
                            (nivelLoc1 == '*' || item.id_nivel_loc_1 && item.id_nivel_loc_1._id == nivelLoc1)
                            && (nivelLoc2 == '*' || item.id_nivel_loc_2 && item.id_nivel_loc_2._id == nivelLoc2)
                            && (nivelLoc3 == '*' || item.id_nivel_loc_3 && item.id_nivel_loc_3._id == nivelLoc3)
                            && (nivelLoc4 == '*' || item.id_nivel_loc_4 && item.id_nivel_loc_4._id == nivelLoc4)
                            && (identificador == '*' || item.identificador.search(identificador) > -1)
                        ){
                            lista.push(item)
                        }
                    }
                    if(response.data.length - 1 == index){
                    }
                })
                setExportList(lista)
            }
        ).finally(
            () => {setListExportLoaded(true)}
        )
        setChange(4)
    }

    function addOrRemoveListImport(index) {
        if(exportListSelected.indexOf(index) == -1) {
            setExportListSelected([...exportListSelected, index])
        }else{
            let lindex = exportListSelected.indexOf(index)
            setExportListSelected([...(exportListSelected.slice(0, lindex)), ...(exportListSelected.slice(lindex + 1, exportListSelected.length))])
        }
    }

    function removeAlltoList() {
        setExportListSelected([])
    }

    function addAllToList() {
        setExportListSelected(exportList.map((item) => {
            return item._id
        }))
    }

    function selectedIndex(index) {
        return exportListSelected.includes(index)
    }

    function loadItensToFile() {
        setExportedLoaded(false)
        api.get(`/registro_tag_date/${sessionStorage.getItem('idConta')}/${dataInicio}/${dataFim}?itemName=${itemName}`, {}
        ).then(response => {
            setItList([...response.data.filter((item, index) => {
                if(exportListSelected.includes(item.id_registro)) {
                    return item
                }
            })])
        }).finally(()=> {
            setExportedLoaded(true)
        })
    }

    function reverseReg() {
        setReverse(!reverse)
        setItems(items.reverse())
    }

    function dates(days, direction) {
        let dt   = new Date();
        if(direction == '+') {
            dt = new Date(dt.getTime() + (days * 24 * 60 * 60 * 1000))
        }
        else if(direction == '-') {
            dt = new Date(dt.getTime() - (days * 24 * 60 * 60 * 1000))
        }
        return (dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2))
    }

    function now() {
        let dt = new Date()
        dt.setHours(dt.getHours() - 3)
        let dt_reg = dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2)
        dt_reg += " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" +dt.getMinutes()).slice(-2) + ":" + ("0" +dt.getSeconds()).slice(-2)
        return dt_reg
    }

    function formatDate(data) {
        let dt = new Date(data)
        return (
            <div>
              {dt.toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
                timeZone: 'UTC'
              })}
            </div>
        );
    }
    
    function formatDatecsv(data) {
        let dt = new Date(data)
        let dt_reg = ("0" + dt.getDate()).slice(-2) + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + dt.getFullYear()
        dt_reg += " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" +dt.getMinutes()).slice(-2) + ":" + ("0" +dt.getSeconds()).slice(-2)
        return dt_reg
    }

    function nivel1Get() {
        api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setNivelLoc1ListInventario(response.data)})
    }

    function nivel2Get(nivel1) {
        if(nivel1 != "*") {
            api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/${nivel1}/*/*/*/*`, {}
            ).then(response => {setNivelLoc2ListInventario(response.data)})
        }else {
            setNivelLoc2ListInventario([])
        }
    }
    function nivel3Get(nivel2) {
        if(nivel2 != "*") {
            api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/${nivel2}/*/*/*/*`, {}
            ).then(response => {setNivelLoc3ListInventario(response.data)})
        }else {
            setNivelLoc3ListInventario([])
        }
    }
    function nivel4Get(nivel4) {
        if(nivel4 != "*") {
            api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/${nivel4}/*/*/*/*`, {}
            ).then(response => {setNivelLoc4ListInventario(response.data)})
        }else {
            setNivelLoc4ListInventario([])
        }
    }
    function parceiroGet() {
        api.get(`/parceiro/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setParceiroList(response.data)})
    }

    function nivel1GetList() {
        api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setNivelLoc1List(response.data)})
    }

    function nivel2GetList(nivel1) {
        if(nivel1 != "*" && nivel1 != '') {
            api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/${nivel1 != '' ? nivel1 : '*'}/*/*/*/*`, {}
            ).then(response => {setNivelLoc2List(response.data)})
        }else {
            setNivelLoc2List([])
        }
    }

    function nivel3GetList(nivel2) {
        if(nivel2 != "*" && nivel2 != '') {
            api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/${nivel2 != '' ? nivel2 : '*'}/*/*/*/*`, {}
            ).then(response => {setNivelLoc3List(response.data)})
        }else {
            setNivelLoc3List([])
        }
    }

    function nivel4GetList(nivel3) {
        if(nivel3 != "*" && nivel3 != '') {
            api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/${nivel3 != '' ? nivel3 : '*'}/*/*/*/*`, {}
            ).then(response => {setNivelLoc4List(response.data)})
        }else {
            setNivelLoc4List([])
        }
    }

    function nivel1GetListNew() {
        api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setNivelLoc1NewList(response.data)})
    }

    function nivel2GetListNew(nivel1) {
        if(nivel1 != "*" && nivel1 != '') {
            api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/${nivel1 != '' ? nivel1 : '*'}/*/*/*/*`, {}
            ).then(response => {setNivelLoc2NewList(response.data)})
        }else {
            setNivelLoc2NewList([])
        }
    }

    function nivel3GetListNew(nivel2) {
        if(nivel2 != "*" && nivel2 != '') {
            api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/${nivel2 != '' ? nivel2 : '*'}/*/*/*/*`, {}
            ).then(response => {setNivelLoc3NewList(response.data)})
        }else {
            setNivelLoc3NewList([])
        }
    }

    function nivel4GetListNew(nivel3) {
        if(nivel3 != "*" && nivel3 != '') {
            api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/${nivel3 != '' ? nivel3 : '*'}/*/*/*/*`, {}
            ).then(response => {setNivelLoc4NewList(response.data)})
        }else {
            setNivelLoc4NewList([])
        }
    }

    function changeImage(file) {
        var reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onloadend = function(e, file) {
            var b64 = this.result;

            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            api.post(`/save_jpeg/${sessionStorage.getItem('idConta')}`, [{foto: b64}], option).then(
                async response => {
                    let fotoRes = response.data[0].id_foto
                    setFotoUp(fotoRes)
                    setFoto(fotoRes && fotoRes != '' && fotoRes != '/static/media/sem_foto.b83884cf.png' && fotoRes != 'assets/imgs/sem_foto.png'
                    ? "https://api.inovacode.app.br/" + fotoRes : NoImg)
                }
            ).finally(
                () => {
                    setFotoToUp(true)
                }
            )
        }
    }

    function categoriaGet() {
        api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setCategoriaList(response.data)})
    }

    function novaCategoria(fechar) {
        if(fechar == 'fechar') {
            setAlertNew('')
            setInputNew('')
            setCategoriaNew('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                ean: "",
                id_categoria: "0",
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                inf_compl_1: "",
                inf_compl_2: "",
                inf_compl_3: "",
                inf_compl_4: "",
                inf_compl_5: "",
                observacao: ""
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            api.post('/categoria', reg, option).then(
                response=> {
                    setInputNew('')
                    setAlertNew('')
                    categoriaGet()
                    setCategoriaNew(response.data[0]._id)

                },
                response=> {
                    setAlertNew(`Temos um problema...\n${response.data.error}`)
                }
            )
        }
    }

    function conditionAPI(cond1, cond2,where, field) {
        if(!(cond1 == '' || cond1 == '*' || cond1 == null|| cond1 == undefined)) {
            return cond1
        }else if(!(cond2 == null ||cond2 == undefined ||cond2[where] == null ||cond2[where] == undefined || cond2[where][field] == '' || cond2[where][field] == '*' || cond2[where][field] == null)) {
            return cond2[where][field]
        }else {
            return ''
        }
    }

    const noFoto = ['', '/static/media/sem_foto.b83884cf.png', 'assets/imgs/sem_foto.png']

    function csv() {
        let body = tagListItens.map((item, index) => {
            return [
                item?._id,
                item?.ordem,
                item?.descricao,
                item?.quantidade,
                item?.lido === 0 ? "Lido" : "Não lido"
            ]
        })
        let body2 = ordenateListBy(ordenationExport.split(','),body).filter((item) => {
            if(item) {
                return item
            }
        })
        return [...body2]
    }

    async function prepareExport(redirect) {
        setChange(redirect)
    }
    

    //pedidos
    function csvPedido() {
        let body = pedidoListItens.map((item, index) => {
            let info = ''
            if(Number(item.quantidade) == Number(item.quantidade_lida)) {
                info = 'Correto'
            }
            else if(Number(item.quantidade) > Number(item.quantidade_lida)) {
                info = 'Faltante'
            }
            else if(Number(item.quantidade) < Number(item.quantidade_lida)) {
                info = 'Excedente'
            }
            return [
                item.descricao,
                item.ean,
                item.quantidade.replace('\r',''),
                item.quantidade_lida,
                item.quantidade_auditada,
                info,
                item.observacao
            ]
        })
        let body2 = body.filter((item) => {
            if(item) {
                return item
            }
        })
        return [...body2]
    }
    //

    //itens
    function csvItens() {
        let body = tagListItens.map((item, index) => {
            let info = ""
            let dtstatus = ['', null]
            let listCompl_6_10 = []
            if(item.encontrado == 0 && item.excedente == 0) {
                info = "Ausente"
            }
            if(item.encontrado == 1 && item.excedente == 0) {
                info = "Encontrado"
            }
            if(item.excedente == 1) {
                info = "Excedente"
            }
            if(item.excedente == 1 && ((item.id_item != '0' && item.id_item != null) || item.descricao != "SEM DESCRICAO" && item.descricao != "SEM DESCRIÇÃO")) {
                info = "Outro_Ambiente"
            }
            if(item.auditado == 1) {
                info = "Auditado"
            }
            if(item.incorporado == 1) {
                info = 'Incorporado'
            }
            if(item.id_item){
                listCompl_6_10 = [
                    (item.id_item.inf_compl_6 ? item.id_item.inf_compl_6 : ''),
                    (item.id_item.inf_compl_7 ? item.id_item.inf_compl_7 : ''),
                    (item.id_item.inf_compl_8 ? item.id_item.inf_compl_8 : ''),
                    (item.id_item.inf_compl_9 ? item.id_item.inf_compl_9 : ''),
                    (item.id_item.inf_compl_10 ? item.id_item.inf_compl_10 : ''),
                ]
            }
            if (tagFilter.includes('Todas') || tagFilter.some(item => info.includes(item))) {
                let infCompl = [
                    (item.inf_compl_1 ? item.inf_compl_1.replaceAll('"',"''") : ''),
                    (item.inf_compl_2 ? item.inf_compl_2.replaceAll('"',"''") : ''),
                    (item.inf_compl_3 ? item.inf_compl_3.replaceAll('"',"''") : ''),
                    (item.inf_compl_4 ? item.inf_compl_4.replaceAll('"',"''") : ''),
                    (item.inf_compl_5 ? item.inf_compl_5.replaceAll('"',"''") : ''),
                ]
                return [
                    item?.id_item?._id ? item?.id_item?._id : null,
                    item.descricao.replaceAll('"',"''"),
                    item.tag,
                    treeCond(item.id_item, item.id_externo, 'id_externo'),
                    item.ean ? item.ean : '',
                    infCompl[0],
                    infCompl[1],
                    infCompl[2],
                    infCompl[3],
                    item.id_conta == 'rrRSGwq6m' ? item.ean : infCompl[4],
                    listCompl_6_10[0],
                    listCompl_6_10[1],
                    listCompl_6_10[2],
                    listCompl_6_10[3],
                    listCompl_6_10[4],
                    conditionAPI(item.nivel_loc_1, item.id_item, 'id_nivel_loc_1', 'descricao').replaceAll('"',"''"),
                    conditionAPI(item.nivel_loc_2, item.id_item, 'id_nivel_loc_2', 'descricao').replaceAll('"',"''"),
                    conditionAPI(item.nivel_loc_3, item.id_item, 'id_nivel_loc_3', 'descricao').replaceAll('"',"''"),
                    conditionAPI(item.nivel_loc_4, item.id_item, 'id_nivel_loc_4', 'descricao').replaceAll('"',"''"),
                    conditionAPI(item.observacao, item, 'id_item', 'observacao').replaceAll('"',"''"),
                    info,
                    !dtstatus.includes(item.dt_leitura) ? formatDatecsv(item.dt_leitura): '',
                    item.latitude ? item.latitude : '',
                    item.longitude ? item.longitude : '',
                    (item?.id_item?.foto && !noFoto.includes(item?.id_item?.foto)) ? `${process.env.REACT_APP_API_URL}/${item?.id_item?.foto}` : 'Sem foto'
                ]
            }
        })
        let body2 = body.filter((item) => {
            if(item) {
                return item
            }
        })
        return [...body2]
    }
    //

    function numberListGet() {
        let list = []
        let qtdpages = reverse ? Math.ceil((items[0].total_reg / 30)) : Math.ceil((items[items.length - 1].total_reg / 30))
        
        if(qtdpages >= 2 && qtdpages <= 5) {
            for(let i= 1; i <= qtdpages; i++) {
                list.push(i)
            }
        }else if(qtdpages > 5) {
            if(pageNumber <= 1) {
                for(let i= 1; i <= 5; i++) {
                    list.push(i)
                }
            } else if(pageNumber > 1 && pageNumber < (qtdpages - 2)) {
                for(let i= (pageNumber - 1); i <= (pageNumber + 3); i++) {
                    list.push(i)
                }
            } else {
                for(let i= (qtdpages - 4); i <= qtdpages; i++) {
                    list.push(i)
                }
            }
        }
        return list
    }

    function handleCreate(){
        const running_inventories = secureLocalStorage.getItem('running_inventories')
        if(running_inventories){
            setRunningInventories(running_inventories)
            setShowRunningInventories(true)
        }else{
            history('/collections/create')
        }
    }


    function changeFunction(key) {
        switch (key) {
            case 0:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <Container fluid>
                            {showMap ? (
                                <Modal show={true} centered dialogClassName=" modal-xl">
                                    <Modal.Header>
                                        <Modal.Title>{t('actions.map')}</Modal.Title>
                                        <Button variant="light" className="bg-transparent border-0" onClick={e=> {markersUnloader()}}>
                                            <CloseButton />
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <GoogleMap
                                        className='mapStyles'
                                        mapContainerStyle={mapContainerStyle}
                                        zoom={18}
                                        center={{lat: Number.parseFloat(markList[0].latitude), lng: Number.parseFloat(markList[0].longitude)}}
                                        >
                                        {markList.map((item, index) => {
                                            let info = ""
                                            if(item.encontrado == 0 && item.excedente == 0) {
                                                info = "Ausente"
                                            }
                                            if(item.encontrado == 1 && item.excedente == 0) {
                                                info = "Encontrado"
                                            }
                                            if(item.excedente == 1) {
                                                info = "Excedente"
                                            }
                                            if(item.excedente == 1 && ((item.id_item != '0' && item.id_item != null) || item.descricao != "SEM DESCRICAO" && item.descricao != "SEM DESCRIÇÃO")) {
                                                info = "Outro_Ambiente"
                                            }
                                            if(item.auditado == 1) {
                                                info = "Auditado"
                                            }
                                            if(item.incorporado == 1) {
                                                info = 'Incorporado'
                                            }
                                            if(item.longitude) {
                                                return (
                                                    <Marker
                                                        key = {index}
                                                        position={{
                                                            lat: Number.parseFloat(item.latitude),
                                                            lng: Number.parseFloat(item.longitude)
                                                        }}
                                                        icon={{
                                                            url: `/iconMap_${info}.png`,
                                                            scaledsize: new window.google.maps.Size(32, 30)
                                                        }}
                                                        onClick= {e => {setSelectedMark(item)}}
                                                    />
                                                    
                                                )
                                            }
                                        })}

                                        {selectedMark && (
                                            <InfoWindow
                                                position={{
                                                    lat: Number.parseFloat(selectedMark.latitude),
                                                    lng: Number.parseFloat(selectedMark.longitude)
                                                }}
                                                onCloseClick={e => {setSelectedMark(null)}}
                                            >
                                                <div style={{textAlign: 'center'}}>
                                                    <h2>{selectedMark.descricao}</h2>
                                                    <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark.tag}</p>
                                                    <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark.latitude} {selectedMark.longitude}</p>
                                                </div>
                                            </InfoWindow>
                                        )}
                                    </GoogleMap>
                                    </Modal.Body>
                                </Modal>
                            ) : ''}
                            <Row>
                                <Col><h3>{t('common.registers')}</h3></Col>
                                <Col className="d-flex justify-content-end">
                                    {false ? <Button variant="light" onClick={e => setChange(1)}>REPOSITÓRIO</Button> : ''}
                                    {false && sessionStorage.getItem('perfil') == 'admin' ? <Button variant="light" className="mx-2" onClick={e => setChange(2)}>NOVA</Button> : ''}
                                </Col>
                            </Row>
                            <Col className="border rounded bg-white p-3">
                                <Col className="d-flex justify-content-between border-bottom py-2 align-items-center">
                                    {tipoList == 'item' ? <h4>{t('common.items')}</h4> : <h4>{t('common.register')}</h4>}
                                    
                                </Col>
                                <Col className="rounded p-3 mt-3" style={{background:'#f5f6fb'}}>
                                    <div className="d-flex align-items-center">
                                        <h5 className="fw-bold text-primary">
                                            <FiFilter/>
                                            <span className="px-2">{t('common.filter')}</span>
                                        </h5>
                                    </div>
                                    <Row className="gy-2 mt-3">
                                        <Form.Group as={Col} md={3}>
                                            <Form.Label>Identificador</Form.Label>
                                            <Form.Control onChange={e=> {setSearchItem(e.target.value)}}/>
                                        </Form.Group>
                                    </Row>
                                    <Row className='justify-content-end w-100 py-3'>
                                        <Col md={2}>
                                            <Button className='w-100' onClick={e=>{handleRegisterFilter()}}>Pesquisar</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            {tipoList == 'item' ? <div><Button className="w-100 my-3" onClick={e => {markersLoader(items)}}>{t('actions.map')}</Button></div> : ''}
                            
                            {!itemsLoaded ? 
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                </div>
                            : 
                                <div>
                                {tipoList == 'registro' ?
                                <Table responsive className="text-uppercase mt-3 rounded">
                                    <thead>
                                        <tr id="header">
                                            <th onClick={e=> {reverseReg()}} style={{cursor: 'pointer'}}># {reverse ? <AiOutlineArrowUp/> : <AiOutlineArrowDown/>}</th>
                                            <th>Identificador</th>
                                            <th>{t('common.created_at')}</th>
                                            <th>DATA DE RETORNO</th>
                                            <th>{t('common.locate')}</th>
                                            <th>Destino</th>
                                            <th>{t('common.resume')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map((item, index) => {
                                                return (
                                                    <tr
                                                        className='edit'
                                                        key={index}
                                                        onClick={e=> {editItem(item)}}
                                                    >
                                                        <th>{reverse ? pageNumber * 30 + items.length - (index + 1) : pageNumber * 30 + index + 1}</th>
                                                        <th>{item.id_mov}</th>
                                                        {item.data_reg ? 
                                                            <th>
                                                                {formatDate(item.data_reg)}
                                                            </th> 
                                                        : <th></th>}
                                                        {item.data_reg ? 
                                                            <th>
                                                                {item?.destino_retorno ? formatDate(item.destino_retorno) : ""}
                                                            </th> 
                                                        : <th></th>}
                                                        <th>
                                                            <div>{item?.origem_desc_localizacao1}</div>
                                                            <div>{item?.origem_desc_localizacao2}</div>
                                                            <div>{item?.origem_desc_localizacao3}</div>
                                                            <div>{item?.origem_desc_localizacao4}</div>
                                                        </th>
                                                        <th>
                                                            <div>{item?.destino_desc_localizacao1}</div>
                                                            <div>{item?.destino_desc_localizacao2}</div>
                                                            <div>{item?.destino_desc_localizacao3}</div>
                                                            <div>{item?.destino_desc_localizacao4}</div>
                                                        </th>
                                                        <th>
                                                            <div style={{display:'flex', flexDirection:'row'}}>
                                                                <div className='block-info resume-status rounded me-2' style={{backgroundColor: '#B40C0C'}}/>
                                                                Ausentes: {item.total_esperado} 
                                                            </div>
                                                            <div style={{display:'flex', flexDirection:'row'}}>
                                                                <div className='block-info resume-status rounded me-2' style={{backgroundColor: '#4EA90B'}}/> 
                                                                Encontrados: {item.total_encontrado}  
                                                            </div>
                                                            <div style={{display:'flex', flexDirection:'row'}}>
                                                                <div className='block-info resume-status rounded me-2' style={{backgroundColor: '#E3AE1B'}}/> 
                                                                Excedentes: {item.total_excedente} 
                                                            </div>
                                                        </th>
                                                    </tr>
                                                )
                                            
                                        })}
                                    </tbody>
                                </Table>
                                :
                                <Table responsive className="border mt-3 rounded">
                                    <thead>
                                        <tr className="text-uppercase">
                                            <th onClick={e=> {reverseReg()}} style={{cursor: 'pointer'}}># {reverse ? <AiOutlineArrowUp/> : <AiOutlineArrowDown/>}</th>
                                            <th>{t('common.items')}</th>
                                            <th>{t('common.locate')}</th>
                                            <th></th>
                                            <th>STATUS</th>
                                            <th><ImCompass2 className="table_icons"/></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.slice(0, items.length - 1).map((item, index) => {
                                            let info = ""
                                            if(item.encontrado == 0 && item.excedente == 0) {
                                                info = `${t('common.absent')}`
                                            }
                                            if(item.encontrado == 1 && item.excedente == 0) {
                                                info = `${t('common.found')}`
                                            }
                                            if(item.excedente == 1) {
                                                info = `${t('common.excess')}`
                                            }
                                            if(item.excedente == 1 && ((item.id_item != '0' && item.id_item != null) || item.descricao != "SEM DESCRICAO" && item.descricao != "SEM DESCRIÇÃO")) {
                                                info = `${t('common.other_locate')}`
                                            }
                                            if(item.auditado == 1) {
                                                info = `${t('common.audited')}`
                                            }
                                            if(item.incorporado == 1) {
                                                info = `${t('common.incorporated')}`
                                            }
                                            return (
                                                <tr key={index} onClick={e=> {getExportListItems(item)}}>
                                                    <th>{index + 1}</th>
                                                    <th>
                                                        <div>{item.descricao}</div>
                                                        <div>{item.tag}</div>
                                                    </th>
                                                    <th>
                                                        
                                                    <div>{conditionAPI(item.nivel_loc_1, item.id_item, 'id_nivel_loc_1', 'descricao') != '' ? conditionAPI(item.nivel_loc_1, item.id_item, 'id_nivel_loc_1', 'descricao') : 'N / A'}</div>
                                                                <div>{conditionAPI(item.nivel_loc_2, item.id_item, 'id_nivel_loc_2', 'descricao') != '' ? conditionAPI(item.nivel_loc_2, item.id_item, 'id_nivel_loc_2', 'descricao') : 'N / A'}</div>
                                                                <div>{conditionAPI(item.nivel_loc_3, item.id_item, 'id_nivel_loc_3', 'descricao') != '' ? conditionAPI(item.nivel_loc_3, item.id_item, 'id_nivel_loc_3', 'descricao') : 'N / A'}</div>
                                                                <div>{conditionAPI(item.nivel_loc_4, item.id_item, 'id_nivel_loc_4', 'descricao') != '' ? conditionAPI(item.nivel_loc_4, item.id_item, 'id_nivel_loc_4', 'descricao') : 'N / A'}</div>
                                                    </th>
                                                    <th>
                                                        {
                                                            item.foto && !fotoNone.includes(item.foto) ?
                                                            <img style={{borderRadius: '10px'}} src={`https://api.inovacode.app.br/${item.foto}`} alt='CollectItemImage'/> : ''
                                                        }
                                                    </th>
                                                    <th>
                                                        <div style={{padding: '10px', background: colorGet(info), width: '120px', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'}}>{info}</div>
                                                        <div>{item.dt_leitura}</div>
                                                    </th>
                                                    <th>{item.longitude ? <GoLocation className="table_icons show_map" onClick={e => {markersLoader([item])}}/> : ''}</th>
                                                </tr>
                                            )})}
                                        </tbody>
                                    </Table>
                                }
                            </div>
                            }
                            </Col>
                        </Container>  
                    </div>
                )
                break
            /*case 1:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <div className="title" id="colection">
                            <h1>Inventários</h1>
                            <div>
                                <button>IMPORTAR</button>
                                <button onClick={e => setChange(0)}>INVENTÁRIOS</button>
                            </div>
                        </div>
                        <div className="filters">
                                <div className="file">
                                    <label>Arquivo</label>
                                    <input type="file"/>
                                </div>
                            </div>
                    </div>
                )
                break*/
            case 2:
                return(
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <Container fluid className="bg-white border rounded p-3">
                        {showMap ? (
                                <Modal show={true} centered dialogClassName=" modal-xl">
                                    <Modal.Header>
                                        <Modal.Title>{t('actions.map')}</Modal.Title>
                                        <Button variant="light" className="bg-transparent border-0" onClick={e=> {markersUnloader()}}>
                                            <CloseButton />
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <GoogleMap
                                            className='mapStyles'
                                            mapContainerStyle={mapContainerStyle}
                                            zoom={18}
                                            center={{lat: Number.parseFloat(markList[0].latitude), lng: Number.parseFloat(markList[0].longitude)}}
                                        >
                                            {markList.map((item, index) => {
                                                let info = ""
                                                if(item.encontrado == 0 && item.excedente == 0) {
                                                    info = "Ausente"
                                                }
                                                if(item.encontrado == 1 && item.excedente == 0) {
                                                    info = "Encontrado"
                                                }
                                                if(item.excedente == 1) {
                                                    info = "Excedente"
                                                }
                                                if(item.excedente == 1 && ((item.id_item != '0' && item.id_item != null) || item.descricao != "SEM DESCRICAO" && item.descricao != "SEM DESCRIÇÃO")) {
                                                    info = "Outro_Ambiente"
                                                }
                                                if(item.auditado == 1) {
                                                    info = "Auditado"
                                                }
                                                if(item.incorporado == 1) {
                                                    info = 'Incorporado'
                                                }
                                                if(item.longitude) {
                                                    return (
                                                        <Marker
                                                            key = {index}
                                                            position={{
                                                                lat: Number.parseFloat(item.latitude),
                                                                lng: Number.parseFloat(item.longitude)
                                                            }}
                                                            icon={{
                                                                url: `/iconMap_${info}.png`,
                                                                scaledsize: new window.google.maps.Size(32, 30)
                                                            }}
                                                            onClick= {e => {setSelectedMark(item)}}
                                                        />
                                                    )
                                                }
                                            })}

                                            {selectedMark && (
                                                <InfoWindow
                                                    position={{
                                                        lat: Number.parseFloat(selectedMark.latitude),
                                                        lng: Number.parseFloat(selectedMark.longitude)
                                                    }}
                                                    onCloseClick={e => {setSelectedMark(null)}}
                                                >
                                                    <div style={{textAlign: 'center'}}>
                                                        <h2>{selectedMark.descricao}</h2>
                                                        <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark.tag}</p>
                                                        <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark.latitude} {selectedMark.longitude}</p>
                                                    </div>
                                                </InfoWindow>
                                            )}
                                        </GoogleMap>
                                    </Modal.Body>
                                </Modal>
                            ) : ''}
                            <Col className="d-flex justify-content-between py-3">
                                <h3>{t('common.inventories')}</h3>
                                <Col className="container-btn">
                                    {editing == 1 ? (<Button variant="light" className="text-uppercase" onClick={e=> {remove()}}>{t('actions.delete')}</Button>): ''}
                                    <Button variant="light" className="text-uppercase" onClick={e => cleaner()}>{t('actions.exit')}</Button>
                                </Col>
                            </Col>
                            <ul className="nav custom-nav mb-3 text-secondary">
                                <li onClick={e=>setChangeNew(0)}>Informações</li>
                                <li onClick={e=>setChangeNew(1)}>Eans</li>
                                <li onClick={e=>setChangeNew(2)}>{t('common.items')}</li>
                            </ul>
                            {changeNewFunction(changeNew)}
                        </Container>
                    </div>
                )
                break
            case 3:
                return (
                    <>
                        <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                            <EditItem item={itemDetails[0]} status={details[1]} infoComplLabels={infoNamesList} onChange={e=>{setChange(2)}}/>
                        </div>
                    </>
                )
                break
            case 4:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>                  
                        <Container fluid>
                            <Col className="d-flex justify-content-between">
                                <h1>{t('common.inventories')}</h1>
                                <div>
                                    {exportLoaded ? 
                                        <>
                                            <Button variant="light" onClick={e=> {
                                                setChange(7)
                                                setExportType(0)
                                            }}><FaFilePdf className="me-2 text-uppercase"/>{t('actions.generate')} PDF</Button>
                                            <Button variant="light" className="mx-2" onClick={e=> {
                                                setChange(6)
                                                setExportType(0)
                                            }}><FaFileCsv className="me-2 text-uppercase"/>{t('actions.generate')} CSV</Button>
                                        </>
                                    :
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    }
                                    <Button variant="light" onClick={e => cleaner()}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                                </div>
                            </Col>
                            <Col className="border rounded bg-white p-3">
                                <Row>
                                    <Col><h3>{t('common.items')}</h3></Col>
                                    <Col className="d-flex justify-content-end">
                                        <Button variant="light" onClick={e=> {loadItensToFile()}}>{t('actions.load')} Itens</Button>
                                        <Button variant="light" className="mx-2" onClick={e=> {addAllToList()}}>{t('actions.select_all')}</Button>
                                        <Button variant="light" onClick={e => {removeAlltoList()}}>{t('actions.deselect_all')}</Button>
                                    </Col>
                                </Row>
                                <Row className="align-items-end">
                                    <Col>
                                        <div className="d-flex flex-row me-3">
                                            <div className='custom-checkbox fw-bold me-1' style={{backgroundColor: '#B40C0C', textAlign:'center', cursor: 'pointer'}} onClick={e=>{addOrRemoveExportFilter('Ausente')}}>{listaFilterExport.indexOf('Ausente') > -1 ? <FaCheck/> : ''}</div> Ausentes
                                        </div>
                                        <div className="d-flex flex-row me-3">
                                            <div className='custom-checkbox fw-bold me-1' style={{backgroundColor: '#4EA90B', textAlign:'center', cursor: 'pointer'}} onClick={e=>{addOrRemoveExportFilter('Encontrados')}}>{listaFilterExport.indexOf('Encontrados') > -1 ? <FaCheck/> : ''}</div> Encontrados
                                        </div>
                                        <div className="d-flex flex-row me-3">
                                            <div className='custom-checkbox fw-bold me-1' style={{backgroundColor: '#A33BBF', textAlign:'center', cursor: 'pointer'}} onClick={e=>{addOrRemoveExportFilter('Excedente')}}>{listaFilterExport.indexOf('Excedente') > -1 ? <FaCheck/> : ''}</div> Excedente
                                        </div>
                                        <div className="d-flex flex-row me-3">
                                            <div className='custom-checkbox fw-bold me-1' style={{backgroundColor: '#E3AE1B', textAlign:'center', cursor: 'pointer'}} onClick={e=>{addOrRemoveExportFilter('Outro_Ambiente')}}>{listaFilterExport.indexOf('Outro_Ambiente') > -1 ? <FaCheck/> : ''}</div> Outro_Ambiente
                                        </div>
                                    </Col>
                                    {true?
                                        <Col className="d-flex justify-content-end align-items-center">
                                            <label className="fw-bold">{t('actions.sort_by')}</label>
                                            <select
                                                className="border-0 border-bottom rounded-0"
                                                value={ordenationExport}
                                                onChange={e=>{setOrdenationExport(e.target.value)}}
                                            >
                                                <option value={''}></option>
                                                <option value={'0'}>{t('common.description')}</option>
                                                <option value={'1'}>Tag</option>
                                                <option value={'2'}>{t('common.extern_code')}</option>
                                                <option value={'3'}>EAN</option>
                                                <option value={'9,10,11,12'}>{t('common.level')} Item</option>
                                                <option value={'14'}>Status</option>
                                                <option value={'15'}>{t('common.read_at')}</option>
                                                <option value={'18'}>{t('common.identify')}</option>
                                                <option value={'19'}>{t('common.user')}</option>
                                                <option value={'20'}>{t('common.reader')}</option>
                                                <option value={'21,22,23,24'}>{t('common.register_nivel')}</option>
                                            </select>
                                        </Col>:''}
                                </Row>
                            
                            {!listExportLoaded ? 
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                </div>
                            :
                                <Table responsive className="mt-3 border rounded">
                                    <thead>
                                        <tr className="text-uppercase">
                                            <th style={{cursor: 'pointer'}}>#</th>
                                            <th>{t('common.identify')}</th>
                                            <th>{t('common.date')}</th>
                                            <th>{t('common.locate')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {exportList.map((item, index) => (
                                            <tr
                                            className='edit'
                                            style={selectedIndex(item._id) ? {background: '#919191', color: '#F8F8F8'} : {}}
                                            key={index}
                                            onClick ={e=> {addOrRemoveListImport(item._id)}}
                                            >
                                                <th>{index + 1}</th>
                                                <th>{item.identificador}</th>
                                                {item.dt_registro ? <th>{item.dt_registro.slice(0,10)} {item.dt_registro.slice(11,19)}</th> : <th></th>}
                                                <th>{item.id_nivel_loc_1 ? item.id_nivel_loc_1.descricao : 'N / A'}</th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>  
                            }
                            </Col>
                        </Container>
                    </div>
                )
                break
            case 5:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <Container fluid>
                            <div className='d-flex justify-content-between'>
                                <h1>Itens</h1>
                                <div className='d-flex'>
                                    <Button variant="light" className='me-2' onClick={e => {saveCreateItem()}}>SALVAR</Button>
                                    <Button variant="light" onClick={e => {closeCreateItem()}}>VOLTAR</Button>
                                </div>
                            </div>
                            <div>
                            <div className="list_account">
                                <div className='infs'>
                                    {categoriaNew == 'new' ? (
                                        <div className='extra'>
                                        <div className='extracontainer'>
                                            <div className="title">
                                                <h1>Categoria</h1>
                                            </div>
                                            <div>
                                                <input
                                                    value={inputNew}
                                                    onChange={e=> {setInputNew(e.target.value)}}
                                                />
                                            </div>
                                            <div className='close'>
                                                <button onClick={e=> {novaCategoria()}}>SALVAR</button>
                                                <button onClick={e=> {novaCategoria('fechar')}}>FECHAR</button>
                                            </div>
                                        </div>
                                    </div>
                                    ) : ''}
                                    {nivelLoc1New == 'new' ? (
                                        <div className='extra'>
                                        <div className='extracontainer'>
                                            <div className="title">
                                                <h1>Nivel 1</h1>
                                            </div>
                                            <div>
                                                <input
                                                    value={inputNew}
                                                    onChange={e=> {setInputNew(e.target.value)}}
                                                />
                                            </div>
                                            <div className='close'>
                                                <button onClick={e=> {novoNivel1()}}>SALVAR</button>
                                                <button onClick={e=> {novoNivel1('fechar')}}>FECHAR</button>
                                            </div>
                                        </div>
                                    </div>
                                    ) : ''}
                                    {nivelLoc2New == 'new' ? (
                                        <div className='extra'>
                                        <div className='extracontainer'>
                                            <div className="title">
                                                <h1>Nivel 2</h1>
                                            </div>
                                            <div>
                                                <input
                                                    value={inputNew}
                                                    onChange={e=> {setInputNew(e.target.value)}}
                                                />
                                            </div>
                                            <div className='close'>
                                                <button onClick={e=> {novoNivel2()}}>SALVAR</button>
                                                <button onClick={e=> {novoNivel2('fechar')}}>FECHAR</button>
                                            </div>
                                        </div>
                                    </div>
                                    ) : ''}
                                    {nivelLoc3New == 'new' ? (
                                        <div className='extra'>
                                        <div className='extracontainer'>
                                            <div className="title">
                                                <h1>Nivel 3</h1>
                                            </div>
                                            <div>
                                                <input
                                                    value={inputNew}
                                                    onChange={e=> {setInputNew(e.target.value)}}
                                                />
                                            </div>
                                            <div className='close'>
                                                <button onClick={e=> {novoNivel3()}}>SALVAR</button>
                                                <button onClick={e=> {novoNivel3('fechar')}}>FECHAR</button>
                                            </div>
                                        </div>
                                    </div>
                                    ) : ''}
                                    {nivelLoc4New == 'new' ? (
                                        <div className='extra'>
                                        <div className='extracontainer'>
                                            <div className="title">
                                                <h1>Nivel 4</h1>
                                            </div>
                                            <div>
                                                <input
                                                    value={inputNew}
                                                    onChange={e=> {setInputNew(e.target.value)}}
                                                />
                                            </div>
                                            <div className='close'>
                                                <button onClick={e=> {novoNivel4()}}>SALVAR</button>
                                                <button onClick={e=> {novoNivel4('fechar')}}>FECHAR</button>
                                            </div>
                                        </div>
                                    </div>
                                    ) : ''}
                                    {parceiroNew == 'new' ? (
                                        <div className='extra'>
                                            <div className='extracontainer'>
                                                <div className="title">
                                                    <h1>Parceiro</h1>
                                                </div>
                                                <div>
                                                    <input
                                                        value={inputNew}
                                                        onChange={e=> {setInputNew(e.target.value)}}
                                                    />
                                                </div>
                                                <div className='close'>
                                                    <button onClick={e=> {novoParceiro()}}>SALVAR</button>
                                                    <button onClick={e=> {novoParceiro('fechar')}}>FECHAR</button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''}
                                    <div>
                                        <label>Categoria</label>
                                        <select
                                            value={categoriaNew}
                                            onChange={e=> {setCategoriaNew(e.target.value)}}
                                        >
                                            <option value={''}></option>
                                            <option value={'new'}>Nova Categoria</option>
                                            {categoriaList.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label>Tag</label>
                                        <input
                                            type="text"
                                            value={tagNew}
                                            onChange={e=> {setTagNew(e.target.value)}}
                                        />
                                    </div>
                                    <div>
                                        <label>idExterno</label>
                                        <input
                                            className='blocked'
                                            type="text"
                                            value={codExternoNew}
                                            disabled
                                        />
                                    </div>
                                    <div>
                                        <label>Ativo</label>
                                        <select
                                            value={ativoNew}
                                            onChange={e=> {setAtivoNew(e.target.value)}}
                                        >
                                            <option value={'0'}>Não</option>
                                            <option value={'1'}>Sim</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="infs">
                                    <div>
                                        <label>Inf. Complentar 1</label>
                                        <input
                                            type="text"
                                            value={inf1New}
                                            onChange={e=> {setInf1New(e.target.value)}}
                                        />
                                    </div>
                                    <div>
                                        <label>Inf. Complentar 2</label>
                                        <input
                                            type="text"
                                            value={inf2New}
                                            onChange={e=> {setInf2New(e.target.value)}}
                                        />
                                    </div>
                                    <div>
                                        <label>Inf. Complentar 3</label>
                                        <input
                                            type="text"
                                            value={inf3New}
                                            onChange={e=> {setInf3New(e.target.value)}}
                                        />
                                    </div>
                                    <div>
                                        <label>Inf. Complentar 4</label>
                                        <input
                                            type="text"
                                            value={inf4New}
                                            onChange={e=> {setInf4New(e.target.value)}}
                                        />
                                    </div>
                                    <div>
                                        <label>Inf. Complentar 5</label>
                                        <input
                                            type="text"
                                            value={inf5New}
                                            onChange={e=> {setInf5New(e.target.value)}}
                                        />
                                    </div>
                                </div>
                                <div className='infs'>
                                    <div>
                                        <label><b className="focus">Localização</b> Nivel 1</label>
                                        <select
                                            value={nivelLoc1New}
                                            onChange={e=> {setNivelLoc1New(e.target.value)}}
                                        >
                                            <option value={''}></option>
                                            <option value={'new'}>Novo Nivel</option>
                                            {nivelLoc1NewList.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label>Nivel 2</label>
                                        <select
                                            value={nivelLoc2New}
                                            onChange={e=> {setNivelLoc2New(e.target.value)}}
                                        >
                                            <option value={''}></option>
                                            <option value={'new'}>Novo Nivel</option>
                                            {nivelLoc2NewList.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label> Nivel 3</label>
                                        <select
                                            value={nivelLoc3New}
                                            onChange={e=> {setNivelLoc3New(e.target.value)}}
                                        >
                                            <option value={''}></option>
                                            <option value={'new'}>Novo Nivel</option>
                                            {nivelLoc3NewList.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label>Nivel 4</label>
                                        <select
                                            value={nivelLoc4New}
                                            onChange={e=> {setNivelLoc4New(e.target.value)}}
                                        >
                                            <option value={''}></option>
                                            <option value={'new'}>Novo Nivel</option>
                                            {nivelLoc4NewList.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div id="flex_1">
                                    <div id="flex2">
                                        <div>
                                            <div>
                                                <label>Parceiro</label>
                                                <select
                                                    value={parceiroNew}
                                                    onChange={e=> {setParceiroNew(e.target.value)}}
                                                >
                                                    <option value={''}></option>
                                                    <option value={'new'}>Novo Parceiro</option>
                                                    {parceiroList.map((item, index) => (
                                                        <option key={index} value={item._id}>{item.nome}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <label>Observação</label>
                                                <input
                                                    type="text"
                                                    value={observacaoNew}
                                                    onChange={e=> {setObservacaoNew(e.target.value)}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div id="flex3">
                                        <div>
                                            <img id="register"
                                                src={NoImg}
                                                alt="itemImage"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </Container>
                    </div>
                )
                break
            case 6:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>                  
                        <Container fluid>
                            <Col className="d-flex justify-content-between my-2" >
                                <h3>{t('common.inventories')} - {t('actions.generate')} CSV</h3>
                                <Button variant="light" onClick={e => {cleaner()}}><FiArrowLeft/>VOLTAR</Button>
                            </Col>
                            <ExportCSV headers={headersCSV} listItens={csv()} modulo={'registro-rotas'}/>
                        </Container>
                    </div>
                )
                break
            case 7:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>                  
                        <Container>
                            <Col className="d-flex justify-content-between my-2" >
                                <h3>Inventário - Gerar PDF</h3>
                                <Button variant="light" onClick={e => {cleaner()}}><FiArrowLeft/>VOLTAR</Button>
                            </Col>
                            <ExportPDF headers={headersCSV} listItens={csv()} modulo={'registro-rotas'} data={dataProps}/>
                        </Container>
                    </div>
                )
                break
            case 8:
                    return (
                        <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>                  
                            <Container fluid>
                                <Col className="border rounded bg-white p-3">
                                    <Col className="d-flex justify-content-between">
                                            <h3>Preview {t('common.items')}</h3>
                                            <div className="text-uppercase">
                                                <Button variant="light" onClick={e=> {
                                                    setChange(7)
                                                    setExportType(2)
                                                }}><FaFilePdf className="me-2"/>{t('actions.generate')} PDF</Button>
                                                <Button variant="light" className="mx-2" onClick={e=> {
                                                    setChange(6)
                                                    setExportType(2)
                                                }}><FaFileCsv className="me-2"/>{t('actions.generate')} CSV</Button>
                                                <Button variant="light" onClick={e => {cleaner()}}><FiArrowLeft/>VOLTAR</Button>
                                            </div>
                                        </Col>
                                    <Table>
                                        <thead>
                                            <tr id="header">
                                                <th>#</th>
                                                <th>ITEM</th>
                                                <th>LOCAL</th>
                                                <th></th>
                                                <th>
                                                    <FormControl sx={{ m: 1, width: 300 }}>
                                                        <InputLabel id="filter-by-status">Filtrar por Status</InputLabel>
                                                        <Select
                                                            labelId="filter-by-status"
                                                            id="filter-by-status"
                                                            multiple
                                                            value={tagFilter}
                                                            onChange={handleFilter}
                                                            input={<OutlinedInput label="Filtrar por Status" />}
                                                            renderValue={(selected) => selected.join(', ')}
                                                            MenuProps={MenuProps}
                                                        >
                                                        {filterOptions.map((option) => (
                                                            <MenuItem key={option} value={option}>
                                                                <Checkbox checked={tagFilter.indexOf(option) > -1} />
                                                                <ListItemText primary={option} />
                                                            </MenuItem>
                                                        ))}
                                                        </Select>
                                                    </FormControl>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {tagListItens.map((item, index) => {
                                                let info = ""
                                                if(item.encontrado == 0 && item.excedente == 0) {
                                                    info = "Ausente"
                                                }
                                                if(item.encontrado == 1 && item.excedente == 0) {
                                                    info = "Encontrado"
                                                }
                                                if(item.excedente == 1) {
                                                    info = "Excedente"
                                                }
                                                if(item.excedente == 1 && ((item.id_item != '0' && item.id_item != null) || item.descricao != "SEM DESCRICAO" && item.descricao != "SEM DESCRIÇÃO")) {
                                                    info = "Outro_Ambiente"
                                                }
                                                if(item.auditado == 1) {
                                                    info = "Auditado"
                                                }
                                                if(item.incorporado == 1) {
                                                    info = 'Incorporado'
                                                }
                                                if (tagFilter.includes('Todas') || tagFilter.some(item => info.includes(item))) {
                                                    return (
                                                        <tr
                                                            className='edit'
                                                            key={index}
                                                        >
                                                            <th onClick={e=> {itemDetail(item, info)}}>{index + 1}</th>
                                                            <th onClick={e=> {itemDetail(item, info)}}>
                                                                <div>{item.descricao}</div>
                                                                <div>{item.tag}</div>
                                                            </th>
                                                            <th onClick={e=> {itemDetail(item, info)}}>
                                                                <div>{conditionAPI(item.nivel_loc_1, item.id_item, 'id_nivel_loc_1', 'descricao') != '' ? conditionAPI(item.nivel_loc_1, item.id_item, 'id_nivel_loc_1', 'descricao') : 'N / A'}</div>
                                                                <div>{conditionAPI(item.nivel_loc_2, item.id_item, 'id_nivel_loc_2', 'descricao') != '' ? conditionAPI(item.nivel_loc_2, item.id_item, 'id_nivel_loc_2', 'descricao') : 'N / A'}</div>
                                                                <div>{conditionAPI(item.nivel_loc_3, item.id_item, 'id_nivel_loc_3', 'descricao') != '' ? conditionAPI(item.nivel_loc_3, item.id_item, 'id_nivel_loc_3', 'descricao') : 'N / A'}</div>
                                                                <div>{conditionAPI(item.nivel_loc_4, item.id_item, 'id_nivel_loc_4', 'descricao') != '' ? conditionAPI(item.nivel_loc_4, item.id_item, 'id_nivel_loc_4', 'descricao') : 'N / A'}</div>
                                                            </th>
                                                            <th onClick={e=> {itemDetail(item, info)}}>
                                                                {
                                                                    item.foto && !fotoNone.includes(item.foto) ?
                                                                    <img style={{borderRadius: '10px'}} src={`https://api.inovacode.app.br/${item.foto}`} alt='CollectItemImage'/> : ''
                                                                }
                                                            </th>
                                                            <th onClick={e=> {itemDetail(item, info)}}>
                                                                <div style={{padding: '10px', background: colorGet(info), width: '120px', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'}}>{info}</div>
                                                                <div>{item.dt_leitura}</div>
                                                            </th>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Container>
                        </div>
                    )
                    break
            default:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>Error</div>
                )
                break
        }
    }

    function openCreateItem(){
        api.get(`/itensPage/${sessionStorage.getItem('idConta')}/*/*/${details[0].tag}/*/*/*/*/*/*/*/*/*/*/0`).then(
            response => {
                if(response.data[response.data.length - 1].total_reg == 0) {
                    setTagNew(details[0].tag)
                    setChange(5)
                }else{
                    window.alert('Esta tag já se encontra registrada na base!')
                }
            }, error=>{
                setTagNew(details[0].tag)
                setChange(5)
            }
        )
    }

    function saveCreateItem(){
        if(categoriaNew == ''){
            window.alert("Ops...\nSelecione ou cadastre uma nova Categoria")
        }else if(tagNew == '') {
            window.alert("Faltou !\nInformar o valor da TAG")
        }else{
            let tagConf = tagNew
            if(!tagConf.includes(':')) {
                tagConf = "000000000000000000000000" + tagConf
                tagConf = tagConf.substr(tagConf.length - 24);
            }
            tagConf = tagConf.toUpperCase()
            let reg = []
            reg.push({
                ativo: ativoNew,
                foto: fotoUp,
                id_categoria: categoriaNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: codExternoNew,
                id_item_vinculado: '',
                id_nivel_loc_1: nivelLoc1New,
                id_nivel_loc_2: nivelLoc2New,
                id_nivel_loc_3: nivelLoc3New,
                id_nivel_loc_4: nivelLoc4New,
                id_parceiro: parceiroNew,
                id_registro: "null",
                inf_compl_1: inf1New,
                inf_compl_2: inf2New,
                inf_compl_3: inf3New,
                inf_compl_4: inf4New,
                inf_compl_5: inf5New,
                lido_registro: '1',
                observacao: observacaoNew,
                tag: tagConf,
                up_1: '',
                up_2: '',
                up_3: '',
                up_4: '',
                up_5: '',
                _id: '0'
            })

            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            api.post('/itemBO', reg, option
            ).then(
                response=> {
                    window.alert("Registrado !\nItem salvo com sucesso")
                    closeCreateItem(true)
                },
                response=> {
                    window.alert(`Temos um problema...`)
                }
            )
        }
    }

    function closeCreateItem(save){
        if(save){
            setChange(2)
        }else{
            setChange(3)
        }
        setCategoriaNew('')
        setTagNew('')
        setAtivoNew('1')
        setInf1New('')
        setInf2New('')
        setInf3New('')
        setInf4New('')
        setInf5New('')
        setNivelLoc1New('')
        setNivelLoc2New('')
        setNivelLoc3New('')
        setNivelLoc4New('')
        setParceiroNew('')
        setObservacaoNew('')
        setCodExternoNew('')
        setCategoriaList([])
    }

    function itemDetail(item, info) {
        setDetails([item, info])
        if(item.tag != '') {
            api.get(`item_tag_inf/${sessionStorage.getItem('idConta')}/${item.tag}`).then(
                response => {
                    if(response.data.length == 0) {
                        setItemDetails([
                            {
                                "ativo": 1,
                                "lido_registro": 1,
                                "_id": "",
                                "id_conta": sessionStorage.getItem('idConta'),
                                "id_externo": "",
                                "id_item_vinculado": "",
                                "tag": "",
                                "id_categoria": null,
                                "inf_compl_1": "",
                                "inf_compl_2": "",
                                "inf_compl_3": "",
                                "inf_compl_4": "",
                                "inf_compl_5": "",
                                "id_nivel_loc_1": null,
                                "id_nivel_loc_2": null,
                                "id_nivel_loc_3": null,
                                "id_nivel_loc_4": null,
                                "foto": "",
                                "observacao": "",
                                "id_parceiro": null,
                                "id_registro": null
                            }
                        ])
                    }else {
                        setItemDetails(response.data)
                    }
                },
                response => {
                    setItemDetails([
                        {
                            "ativo": 1,
                            "lido_registro": 1,
                            "_id": "",
                            "id_conta": sessionStorage.getItem('idConta'),
                            "id_externo": "",
                            "id_item_vinculado": "",
                            "tag": "",
                            "id_categoria": null,
                            "inf_compl_1": "",
                            "inf_compl_2": "",
                            "inf_compl_3": "",
                            "inf_compl_4": "",
                            "inf_compl_5": "",
                            "id_nivel_loc_1": null,
                            "id_nivel_loc_2": null,
                            "id_nivel_loc_3": null,
                            "id_nivel_loc_4": null,
                            "foto": "",
                            "observacao": "",
                            "id_parceiro": null,
                            "id_registro": null
                        }
                    ])
                }
            ).finally(
                () => {
                    setChange(3)
                }
            )
        }else {
            setItemDetails([
                {
                    "ativo": 1,
                    "lido_registro": 1,
                    "_id": "",
                    "id_conta": sessionStorage.getItem('idConta'),
                    "id_externo": "",
                    "id_item_vinculado": "",
                    "tag": "",
                    "id_categoria": null,
                    "inf_compl_1": "",
                    "inf_compl_2": "",
                    "inf_compl_3": "",
                    "inf_compl_4": "",
                    "inf_compl_5": "",
                    "id_nivel_loc_1": null,
                    "id_nivel_loc_2": null,
                    "id_nivel_loc_3": null,
                    "id_nivel_loc_4": null,
                    "foto": "",
                    "observacao": "",
                    "id_parceiro": null,
                    "id_registro": null
                }
            ])
            setChange(3)
        }
    }

    function closeDetails() {
        setChange(2)
        setDetails([])
        setItemDetails([])
        setDescriptionsList(['', '', '', '', '', ''])
    }

    function changeNewFunction(key) {
        switch (key) {
            case 0:
                return (
                    <Col>
                            {nivelLoc1Inventario == 'new' ? (
                                <div className='extra'>
                                <div className='extracontainer'>
                                    <div className="title">
                                        <h1>Nivel 1</h1>
                                    </div>
                                    <div>
                                        <input
                                            value={inputNew}
                                            onChange={e=> {setInputNew(e.target.value)}}
                                        />
                                    </div>
                                    <div className='close'>
                                        <button onClick={e=> {novoNivel1()}}>SALVAR</button>
                                        <button onClick={e=> {novoNivel1('fechar')}}>FECHAR</button>
                                    </div>
                                </div>
                            </div>
                            ) : ''}
                            {nivelLoc2Inventario == 'new' ? (
                                <div className='extra'>
                                <div className='extracontainer'>
                                    <div className="title">
                                        <h1>Nivel 2</h1>
                                    </div>
                                    <div>
                                        <input
                                            value={inputNew}
                                            onChange={e=> {setInputNew(e.target.value)}}
                                        />
                                    </div>
                                    <div className='close'>
                                        <button onClick={e=> {novoNivel2()}}>SALVAR</button>
                                        <button onClick={e=> {novoNivel2('fechar')}}>FECHAR</button>
                                    </div>
                                </div>
                            </div>
                            ) : ''}
                            {nivelLoc3Inventario == 'new' ? (
                                <div className='extra'>
                                <div className='extracontainer'>
                                    <div className="title">
                                        <h1>Nivel 3</h1>
                                    </div>
                                    <div>
                                        <input
                                            value={inputNew}
                                            onChange={e=> {setInputNew(e.target.value)}}
                                        />
                                    </div>
                                    <div className='close'>
                                        <button onClick={e=> {novoNivel3()}}>SALVAR</button>
                                        <button onClick={e=> {novoNivel3('fechar')}}>FECHAR</button>
                                    </div>
                                </div>
                            </div>
                            ) : ''}
                            {nivelLoc4Inventario == 'new' ? (
                                <div className='extra'>
                                <div className='extracontainer'>
                                    <div className="title">
                                        <h1>Nivel 4</h1>
                                    </div>
                                    <div>
                                        <input
                                            value={inputNew}
                                            onChange={e=> {setInputNew(e.target.value)}}
                                        />
                                    </div>
                                    <div className='close'>
                                        <button onClick={e=> {novoNivel4()}}>SALVAR</button>
                                        <button onClick={e=> {novoNivel4('fechar')}}>FECHAR</button>
                                    </div>
                                </div>
                            </div>
                            ) : ''}
                            {parceiroInventario == 'new' ? (
                                <div className='extra'>
                                <div className='extracontainer'>
                                    <div className="title">
                                        <h1>Parceiro</h1>
                                    </div>
                                    <div>
                                        <input
                                            value={inputNew}
                                            onChange={e=> {setInputNew(e.target.value)}}
                                        />
                                    </div>
                                    <div className='close'>
                                        <button onClick={e=> {novoParceiro()}}>SALVAR</button>
                                        <button onClick={e=> {novoParceiro('fechar')}}>FECHAR</button>
                                    </div>
                                </div>
                            </div>
                            ) : ''}
                            <Row>
                                <Col>
                                    <Row className="g-2">
                                        <Form.Group as={Col} sm={3}>
                                            <Form.Label>{t('common.mode')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={modoInventario}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm={3}>
                                            <Form.Label>{t('common.identify')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={identificadorInventario}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm={3}>
                                            <Form.Label>Status</Form.Label>
                                            <Form.Control
                                                value={statusInventario}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm={3}>
                                            <Form.Label>{t('common.register_date')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={formatDatecsv(dtRegistro)}
                                                />
                                        </Form.Group>
                                        <Form.Group as={Col} sm={3}>
                                            <Form.Label>{t('common.estimated_count')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={qtdEstimada}
                                                />
                                        </Form.Group>
                                        <Form.Group as={Col} sm={3}>
                                            <Form.Label>{t('common.found')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={encontradoInventario}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm={3}>
                                            <Form.Label>{t('common.excess')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={excedenteInventario}
                                            />
                                        </Form.Group>
                                    </Row>    
                                </Col>
                            </Row>
                    </Col>
                )
                break
            case 1:
                return (
                    <Row >
                        <Col className="d-flex justify-content-between">
                            <h3>Eans</h3>
                            <div>
                                <Button className="me-2" onClick={e=> {
                                    setChange(7)
                                    setExportType(1)
                                }}><FaFilePdf className="me-2"/>{t('actions.generate')} PDF</Button>
                                <Button onClick={e=> {
                                    setChange(6)
                                    setExportType(1)
                                }}><FaFileCsv className="me-2"/>{t('actions.generate')} CSV</Button>
                            </div>
                        </Col>
                        {!pedidoLoaded ?
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                            </div>
                        :
                            <div>
                                <Table responsive hover>
                                    <thead>    
                                        <tr id="header">
                                            <th>#</th>
                                            <th>{t('common.categories')}</th>
                                            <th>EAN</th>
                                            <th>{t('common.count')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pedidoFilterListItens.map((item, index) => (
                                            <tr key={index}>
                                                <th>{index + 1}</th>
                                                <th>{item?.nome}</th>
                                                <th>{item?.ean}</th>
                                                <th>{item?.total_ean_encontrado}</th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </Row>
                )
                break
            case 2:
                return (
                    <Row>
                        {listItemsLoaded ?
                        <Col className="d-flex justify-content-between">
                            <h3>Dashboard</h3>
                            <div className="text-uppercase">
                                <Button variant="light" onClick={e=> {
                                    prepareExport(7)
                                }}><FaFilePdf className="me-2"/>{t('actions.generate')} PDF</Button>
                                <Button variant="light" className="mx-2" onClick={e=> {
                                    prepareExport(6)
                                }}><FaFileCsv className="me-2"/>{t('actions.generate')} CSV</Button>
                                {listItemsLoaded ?<Button variant="custom-primary" onClick={e => {markersLoader(tagListItens)}}>{t('actions.map')}</Button>:''}
                            </div>
                        </Col> : ''}
                        {listItemsLoaded && !showMap?
                            <Dashboard 
                                totalItens={totalItens}
                                statusList={statusList} 
                                IDRegistro={idRegistro}
                                dashItensFound={dashItensFound}
                            />
                        : ''}
                        {!listItemsLoaded ?
                            <div style={{display: 'flex', justifyContent: 'center', marginTop:'80px'}}>
                                <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                            </div>
                        :
                            <div className="list">                  
                                <Table hover>
                                    <thead>
                                        <tr id="header">
                                            <th>#</th>
                                            <th>ITEM</th>
                                            <th>{infoNamesList[1]}</th>
                                            <th>DATA DE LEITURA</th>
                                            <th>STATUS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tagListItens.map((item, index) => (
                                            <tr
                                                className='edit py-3'
                                                key={index}
                                            >
                                                <th>{index + 1}</th>
                                                <th>
                                                    <div>{item?.nome}</div>
                                                    <div>{item?.tag}</div>
                                                </th>
                                                <th>
                                                    <div>{item?.inf_compl2}</div>
                                                </th>
                                                <th>
                                                    <div>{formatDate(item?.data_leitura)}</div>
                                                </th>
                                                <th className='text-capitalize'>{item.status_leitura}</th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                
                            </div>
                        }
                    </Row>
                )
                break
            case 3:
                return (
                    <div style={{marginTop: '20px'}}>
                        <GoogleMap
                            className='mapStyles'
                            mapContainerStyle={mapContainerStyle}
                            zoom={18}
                            center={{lat: Number.parseFloat(latitude), lng: Number.parseFloat(longitude)}}
                        >
                            <Marker
                                position={{
                                    lat: Number.parseFloat(latitude),
                                    lng: Number.parseFloat(longitude)
                                }}
                                icon={{
                                    url: '/iconMap.png',
                                    scaledsize: new window.google.maps.Size(32, 30)
                                }}
                            />
                        </GoogleMap>
                    </div>
                )
                break
            case 4:
                return (
                    <div className="list_account"></div>
                )
                break
            case 5:
                return (
                    <div>
                    {leitorInventarioInfo ?
                        <Row>
                            <Form.Group as={Col} sm={3}>
                                <Form.Label>Nome Leitor</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={leitorInventarioInfo.nm_leitor}
                                    disabled
                                    />
                            </Form.Group>
                            <Form.Group as={Col} sm={3}>
                                <Form.Label>Serial</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={leitorInventarioInfo.serial}
                                    disabled
                                    />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Ativo</Form.Label>
                                <Form.Select
                                    value={leitorInventarioInfo.ativo}
                                    disabled
                                    >
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Periodo - Dias</Form.Label>
                                <Form.Control
                                    type="number"
                                    min='0'
                                    value={leitorInventarioInfo.periodo}
                                    disabled
                                    />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Inf. Compl 1</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={leitorInventarioInfo.inf_compl_1}
                                    disabled
                                    />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Inf. Compl 2</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={leitorInventarioInfo.inf_compl_2}
                                    disabled
                                    />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Modelo</Form.Label>
                                <Form.Select
                                    value={leitorInventarioInfo.leitor_modelo}
                                    disabled
                                    >
                                    <option value=""></option>
                                    <option value="blu">Bluebird</option>
                                    <option value="dot">Dot 900</option>
                                    <option value="301">i300</option>
                                    <option value="bu1">Clock B1</option>
                                    <option value="tsl">Tsl 1128</option>
                                    <option value="imp">Impinj</option>
                                    <option value="inn">InovaCode</option>
                                    <option value="mdl">Midleware</option>
                                    <option value="nfc">NFC</option>  
                                    <option value="ebc">eBeacon</option> 
                                    <option value="ter">Terminal</option> 
                                    <option value="csv">CSV</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Potência</Form.Label>
                                <Form.Select
                                    value={leitorInventarioInfo.leitor_potencia}
                                    disabled
                                    >
                                    <option value=""></option>
                                    <option value="5">Baixa</option>
                                    <option value="20">Média</option>
                                    <option value="30">Alta</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Seção</Form.Label>
                                <Form.Select
                                    value={leitorInventarioInfo.leitor_secao}
                                    disabled
                                    >
                                    <option value=""></option>
                                    <option value="0">Seção 0</option>
                                    <option value="1">Seção 1</option>
                                    <option value="2">Seção 2</option>
                                    <option value="3">Seção 3</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Estado</Form.Label>
                                <Form.Select
                                    value={leitorInventarioInfo.leitor_estado}
                                    disabled
                                    >
                                    <option value=""></option>
                                    <option value="0">A</option>
                                    <option value="1">A~B</option>
                                    <option value="2">B</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Impressora</Form.Label>
                                <input
                                    type="text"
                                    value={leitorInventarioInfo.impressora_serial}
                                    disabled
                                    />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Padrão</Form.Label>
                                <Form.Select
                                    value={leitorInventarioInfo.item_padrao}
                                    disabled
                                    >
                                    <option value=""></option>
                                    <option value="manual">Manual</option>
                                    <option value="gtin">GTIN</option>
                                    <option value="sgtin">sGTIN</option>
                                    <option value="one">InovaOne</option>
                                    <option value="epc">EPC API</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Verificador Inicial</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={leitorInventarioInfo.item_verif_inicial}
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Posição</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={leitorInventarioInfo.item_posicao}
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Comprimento</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={leitorInventarioInfo.item_comprimento}
                                    disabled
                                    />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Finalidade</Form.Label>
                                <Form.Select
                                    value={leitorInventarioInfo.reg_coleta}
                                    disabled
                                >
                                    <option value='1'>Coletas</option>
                                    <option value='0'>Inventários</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Observação</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={leitorInventarioInfo.observacao}
                                    disabled
                                />
                            </Form.Group>
                        </Row>
                        :
                        <div style={{textAlign: 'center', margin: '150px', fontWeight:'bold', fontSize: '40px'}}>Leitor Não Especificado</div>
                    }
                    </div>
                )
                break
            default:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>Error</div>
                )
                break
        }
    }

    function colorGet(stat) {
        let color = ''
        if(stat == "Excedente") {
            color = '#A33BBF'
        }else if(stat == "Outro_Ambiente") {
            color = '#E3AE1B'
        }else if(stat == "Auditado") {
            color = '#08B08C'
        }else if(stat == "Encontrado" || stat == "Encontrados") {
            color = '#4EA90B'
        }else if(stat == "Ausente") {
            color = '#B40C0C'
        }else if(stat == "Incorporado") {
            color = '#26C6DA'
        }

        return color
    }

    function novoNivel1(fechar) {
        if(fechar == 'fechar') {
            setAlertNew('')
            setInputNew('')
            setNivelLoc1Inventario('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                id_nivel_loc1: "0",
                observacao: "",
                tag: "",
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }
    
            api.post('/nivel_loc1', reg, option).then(
                response=> {
                    setInputNew('')
                    setAlertNew('')
                    nivel1Get()
                    setNivelLoc1Inventario(response.data[0]._id)
    
                },
                response=> {
                    setAlertNew(`Temos um problema...\n${response.data.error}`)
                }
            )
        }
    }
    
    function novoNivel2(fechar) {
        if(fechar == 'fechar') {
            setAlertNew('')
            setInputNew('')
            setNivelLoc2Inventario('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                id_nivel_loc1: nivelLoc1Inventario,
                id_nivel_loc2: "0",
                observacao: "",
                tag: "",
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }
    
            api.post('/nivel_loc2', reg, option).then(
                response=> {
                    setInputNew('')
                    setAlertNew('')
                    nivel2Get(nivelLoc1Inventario)
                    setNivelLoc2Inventario(response.data[0]._id)
    
                },
                response=> {
                    setAlertNew(`Temos um problema...\n${response.data.error}`)
                }
            )
        }
    }
    
    function novoNivel3(fechar) {
        if(fechar == 'fechar') {
            setAlertNew('')
            setInputNew('')
            setNivelLoc3Inventario('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                id_nivel_loc2: nivelLoc2Inventario,
                id_nivel_loc3: "0",
                observacao: "",
                tag: ""
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }
    
            api.post('/nivel_loc3', reg, option).then(
                response=> {
                    setInputNew('')
                    setAlertNew('')
                    nivel3Get(nivelLoc2Inventario)
                    setNivelLoc3Inventario(response.data[0]._id)
    
                },
                response=> {
                    setAlertNew(`Temos um problema...\n${response.data.error}`)
                }
            )
        }
    }
    
    function novoNivel4(fechar) {
        if(fechar == 'fechar') {
            setAlertNew('')
            setInputNew('')
            setNivelLoc4Inventario('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                id_nivel_loc3: nivelLoc3Inventario,
                id_nivel_loc4: "0",
                observacao: "",
                tag: ""
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }
    
            api.post('/nivel_loc4', reg, option).then(
                response=> {
                    setInputNew('')
                    setAlertNew('')
                    nivel4Get(nivelLoc3Inventario)
                    setNivelLoc4Inventario(response.data[0]._id)
    
                },
                response=> {
                    setAlertNew(`Temos um problema...\n${response.data.error}`)
                }
            )
        }
    }
    
    function novoParceiro(fechar) {
        if(fechar == 'fechar') {
            setAlertNew('')
            setInputNew('')
            setParceiroInventario('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                endereco: "",
                id_conta: sessionStorage('idConta'),
                id_externo: "",
                id_parceiro: "0",
                nome: inputNew,
                observacao: "",
                telefone: "",
                uuid: ""
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }
    
            api.post('/parceiro', reg, option).then(
                response=> {
                    setInputNew('')
                    setAlertNew('')
                    parceiroGet()
                    setParceiroInventario(response.data[0]._id)
    
                },
                response=> {
                    setAlertNew(`Temos um problema...\n${response.data.error}`)
                }
            )
        }
    }


    function editItem(item) {
        setPedidoLoaded(false)
        //setListItemsLoaded(false)
        setChange(2)
        setChangeNew(2) //teste
        setIdRegistro(item._id)
        setModoInventario(item.tipo_mov)
        setIdentificadorInventario(item.id_mov ? item.id_mov : '')
        setStatusInventario(item.status_reg)
        setQtdEstimada(item.total_esperado)
        setEncontradoInventario(item.total_encontrado)
        setExcedenteInventario(item.total_excedente)
        setEditing(1)
        seDtRegistro(item.data_reg)

        let graphValues = [
            { name: 'Esperado', value: item?.total_esperado },
            { name: 'Encontrados', value: item?.total_encontrado },
            { name: 'Outros Amb.', value: item?.total_excedente },
        ]

        setStatusList(graphValues)

        let dashFoundList = [
            { name: 'Esperado', value: item?.total_esperado || 0},
            { name: 'Encontrados', value: item?.total_encontrado || 0}
        ]

        setDashItensFound(dashFoundList)

        api.get(`https://inovabo.herokuapp.com/bd/?c=_rota_mov_eans&id_rota_movimentacao=${item._id}`, {}
        ).then(response => {
            setPedidoListItens(response.data)
            setPedidoFilterListItens(response.data)
        }).finally(
            () => {setPedidoLoaded(true)}
        )

        getTags(item._id)
    }

    async function getTags(id) {
        try {
            const response = await api.get(`https://inovabo.herokuapp.com/bd/?c=_rota_mov_itens&id_rota_movimentacao=${id || idRegistro}`);
            const data = response.data;

            setTagListItens(data)
        } catch (error) {
            console.error("Error fetching tags:", error);
        } finally {
            setListItemsLoaded(true);
        }
    }  

    function getStatus(item){
        let status
        if(item.encontrado == 0 && item.excedente == 0) {
            status = "Ausente"
        }
        if(item.encontrado == 1 && item.excedente == 0) {
            status = "Encontrado"
        }
        if(item.excedente == 1) {
            status = "Excedente"
        }
        if(item.excedente == 1 && ((item.id_item != '0' && item.id_item != null) || item.descricao != "SEM DESCRICAO" && item.descricao != "SEM DESCRIÇÃO")) {
            status = "Outro_Ambiente"
        }
        if(item.auditado == 1) {
            status = "Auditado"
        }
        if(item.incorporado == 1) {
            status = 'Incorporado'
        }
        return status
    }

    function getDetails(props){
        const detailsTest = props

        setCountPage(Math.floor(detailsTest?.total_reg / limitPerPage))

        setTotalItens(detailsTest?.total_reg)
    }

    function handlePagination(){
        //setLoading(true)
        //api.get(`/registro_tag/*/${idRegistro}/${page - 1}?limit=${limitPerPage}`, {}
        //).then(response => {
        //    const responseRemove = [...response.data].pop()
//
        //    getDetails(responseRemove)
        //    setTagListItens(response.data.slice(0, -1))
//
        //    setLoading(false)
        //}).finally(
        //    () => {setLoading(false)}
        //)
    }

    function getPrazo(item){
        let prazo = ''

        let index = ''

        if(item?.id_nivel_loc_1?.observacao && item?.id_nivel_loc_1?.observacao.includes('${prazo:')){
            index='id_nivel_loc_1'
        }

        if(item?.id_nivel_loc_2?.observacao && item?.id_nivel_loc_2?.observacao.includes('${prazo:')){
            index='id_nivel_loc_2'
        }

        if(item?.id_nivel_loc_3?.observacao && item?.id_nivel_loc_3?.observacao.includes('${prazo:')){
            index='id_nivel_loc_3'
        }

        if(item?.id_nivel_loc_4?.observacao && item?.id_nivel_loc_4?.observacao.includes('${prazo:')){
            index='id_nivel_loc_4'
        }

        if(item?.id_parceiro && item?.id_parceiro?.observacao.includes('${prazo:')){
            index='id_parceiro'
        }

        if(index){
            prazo = new Date(item.dt_registro);
            const base = item[index].observacao.split('}')[0].split('{').pop().replace(/[^0-9]/g, '')
            prazo.setDate(prazo.getDate() + parseInt(base))
            prazo = prazo.toLocaleDateString('pt-BR', {day: '2-digit', month: '2-digit', year: 'numeric'})
        }

        return prazo
    }

    function cleaner() {
        setPrazoInventario()
        setExportType(-1)
        addOrRemoveExportFilter('Todos')
        setChange(0)
        setEditing(0)
        setUsuarioInventario('')
        setLeitorInventario('')
        setLeitorInventarioInfo(null)
        setChangeNew(0)
        setModoInventario('p')
        setIdRegistro('0')
        setTipoRegistro('')
        setIdentificadorInventario('')
        setStatusInventario('')
        setQtdEstimada('0')
        setEncontradoInventario('')
        setExcedenteInventario('')
        seDtRegistro(now())
        setDtAlteracao(now())
        setLatitude('-23.485230351504622')
        setLongitude('-46.72570559924746')
        setNivelLoc1Inventario('*')
        setNivelLoc2Inventario('*')
        setNivelLoc2ListInventario([])
        setNivelLoc3Inventario('*')
        setNivelLoc3ListInventario([])
        setNivelLoc4Inventario('*')
        setNivelLoc4ListInventario([])
        setParceiroInventario('')
        setEanInventario('')
        setObservacaoInventario('')
        setFoto(NoImg)
        setTagListItens([])
        setPedidoListItens([])
        setExportList([])
        setExportListSelected([])
        setItList([])
        setDashItens([])
        setStatusList([])
        setOrdenationExport('')
        setListaFilterExport([
            'Ausente',
            'Encontrado',
            'Excedente',
            'Outro_Ambiente',
            'Auditado',
            'Incorporado'
        ])
    }

    function save() {
        if(identificadorInventario == ''){
            window.alert("Ops...\nInforme um identificador")
        }else{
            let reg = []
            reg.push({
                "id_registro": idRegistro,
                "id_conta": sessionStorage.getItem('idConta'),
                "modo": modoInventario,
                "id_tipo_registro": tipoRegistro,
                "identificador": identificadorInventario,
                "dt_registro": dtRegistro,
                "id_parceiro": parceiroInventario,
                "id_nivel_loc_1": nivelLoc1Inventario,
                "id_nivel_loc_2": nivelLoc2Inventario,
                "id_nivel_loc_3": nivelLoc3Inventario,
                "id_nivel_loc_4": nivelLoc4Inventario,
                "ean": eanInventario,
                "quantidade": qtdEstimada,
                "encontrado": encontradoInventario,
                "excedente": excedenteInventario,
                "foto": foto,
                "latitude": latitude,
                "longitude": longitude,
                "status": statusInventario,
                "dt_alteracao": dtAlteracao,
                "observacao": observacaoInventario
            })
    
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }
    
            api.post('/registro?tr=icd3vatgr@23!', reg, option
            ).then(
                response => {
                    registroGet()
                    window.alert("Registrado !\nInventário salvo com sucesso")
                    cleaner()
                },
                response => {
                    window.alert(`Temos um problema...\n${response.data.error}`)
                }
            )
        }
    }

    function remove() {
        Swal.fire({
            title: "Atenção !",
            html: `Identidificador: ${identificadorInventario}<br/>Deseja realmente excluir este Inventário ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
            
                    api.delete(`/registro/${idRegistro}?id=conta${sessionStorage.getItem('idConta')}&?=id_usuario=${sessionStorage.getItem('idUsuario')}`, option
                    ).then(
                        response => {
                            registroGet()
                            Swal.fire({
                                title: "Sucesso !",
                                text: 'Inventário foi excluido de sua base.',
                                icon: 'success',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                            cleaner()
                        },
                        response => {
                            Swal.fire({
                                title: "Ops... !",
                                text: 'Algo deu errado, por favor tente novamente mais tarde',
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        }
                    )
                }
            }
        )
    }
    
    return (
        <div className="mainContainer"
            style={JSON.parse(sessionStorage.getItem('collors'))}
        >
            <Menu/>
            <div id="background">
                {changeFunction(change)}
            </div>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Modal show={showRunningInventories} onHide={handleCloseConfirmModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Inventários em processo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Existem inventários não salvos, deseja continuar de onde parou?

                    <Typography color="error" sx={{mt: 2}}>Essa ação não pode ser revertida</Typography>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="light" onClick={e=>{history(`/collections/create/${runningInventories}`)}}>
                    {t('messages.yes')}
                </Button>
                <Button variant="outline-primary" onClick={e=>{handleNewInventory()}}>
                    Não, criar novo inventário
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Inventories