import { useCallback, useEffect, useRef, useState } from "react"

import Menu from "../../../../../components/Menu"

import api from "../../../../../services/api"

import { Button, Container, Modal} from "react-bootstrap"

import { t } from "i18next"

import {useNavigate } from 'react-router-dom'

import {Menu as MuiMenu, Backdrop, TooltipProps, tooltipClasses, Button as MuiButton, CircularProgress, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Input, InputAdornment, InputLabel, LinearProgress, Paper, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, circularProgressClasses, Chip, Divider, Badge, styled, Alert, AlertTitle, Hidden, Tabs, Tab } from "@mui/material"

import {useParams} from 'react-router-dom'

import { IoIosSearch } from "react-icons/io"

import { amber, blue, deepPurple, green, grey, lightBlue, red, teal, yellow } from "@mui/material/colors"

import { MdBarcodeReader } from "react-icons/md"

import secureLocalStorage from "react-secure-storage"

import { HiHashtag } from "react-icons/hi"

import useWebSocket, { ReadyState } from "react-use-websocket"

import { Icon } from "@iconify/react"

import WarningAlert from "../../../../../assets/notifications-sounds/warning.mp3";

import Notification from "../../../../../components/Notification/Notification"

import { CSVLink } from "react-csv"

import { Parser } from "@json2csv/plainjs/index.js"

import axios from "axios"

// ----------------------------------------------------------------

const statusList = {
    Registrado: {
        title: 'Registrado',
        color: green[400],
        backgroundColor: green[50],
        icon: 'mdi:tag-check-outline'
    },
    Falta: {
        title: 'Falta',
        color: red[200],
        backgroundColor: red[50],
        icon: 'mage:box-3d-cross'
    },
    Excedente: {
        title: 'Excedente',
        color: deepPurple[200],
        backgroundColor: deepPurple[50],
        icon: 'material-symbols:box-add-outline'
    },
    Total_Leituras:{
        title: 'Total',
        color: grey[600],
        backgroundColor: grey[200],
        icon: 'fluent:box-multiple-search-24-regular'
    }
}

function dtFormat(dt_props) {
    let dt = new Date(dt_props)
    let dt_leitura  = dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2);
    dt_leitura  += " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" + dt.getMinutes()).slice(-2) + ":" + ("0" + dt.getSeconds()).slice(-2);
    return dt_leitura;
}

export default function MonitoringInventory(){

    const [id, setID] = useState(useParams().id ?? generateID())
    
    const history = useNavigate()

    const config = secureLocalStorage.getItem(id) || {}

    const [dtInicio, setDtInicio] = useState()

    const [loading, setLoading] = useState(false)

    const [socketConnectionStatus, setSocketConnectionStatus] = useState(false)

    const [readerConnected, setReaderConnected] = useState(false)

    const decoderMode = secureLocalStorage.getItem(id)?.decoder || {}// Recebe o modo de decodificar

    const [displayTags, setDisplayTags] = useState([]) // lista as tags lidas e esperadas (ausentes)

    const [count, setCount] = useState([])

    const [activeTags, setActiveTags] = useState({})

    const [baseObj, setBaseObj] = useState([])

    const [dataBase, setDataBase] = useState([])

    const [saveData, setSaveData] = useState([])

    const [baseItemsObj, setBaseItemsObj] = useState([])

    const [readedTag, setReadedTag] = useState(new Set())

    const [tagsReading, setTagsReading] = useState()

    const [ausenteTags, setAusenteTags] = useState([])

    const [notSave, setNotSave] = useState(false)

    const [saving, setSaving] = useState(false)

    const [csv, setCSV] = useState([])

    const [copyData, setCopyData] = useState([])

    const [expandItem, setExpandItem] = useState()

    const [alertMessage, setAlertMessage] = useState()

    const [ordemServico, setOrdemServico] = useState()

    useEffect(() => {
        handleProps()
        if(!secureLocalStorage.getItem(id)?.dataBase) {
            if(config.ordem_servico){
                getOF()
            }else{
                getItems()
            }
        }
    }, [])

    function handleProps(){
        const update = secureLocalStorage.getItem(id)
        setCount(update?.count ?? {})
        setDisplayTags(update?.displayTags || [])
        setBaseObj(update?.baseObj || [])
        setDataBase(update?.dataBase || [])
        setReadedTag(update?.readedTag || [])
        setAusenteTags(update?.ausenteTags || [])
        const dt_formated = new Date(update?.dt_inicio).toLocaleString('pt-BR', { day: '2-digit', hour: '2-digit', minute: '2-digit', month: "long" })
        setDtInicio(dt_formated)
        setActiveTags(update?.activeTags || {})
    }

    async function getItems(ordemServico) {
        setLoading(true)
        //await api.get('https://api.inovacode.app.br/itensPage/_Y12sHQ61/*/*/*/*/*/*/*/*/*/*/*/*/*/*/0?limit=10')
        await api.get(`/item/${sessionStorage.getItem('idConta')}/*/*/*/*/*/*/*/*/*/*/*/*/*`)
        .then( 
            response=>{
                const base_obj = {}

                let simplify_item_database = {}

                let data_base_items = {}

                let faltanteCount = 0

                let ausente_list = []

                const data = []

                response.data.map((item)=>{
                    if(item.id_categoria){
                        if(!base_obj[item?.id_categoria?._id]){
                            if(ordemServico){
                                if(ordemServico[item?.id_categoria?.ean]){
                                    faltanteCount++;
                                    base_obj[item?.id_categoria?._id] = {
                                        "_id": item?.id_categoria?._id,
                                        "descricao": item?.id_categoria?.descricao,
                                        "ean": item?.id_categoria?.ean,
                                        "status": "Falta",
                                        "quantidade_esperada": ordemServico[item?.id_categoria?.ean].quantidade_esperada,
                                        "tags": []
                                    }
                                    ausente_list.push(item.tag)
                                }
                            }else{
                                faltanteCount++;
                                base_obj[item?.id_categoria?._id] = {
                                    "_id": item?.id_categoria?._id,
                                    "descricao": item?.id_categoria?.descricao,
                                    "ean": item?.id_categoria?.ean,
                                    "status": "Falta",
                                    "tags": []
                                }
                                ausente_list.push(item.tag)
                            }
                        }
                        
                        simplify_item_database[item.tag]= {
                            "categoria": item?.id_categoria?._id,
                        }

                        data_base_items[item.tag] = item
                    }
                })

                setAusenteTags(ausente_list)

                setDataBase(simplify_item_database)

                data.ausenteTags = ausente_list

                data.dataBase = simplify_item_database

                data.baseObj = base_obj

                data.baseItemsObj = data_base_items

                data.count = {Falta: faltanteCount}

                setBaseItemsObj(data_base_items)
                
                setCount({Falta: faltanteCount})

                setBaseObj(base_obj)

                setSaveData(data)

                if(ordemServico){
                    const dataArray = Object.values(base_obj);
                    setDisplayTags(dataArray)
                }

                setLoading(false)

            },
            error=>{
                setLoading(false)
            }
        )
    }

    async function getOF(){
        setLoading(true)
        axios.get(`https://inovabo.herokuapp.com/${config.ordem_servico.api}/rota?_id=${config.ordem_servico.of}`)
        .then(response => {
          // Manipule os dados recebidos aqui        
          const ordemServico = {}

          response.data.forEach((item)=>{
            ordemServico[item.ean] = {
                "quantidade_esperada": item.quantidade
            }
          })
          
          setOrdemServico(ordemServico)

          getItems(ordemServico)
          
        })
    }

    // Socket
    const getSocketUrl  =  useCallback ( ( )  =>  { 
        return  new  Promise ( resolve  =>  {
            resolve (`wss://ws-homolog.inovacode.app.br/${secureLocalStorage.getItem(id)?.reader?.serial}`);
        } )
    }, [])

    const socket = useWebSocket(getSocketUrl,{  
        heartbeat: {
            message: 'ping',
            returnMessage: 'pong',
            interval: 5 * 60 * 1000,
        },
        
        
        onOpen: (evt) => {
            setSocketConnectionStatus(true)
            socket.sendJsonMessage("ST::")
            socket.sendJsonMessage("I")
        },

        onMessage: async (evt) => {
            if(evt.data.includes('INV') || evt.data.includes('ST') || evt.data.includes('INF')){
                setReaderConnected(true)
            }

            if(evt.data.includes('naoLeu')){
                setActiveTags({})
                setTagsReading(null)
            }

            if(evt.data.includes('INV')){
                let message = evt.data.replace('INV|', '').replace(/^[^\;]+\;/, '').split('|')

                let readedTagUpdate = new Set(readedTag)

                let tagsOnReading = new Set(tagsReading)

                let updateBaseObj = {...baseObj}

                let activeTagsUpdate = activeTags
                
                //if(message.length > 1) {
                //    activeTagsUpdate = {}
                //}

                message.forEach((tag) => {
                    let index
                    let formatTag = tag.split(';')
                    if(formatTag[1]?.length > 20){
                        if(!readedTagUpdate.has(formatTag[1])){
                            const newTag = handleRead(formatTag[1], updateBaseObj)
                            updateBaseObj = newTag.returnbaseObj
                            readedTagUpdate.add(formatTag[1])
                            index = newTag.index
                        }
                        if(!tagsOnReading.has(formatTag[1])){
                            if(!index){
                                index = handleRead(formatTag[1], updateBaseObj).index
                            }
                            activeTagsUpdate[index]= (activeTagsUpdate[index] || 0) + 1
                            activeTagsUpdate['total'] = (activeTagsUpdate['total'] || 0) + 1
                        }

                        tagsOnReading.add(formatTag[1])
                    }
                })
                const dataArray = Object.values(updateBaseObj).filter(item => item.tags.length > 0 || item.quantidade_esperada);

                setDisplayTags(dataArray)

                setActiveTags(activeTagsUpdate)

                setBaseObj(updateBaseObj)

                handleCount(Object.values(updateBaseObj), readedTagUpdate)

                setTagsReading(tagsOnReading)

                setReadedTag(readedTagUpdate)
            }
            
        },

        onClose: (evt) => {
            setSocketConnectionStatus(false)
        },

        shouldReconnect: () => {
            return true
        },
        retryOnError: () => {
            return true
        },
        reconnectInterval: 0,
    })

    function handleRead(tag, baseProps, index){
        const baseObjUpdate = {...baseProps}

        const existingTag_DataBase = dataBase[tag]

        let updatedTag

        let status

        let existingTag = false

        let identificador

        if(existingTag_DataBase){
            const id_categoria = existingTag_DataBase.categoria
            baseObjUpdate[id_categoria].decoder = decoder(tag)
            if(baseObjUpdate[id_categoria].tags.length > 0){
                existingTag = true
            }
            if(!baseObjUpdate[id_categoria].tags.includes(tag)){
                baseObjUpdate[id_categoria].tags.push(tag)
            }
            baseObjUpdate[id_categoria].status = 'Registrado'
            updatedTag =  baseObjUpdate[id_categoria]
            status = 'Registrado'
            identificador = id_categoria
        }else{
            const decoderCode = decoder(tag)
            identificador = decoderCode
            const defaultDecoder = {
                "_id": decoderCode,
                "descricao": "Não Registrado",
                "status": "Excedente",
                "decoder": decoderCode,
            };
            
            if (!baseObjUpdate[decoderCode]) {
                baseObjUpdate[decoderCode] = { ...defaultDecoder };
            }
            
            if (!baseObjUpdate[decoderCode].tags) {
                existingTag = true
                baseObjUpdate[decoderCode].tags = [];
            }

            if(!baseObjUpdate[decoderCode].tags.includes(tag)){
                baseObjUpdate[decoderCode].tags.push(tag);
            }
            
            updatedTag = baseObjUpdate[decoderCode]
            status = 'Excedente'

        }


        const returnArray = {
            'returnbaseObj': baseObjUpdate,
            'return_updatedTag': updatedTag,
            'status': status,
            'existingTag': existingTag,
            'index': identificador
        }
        return returnArray
    }

    function handleCount(list, total){
        let countUpdate = {}

        list.forEach(tag=>{
            countUpdate[tag.status] = (countUpdate[tag.status] || 0) + 1
        })

        countUpdate.Readed = total.size

        countUpdate.Total = total.size

        setCount(countUpdate)
    }

    function formatItem (item, status, idReg) {
        const updateItem = {
            "id_conta": sessionStorage.getItem('idConta'),
            "id_registro": idReg,
            "id_registro_tag": "0",
            "id_registro_inventario": "0",
            "id_registro_coleta": "0",
    
            "id_item": item?._id || null,
            "id_categoria": item?.id_categoria?._id || null,
            "ean": item?.id_categoria?.ean,
            "descricao": item?.id_categoria?.descricao || "SEM DESCRICAO",
            "tag": item?.tag,
            "rssi": "",
    
            "inf_compl_1": item?.inf_compl_1,
            "inf_compl_2": item?.inf_compl_2,
            "inf_compl_3": item?.inf_compl_3,
            "inf_compl_4": item?.inf_compl_4,
            "inf_compl_5": item?.inf_compl_5,
    
            "id_nivel_loc_1": item?.id_nivel_loc_1?._id || '',
            "id_nivel_loc_2": item?.id_nivel_loc_2?._id || '',
            "id_nivel_loc_3": item?.id_nivel_loc_3?._id || '',
            "id_nivel_loc_4": item?.id_nivel_loc_4?._id || '',
            "nivel_loc_1": item?.nivel_loc_1 ? item?.nivel_loc_1?.descricao : '',
            "nivel_loc_2": item?.nivel_loc_2 ? item?.nivel_loc_2?.descricao : '',
            "nivel_loc_3": item?.nivel_loc_3 ? item?.nivel_loc_3?.descricao : '',
            "nivel_loc_4": item?.nivel_loc_4 ? item?.nivel_loc_4?.descricao : '',
    
            "encontrado": "0",
            "auditado": "0",
            "excedente": "0",
            "incorporado": "0",
    
            "foto": "",
            "dt_leitura":  dtFormat(new Date()),
            "observacao" : "",
    
            "latitude": item?.latitude,
            "longitude": item?.longitude,

            "decoder": decoderMode ? decoder(item.tag) : null
        }
        updateItem[status.toLowerCase()] = 1

        return updateItem
    }    

    function decoder(code){ // Função responsável por codificar/decodificar a tag recebida
        const tag = []
        
        if(code){
            
            if(decoderMode['Manual']){
                const posicao_final = Number(decoderMode['Manual'].inicial) + Number(decoderMode['Manual'].comprimento);
                const base = code.substring(decoderMode['Manual'].inicial, posicao_final).toUpperCase()
                if(decoderMode['Manual']?.tratativa === 'Hexadecimal'){
                    tag.push(parseInt(base, 16))
                }else{
                    tag.push(base)
                }
            }

            if(decoderMode['Havan'] || decoderMode['GTIN']){
                const tds = require('epc-tds')
                let epcGS1 = tds.valueOf(code);
                tag.push(epcGS1.getGtin().substring(1))
            }

            if(decoderMode['Renner']){
                tag.push(parseInt(code.substring(8, 16).toUpperCase(), 16))
            }

            if(decoderMode['Riachuelo']){
                tag.push(code.substring(3, 14).toUpperCase())
            }
        }

        return tag.length > 0 ? tag.join(" | ") : ''
    }

    function updateCache(){
        if(!notSave){
            const updateCache = {
                ...config,
                count,
                displayTags,
                baseObj,
                readedTag,
                ausenteTags,
                dataBase,
                activeTags
            };
            secureLocalStorage.setItem(id, updateCache)
        }    
    }

    function handleCopyBoard(){
        navigator.clipboard.writeText(copyData)
        setAlertMessage({
            'severity': 'success',
            'title': 'Sucesso',
            'text': 'Texto copiado para area de transferencia.'
        })
    }

    window.addEventListener('beforeunload', function(event) {
        // Chame sua função aqui
        updateCache();
    });

    function handleCancel(){
        socket.sendJsonMessage("F")
        secureLocalStorage.clear()
        history('/collections/inventories')
    }

    function handleSave(){ 
        //socket.sendJsonMessage("F")
        setLoading(true)
        let reg = [{
            "id_registro": id,
            "id_registro_vinculado": "",
            "id_conta": sessionStorage.getItem('idConta'),
            "id_usuario": sessionStorage.getItem('idUser'),
            "id_leitor": config?.reader?.serial,
            "modo": "il",
            "filtro_itens": "",
            "id_tipo_registro": "",
            "identificador": id,
            "dt_registro": dtFormat(config.dt_inicio),
            "id_categoria": "",
            "id_parceiro": null,
            "id_nivel_loc_1": "",
            "id_nivel_loc_2": "",
            "id_nivel_loc_3": "",
            "id_nivel_loc_4": "",
            "nivel_loc_1": "",
            "nivel_loc_2": "",
            "nivel_loc_3": "",
            "nivel_loc_4": "",
            "ean": "",
            "quantidade": count?.Total,
            "encontrado": count?.Registrado,
            "excedente": count?.Excedente,
            "qtd_ausente": count?.Falta,
            "def_id_categoria": "*",
            "def_id_parceiro":null,
            "def_id_item":'',
            "def_id_nivel_loc_1": '*',
            "def_id_nivel_loc_2": '*',
            "def_id_nivel_loc_3": '*',
            "def_id_nivel_loc_4": '*',
            "latitude": '',
            "longitude": '',
            "foto": "",
            "status": "1",
            "dt_alteracao": dtFormat(new Date()),
            "observacao": "",
            "tempo_inventario": getTimer()
        }]

        let option = {
            headers: { 'Content-Type': ['application/json'] }
        }


        api.post('/registro', reg, option
        ).then(
            response => {
                handleTagPicking(response.data[0]._id)
            },
            response => {
                setLoading(false)
            }
        )
        
    }

    function handleTagPicking(id_registro){
        const uploadList = []    
        
        const foundTags = readedTag ?? new Set();

        const decoderList = []

        foundTags.forEach((tag) => {
            const existingTag = baseItemsObj[tag];
            if (existingTag) {
                const formatedItem = formatItem(existingTag, 'Encontrado', id_registro)
                uploadList.push(formatedItem)
                decoderList.push(formatedItem.decoder)

            } else {
                const formatedItem = formatItem({tag:tag}, 'Excedente', id_registro)
                uploadList.push(formatedItem)
                decoderList.push(formatedItem.decoder)
            }
        });

        ausenteTags.forEach((tag)=>{
            const existingTag = baseItemsObj[tag];
            if (!foundTags.has(tag) && existingTag) {
                uploadList.push(formatItem(existingTag, 'Ausente', id_registro))
            }
        })

        setCSV(uploadList)

        setCopyData(decoderList.join('\n'))

        let option = {
            headers: { 'Content-Type': ['application/json'] }
        }

        api.post('/registro_tag_picking', uploadList, option
        ).then(
            response => {
                secureLocalStorage.clear()
                setSaving(true)
            },
            response => {
                window.alert(`Temos um problema...\n${response.data.error}`)
            }
        ).finally(
            ()=>{
                setLoading(false)
            }
        )
        
    }

    function getTimer(){
        const dt_time = new Date() - config.dt_inicio
        const horas = Math.floor(dt_time / 3600000);
        const minutos = Math.floor((dt_time % 3600000) / 60000);
        const segundos = Math.floor((dt_time % 60000) / 1000);
    
        // Formata a string de tempo
        const tempoFormatado = `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;
        return tempoFormatado
    }

    function generateID() {
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var id = '';
        for (var i = 0; i < 6; i++) {
          var randomIndex = Math.floor(Math.random() * characters.length);
          id += characters[randomIndex];
        }
        return id;
    }

    function newInventory() {
        const newID = generateID();
        const newConfig = { ...config };
        newConfig._id = newID;
        newConfig.dt_inicio = new Date() 
        const startConfig = {...newConfig};
        secureLocalStorage.setItem(newID, startConfig);
        setLoading(false)
        setID(newID);
        setCount(saveData?.count ?? {})
        setDisplayTags([])
        setBaseObj(saveData?.baseObj || [])
        setDataBase(saveData?.dataBase || [])
        setReadedTag([])
        setAusenteTags(saveData?.ausenteTags || [])
        setDtInicio(saveData.dtInicio)
        setActiveTags({})
        setTagsReading()
        setSaving(false)
        history(`/collections/create/checagem/${newID}`)
    }

    return (
        <>
            <div 
                className="mainContainer" 
                style={JSON.parse(sessionStorage.getItem('collors'))}
            >
                <Menu/>
                <div id="background">

                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : ""}> 

                        <Container>

                            <Stack spacing={5} sx={{m:{sm: 3, md: 5}}}>

                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <div>
                                        <Typography variant="h1">Inventário</Typography>
                                        <Typography variant="subtitle">{dtInicio}</Typography>
                                    </div>
                                    <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                        <Button variant="light" onClick={e=>{handleCancel()}}>{t('actions.cancel')}</Button>
                                        <Button variant="danger" onClick={e=>{handleSave()}}>{t('actions.save')}</Button>
                                    </Stack>
                                </Stack>

                                <Stack direction="row" justifyContent="space-between" spacing={2}>
                                    <Stack as={Paper} sx={{borderRadius: 3, p: 3, width: '25ch'}}>
                                        <Stack direction="row" justifyContent="space-between" sx={{fontSize: 24}}>
                                            <Typography variant="subtitle2">Tags lidas</Typography>
                                            {config?.quant_esperada && activeTags?.total > 0 ? 
                                                <Icon icon={config?.quant_esperada === activeTags?.total ? 'lets-icons:check-round-fill': `iconamoon:arrow-${config?.quant_esperada > activeTags?.total ? 'down': 'up'}-2-fill`} color={activeTags?.total === config?.quant_esperada ? green[400] : red[600]}/>
                                            : ""}
                                        </Stack>
                                        <Stack direction="row" alignItems="end" justifyContent="center" sx={{p: 3}}>
                                            <Typography variant="h1" textAlign="center" sx={{fontWeight: 600, fontSize: '3.3em'}}>{activeTags?.total || 0}</Typography>
                                            {config?.quant_esperada ?
                                                <Typography variant="subtitle" textAlign="center">/{config?.quant_esperada || 0}</Typography>
                                            :''}
                                        </Stack>
                                    </Stack>

                                    <Stack as={Paper} sx={{borderRadius: 3, p: 3, width: '100%'}}>
                                        <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
                                            <Typography variant="subtitle2">Status</Typography>
                                            <Tooltip
                                                title={
                                                    <>
                                                        <Typography>Nome: {config?.reader?.nm_leitor}</Typography>
                                                        <Typography>Serial: {config?.reader?.serial}</Typography>
                                                    </>
                                                }
                                                placement="bottom-end"
                                                slotProps={{
                                                    popper: {
                                                        modifiers: [
                                                            {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [0, -10],
                                                            },
                                                            },
                                                        ],
                                                    },
                                                }}
                                            >
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    sx={{
                                                        borderRadius: 3,
                                                        p: 1,
                                                        py: 0.5
                                                    }}
                                                    className="border"
                                                    spacing={1}
                                                >
                                                    <Typography>Leitor: </Typography>
                                                    <Typography sx={{ fontWeight: 500, color: readerConnected ? green[500] : red[500]}}>{readerConnected ? 'Conectado' : 'Desconectado'}</Typography>
                                                    <Icon color={grey[400]} icon='iconamoon:arrow-down-2'/>
                                                </Stack>
                                            </Tooltip>
                                        </Stack>
                                        <Stack direction="row" justifyContent="space-around" sx={{mt: 1}} flexWrap="wrap">
                                            <Grid container spacing={2} flexWrap="wrap">
                                                {Object.values(statusList).map((status)=>(
                                                    <>
                                                        <Grid item sm={6} md={3}>
                                                            <Stack direction="row" alignItems="flex-start">
                                                                <IconButton sx={{color: status.color, background: status.backgroundColor}}>
                                                                    <Icon icon={status.icon}/>
                                                                </IconButton>
                                                                <div>
                                                                    <Typography variant="h3" sx={{fontWeight: 400, fontSize: {xs: '2.8em', md:'3.1em'}}}>{count[status.title] ?? 0}</Typography>
                                                                    <Typography variant="subtitle">{status.title}</Typography>
                                                                </div>
                                                            </Stack>
                                                        </Grid>
                                                    </>
                                                ))}
                                            </Grid>
                                        </Stack>
                                    </Stack>
                                </Stack>

                                <Typography variant="h2">Tags Lidas</Typography>

                                {displayTags.map((tag, index)=>(
                                    <Stack 
                                        sx={{borderRadius: 2, border: 1, borderColor: grey[100], backgroundColor: '#fbfbfb'}}
                                        className="shadow-sm"
                                        onClick={e=>{setExpandItem(expandItem === tag._id ? '': tag._id )}}
                                    >
                                        <Grid 
                                            container 
                                            justifyContent="space-between" 
                                            alignItems="center"
                                            className="bg-white"
                                            sx={{
                                                borderLeft: 5,
                                                borderLeftColor: statusList[tag.status].color,
                                                borderBottom: 1,
                                                borderBottomColor: grey[300],
                                                borderRadius: 2,
                                                p: 2
                                            }} 
                                        >
                                            <Grid item>
                                                <Typography variant="h6">{tag.descricao}</Typography>
                                                <Typography variant="subtitle2">{tag.status}</Typography>
                                                <Typography>{tag.decoder}</Typography>
                                            </Grid>
                                            <Grid item sx={{textAlign: 'end'}}>
                                                <Button variant="light" onClick={e=>{setExpandItem(expandItem === tag._id ? '': tag._id )}}>Ver {expandItem === tag._id ? 'Menos': 'Mais'} <Icon icon={expandItem === tag._id ? 'iconamoon:arrow-up-2-bold': 'iconamoon:arrow-down-2-bold'}/></Button>
                                                <Stack direction="row" alignItems="end" justifyContent="end" sx={{mt: 3}} spacing={1}>
                                                    <Typography variant="h5" sx={{color: grey[400]}}>{tag.tags.length}</Typography>
                                                    <Typography variant="h2" sx={{fontWeight: 600}}>{activeTags[tag._id] || 0}</Typography>
                                                    <Typography sx={{color: grey[400], fontSize: '0.8em'}}>{tag.quantidade_esperada ? '/' + tag.quantidade_esperada : ''}</Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        {expandItem === tag._id ? 
                                            <>
                                                <Grid 
                                                    container 
                                                    direction="column"
                                                    sx={{
                                                        p: 2,
                                                        borderRadius: 2,
                                                        borderTopRadius: 0
                                                    }}
                                                >
                                                    {tag.tags.map((taglist)=>(
                                                        <Grid item>
                                                            <Typography sx={{color: statusList[tag.status].color}}><Icon icon='fluent:tag-multiple-24-filled'/> {taglist}</Typography>                                                
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </>
                                        : ''}
                                    </Stack>
                                ))}

                            </Stack>

                        </Container>
                    </div>
                </div>
            </div>

            {alertMessage ? 
                <Snackbar open={alertMessage ?? false} autoHideDuration={200} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert severity={alertMessage.severity} variant="filled" sx={{width: '500px'}} onClose={() => {setAlertMessage()}}>
                        <AlertTitle>{alertMessage.title}</AlertTitle>
                        {alertMessage.text}
                    </Alert>
                </Snackbar>
            : ''}

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Modal show={saving} onHide={e=>{setSaving(false)}} centered>
                <Modal.Body>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="h6">Inventário</Typography>
                            <Typography sx={{color: 'grey.500'}}>#{id}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Button variant="light" onClick={e=>{handleCopyBoard()}}><Icon icon='pajamas:link' width='18'/> Copiar</Button>
                            <CSVLink
                                data={csv}
                                filename={`inventario-${dtFormat(new Date())}.csv`}
                                className="btn btn-dark"
                                target="_blank"
                                separator={";"}
                            >
                                <Icon icon='iconoir:import' width='18'/> Baixar
                            </CSVLink>
                        </Stack>
                    </Stack>
                    <Stack justifyContent="center" alignItems="center" spacing={1} className="p-5">
                        <Stack sx={{color: 'success.light', p:3}}><Icon icon='uim:check-circle' width="64"/></Stack>
                        <Typography variant="h6">Sucesso!</Typography>
                        <Typography>Inventário salvo com sucesso</Typography>
                    </Stack>
                </Modal.Body>
                <Modal.Footer>                  
                    <Stack direction="row" justifyContent="end" alignItems="end" spacing={1}>
                        <Button variant="light" onClick={e=>{history('/collections/inventories')}}>Fechar</Button>
                        <Button variant="primary" onClick={e=>{newInventory()}}>{t('actions.continue')}</Button>
                    </Stack>
                </Modal.Footer>
            </Modal>
        </>
    )
}