
import { useEffect, useState } from "react";

import secureLocalStorage from "react-secure-storage";

import { t } from "i18next";

import api from "../../services/api"

import Alert from "../../components/ui/Alert";

import { Backdrop, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, Stack, Typography } from "@mui/material";

//----------------------------------------------------------------

export default function ConfigMenu(){
    const [loading, setLoading] = useState()

    const [message, setMessage] = useState()

    const [menuItems, setMenuItems] = useState([])

    const [userData, setUserData] = useState([])

    useEffect(()=>{
        getConfig()
    },[])

    function getConfig(){
        setLoading(true)
        api.get(`/usuario/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(
            response => {
                setLoading(false)
                setUserData(response.data[0])
                const custom_menu =  response.data[0].modulos_acesso[0]
                const navList = [
                    {
                        title: 'inventories',
                        api_name: 'inventarios',
                        show: custom_menu ?  custom_menu['inventarios'] ?? false : true 
                    },
                    {
                        title: 'inventories_extern',
                        api_name: 'inventarios_externo',
                        show: custom_menu ?  custom_menu['inventarios_externo'] ?? false : true
                    },
                    {
                        title: 'collections',
                        api_name: 'coletas',
                        show: custom_menu ?  custom_menu['coletas'] ?? false : true
                    },
                    {
                        title: 'items',
                        api_name: 'itens',
                        show: custom_menu ?  custom_menu['itens'] ?? false : true
                    },
                    {
                        title: 'categories',
                        api_name: 'categorias',
                        show: custom_menu ?  custom_menu['categorias'] ?? false : true
                    },
                    {
                        title: 'locate',
                        api_name: 'localizacoes',
                        show: custom_menu ?  custom_menu['localizacoes'] ?? false : true
                    },
                    {
                        title: 'print',
                        api_name: 'impressoes',
                        show: custom_menu ?  custom_menu['impressoes'] ?? false : true
                    },
                    {
                        title: 'print-order',
                        api_name: 'ordem_impressao',
                        show: custom_menu ?  custom_menu['ordem_impressao'] ?? false : true
                    },
                    {
                        title: 'printers',
                        api_name: 'impressoras',
                        show: custom_menu ?  custom_menu['impressoras'] ?? false : true
                    },
                    {
                        title: 'readers',
                        api_name: 'leitores',
                        show: custom_menu ?  custom_menu['leitores'] ?? false : true
                    },
                    {
                        title: 'register',
                        api_name: 'tipos_registros',
                        show: custom_menu ?  custom_menu['tipos_registros'] ?? false : true
                    },
                    {
                        title: 'balance-sheet',
                        api_name: 'balancete',
                        show: custom_menu ?  custom_menu['balancete'] ?? false : true
                    },
                    {
                        title: 'maps',
                        api_name: 'mapas',
                        show: custom_menu ?  custom_menu['mapas'] ?? false : true
                    },
                    {
                        title: 'monitoring',
                        api_name: 'monitoramento',
                        show: custom_menu ?  custom_menu['monitoramento'] ?? false : true
                    },
                    {
                        title: 'logs',
                        api_name: 'logs',
                        show: custom_menu ?  custom_menu['logs'] ?? false : true
                    },
                    {
                        title: 'bi',
                        api_name: 'bi',
                        show: custom_menu ?  custom_menu['bi'] ?? false : true
                    }
                ];
                setMenuItems(navList)
            },
            err => {
                setLoading(false)
                setMessage({
                    "type": "error",
                    "message": t('messages.errors.500'),
                    "title": t('messages.errors.error')
                })
            }
        )
    }
    
    function handleChange(index, show){
        const list = [...menuItems]
        list[index].show = !show
        setMenuItems(list)
    }

    function handleSave(){
        setLoading(true)

        const updateMenu = {};

        menuItems.forEach(item => {
            if(item.show){
                updateMenu[item.api_name] = item.show;
            }
        });

        userData.id_usuario = userData._id
    
        userData.modulos_acesso = [updateMenu]

        let option = {
            headers: { 'Content-Type': ['application/json'] }
        }

        api.post('/usuario', [userData], option).then(
            response => {
                setLoading(false)
                sessionStorage.setItem('menu', JSON.stringify([{custom: Object.keys(response.data[0].modulos_acesso[0]).filter(item => item != '_id')}]))
                window.location.reload()
            },
            error=> {
                setLoading(false)
                setMessage({
                    "type": "error",
                    "message": t('messages.errors.500'),
                    "title": t('messages.errors.error')
                })
            }
        )
    }

    return (
        <>
           <Backdrop  
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop> 

            <Typography variant="h2">{t('settings.navbar.title')}</Typography>

            <Typography variant="subtitle">{t('settings.navbar.subtitle')}</Typography>

            <FormGroup sx={{my:2}}>
                {menuItems.map((item, index)=>(
                    <FormControlLabel control={<Checkbox checked={item.show} onChange={e=>{handleChange(index, item.show)}} />} label={t(`menu.${item.title}`)} />
                ))}
            </FormGroup>

            <Grid item>
                <Button variant="contained" color="error" onClick={e=>{handleSave()}} sx={{borderRadius: 5}}>{t('actions.save')}</Button>
            </Grid>

            {message ? 
                <Alert
                    type={message.type}
                    title={message.title}
                    message={message.message}
                    onClose={e=>{setMessage(e)}}
                />
            : ''}
        </>
    )
}