import { useEffect, useState } from "react";

import { Autocomplete, FormControl, FormGroup, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js";

import { cyan, purple, red } from "@mui/material/colors"

import LineChart from "../../../../components/ReCharts/LineChart";

import Loading from "../../../../components/ui/Loading";

import api from "../../../../services/api";

//--------------------------------------------------------
const fakeDATA = [
    [
        { name: '01/07', saldo: 40, entrada: 24},
        { name: '02/07', saldo: 30, entrada: 13},
        { name: '03/07', saldo: 20, entrada: 98},
        { name: '04/07', saldo: 27, entrada: 39},
        { name: '05/07', saldo: 18, entrada: 48},
        { name: '06/07', saldo: 23, entrada: 38},
        { name: '08/07', saldo: 34, entrada: 43},
        { name: '09/07', saldo: 44, entrada: 46},
        { name: '10/07', saldo: 44, entrada: 27}
    ],
    [
        { name: '01/07', saldo: 35, entrada: 19 },
        { name: '02/07', saldo: 29, entrada: 15 },
        { name: '03/07', saldo: 22, entrada: 64 },
        { name: '04/07', saldo: 28, entrada: 34 },
        { name: '05/07', saldo: 25, entrada: 38 },
        { name: '06/07', saldo: 21, entrada: 29 },
        { name: '08/07', saldo: 33, entrada: 37 },
        { name: '09/07', saldo: 42, entrada: 49 },
        { name: '10/07', saldo: 43, entrada: 28 }
    ],
    [
        { name: '01/07', saldo: 50, entrada: 31 },
        { name: '02/07', saldo: 38, entrada: 18 },
        { name: '03/07', saldo: 25, entrada: 72 },
        { name: '04/07', saldo: 30, entrada: 48 },
        { name: '05/07', saldo: 26, entrada: 57 },
        { name: '06/07', saldo: 29, entrada: 45 },
        { name: '08/07', saldo: 36, entrada: 51 },
        { name: '09/07', saldo: 48, entrada: 62 },
        { name: '10/07', saldo: 50, entrada: 37 }
    ],
    [
        { name: '01/07', saldo: 42, entrada: 28 },
        { name: '02/07', saldo: 34, entrada: 14 },
        { name: '03/07', saldo: 21, entrada: 58 },
        { name: '04/07', saldo: 25, entrada: 36 },
        { name: '05/07', saldo: 22, entrada: 42 },
        { name: '06/07', saldo: 27, entrada: 33 },
        { name: '08/07', saldo: 32, entrada: 41 },
        { name: '09/07', saldo: 45, entrada: 52 },
        { name: '10/07', saldo: 46, entrada: 29 }
    ],
    [
        { name: '01/07', saldo: 44, entrada: 27 },
        { name: '02/07', saldo: 31, entrada: 17 },
        { name: '03/07', saldo: 23, entrada: 63 },
        { name: '04/07', saldo: 29, entrada: 40 },
        { name: '05/07', saldo: 20, entrada: 44 },
        { name: '06/07', saldo: 25, entrada: 34 },
        { name: '08/07', saldo: 30, entrada: 42 },
        { name: '09/07', saldo: 41, entrada: 53 },
        { name: '10/07', saldo: 43, entrada: 30 }
    ],
    [
        { name: '01/07', saldo: 37, entrada: 22 },
        { name: '02/07', saldo: 28, entrada: 13 },
        { name: '03/07', saldo: 18, entrada: 71 },
        { name: '04/07', saldo: 24, entrada: 37 },
        { name: '05/07', saldo: 19, entrada: 47 },
        { name: '06/07', saldo: 23, entrada: 33 },
        { name: '08/07', saldo: 34, entrada: 39 },
        { name: '09/07', saldo: 44, entrada: 55 },
        { name: '10/07', saldo: 45, entrada: 27 }
    ],
    [
        { name: '01/07', saldo: 41, entrada: 23 },
        { name: '02/07', saldo: 33, entrada: 16 },
        { name: '03/07', saldo: 19, entrada: 65 },
        { name: '04/07', saldo: 26, entrada: 42 },
        { name: '05/07', saldo: 21, entrada: 46 },
        { name: '06/07', saldo: 28, entrada: 31 },
        { name: '08/07', saldo: 36, entrada: 43 },
        { name: '09/07', saldo: 47, entrada: 58 },
        { name: '10/07', saldo: 48, entrada: 33 }
    ],
    [
        { name: '01/07', saldo: 39, entrada: 25},
        { name: '02/07', saldo: 32, entrada: 14},
        { name: '03/07', saldo: 17, entrada: 68},
        { name: '04/07', saldo: 23, entrada: 39},
        { name: '05/07', saldo: 20, entrada: 49},
        { name: '06/07', saldo: 25, entrada: 37},
        { name: '08/07', saldo: 35, entrada: 40},
        { name: '09/07', saldo: 46, entrada: 54},
        { name: '10/07', saldo: 47, entrada: 29}
    ],
    [
        { name: '01/07', saldo: 43, entrada: 26 },
        { name: '02/07', saldo: 34, entrada: 12 },
        { name: '03/07', saldo: 21, entrada: 66 },
        { name: '04/07', saldo: 27, entrada: 41 },
        { name: '05/07', saldo: 22, entrada: 45 },
        { name: '06/07', saldo: 26, entrada: 35 },
        { name: '08/07', saldo: 38, entrada: 44 },
        { name: '09/07', saldo: 49, entrada: 60 },
        { name: '10/07', saldo: 50, entrada: 32 }
    ],
    [
        { name: '01/07', saldo: 40, entrada: 24 },
        { name: '02/07', saldo: 30, entrada: 13 },
        { name: '03/07', saldo: 20, entrada: 98 },
        { name: '04/07', saldo: 27, entrada: 39 },
        { name: '05/07', saldo: 18, entrada: 48 },
        { name: '06/07', saldo: 23, entrada: 38 },
        { name: '08/07', saldo: 34, entrada: 43 },
        { name: '09/07', saldo: 44, entrada: 46 },
        { name: '10/07', saldo: 44, entrada: 27 }
    ]
];


export default function ItemsByLevels(){
    const id_conta = sessionStorage.getItem('idConta')

    const [loading, setLoading] = useState(false)

    const [categoria, setCategoria] = useState()

    const [data, setData] = useState()

    const [nivel1, setNivel1] = useState()

    const [nivel2, setNivel2] = useState()

    const [nivel3, setNivel3] = useState()

    const [nivel4, setNivel4] = useState()

    // Lista 
    const [categoriaList, setCategoriaList] = useState([])

    const [nivel1List, setNivel1List] = useState([])

    const [nivel2List, setNivel2List] = useState([])

    const [nivel3List, setNivel3List] = useState([])

    const [nivel4List, setNivel4List] = useState([])

    useEffect(()=>{
        function getCategorias(){
            const descricao = "*"
            api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/${descricao}/*/*`).then(
                response=>{
                    response.data.pop()
                    if(response.data.length){
                        setCategoria(response.data[0])
                        setCategoriaList(response.data)
                    }
                }
            )
        }

        getCategorias()
    },[])

    useEffect(() => {
        function nivel1Get() {
            api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
            ).then(
                response => {
                    setNivel1List(response.data)
                    if(response.data.length){
                        setNivel1(response.data[0]._id)
                    }
                }
            )
        }

        nivel1Get()
    }, [])

    useEffect(() => {
        function nivel2Get(nivel1) {
            if (nivel1 && nivel1 !== "*") {
                const idConta = sessionStorage.getItem('idConta');
                const nivel1Path = nivel1 || '*';
        
                api.get(`/nivel_loc2/${idConta}/${nivel1Path}/*/*/*/*`)
                .then(
                    response => {
                        setNivel2List(response.data)
                        if(!response?.data.find(item => item._id === nivel2)){
                            setNivel2()
                            setNivel3()
                            setNivel4()
                        }
                    }
                )
            } else {
                setNivel2List([]);
            }
        } 

        nivel2Get(nivel1)
    }, [nivel1])

    useEffect(() => {
        function nivel3Get(nivel2) {
            if (nivel2 && nivel2 !== "*") {
                const idConta = sessionStorage.getItem('idConta');
                const nivel2Path = nivel2 || '*';
        
                api.get(`/nivel_loc3/${idConta}/${nivel2Path}/*/*/*/*`)
                    .then(response => {
                        setNivel3List(response.data);
                        if(!response?.data.find(item => item._id === nivel3)){
                            setNivel3()
                            setNivel4()
                        }
                    })
                    .catch(error => {
                        console.error("Error fetching data:", error);
                        // Optional: handle error state, show user feedback, etc.
                    });
            } else {
                setNivel3List([]);
            }
        }

        nivel3Get(nivel2)
    }, [nivel2])
    
    useEffect(() => {
        function nivel4Get(nivel3) {
            if (nivel3 && nivel3 !== "*") {
                const idConta = sessionStorage.getItem('idConta');
                const nivel3Path = nivel3 || '*';
        
                api.get(`/nivel_loc4/${idConta}/${nivel3Path}/*/*/*/*`)
                    .then(response => {
                        setNivel4List(response.data);
                    })
            } else {
                setNivel4List([]);
            }
        }    

        nivel4Get(nivel3)
    }, [nivel3])  
    
    useEffect(()=>{
        function getMovimentation(){
            setLoading(true)
            api.get(`/reg_mov_itens/${id_conta}/*/*/*/${nivel1 ?? '*'}/${nivel2 ?? '*'}/${nivel3 ?? '*'}/${nivel4 ?? '*'}?dt_registro_inicial=*&dt_registro_final=*`).then(
                response=>{
                    const responseFilter = response.data.filter(item => item?.id_item?.id_categoria === categoria._id)

                    const aggroupByData = responseFilter.reduce((acc, item) => {
                        const date = formatDate(item.dt_movimentacao);
                        if (!acc[date]) {
                            acc[date] = {
                                name: date,
                                saldo: 0,
                                entrada: item?.qtde_lido || 0,
                                saida: 0
                            };
                        } else {
                            acc[date].entrada += item?.qtde_lido || 0; 
                        }
                    
                        return acc;
                    }, {});

                    totalByNivel(aggroupByData)
                    setLoading(false)
                },
                error=>{
                    setLoading(false)
                }
            )
        }

        if(categoria && nivel1){
            getMovimentation()
        }
    },[categoria, nivel1, nivel2, nivel3, nivel4])

    function totalByNivel(movItems){
        setLoading(true)
        const id_nivel_loc_1 = nivel1 ?? "*"
        const id_nivel_loc_2 = nivel2 ?? "*"
        const id_nivel_loc_3 = nivel3 ?? "*"
        const id_nivel_loc_4 = nivel4 ?? "*"

        api.get(`/movItensEstoque/*/${id_conta}/${categoria._id}/${id_nivel_loc_1}/${id_nivel_loc_2}/${id_nivel_loc_3}/${id_nivel_loc_4}?dt_registro_inicial=*&dt_registro_final=*`).then(
            response=>{
                if(response.data?.length){
                
                    response.data.map((item) => {
                        const date = formatDate(item.dt_movimentacao);
                
                        if (movItems[date]) {
                            movItems[date].saldo = item?.qtde_estoque;
                        } else {
                            movItems[date] = {
                                name: date,
                                entrada: 0,
                                saldo: item?.qtde_estoque,
                            };
                        }
                    });
                }
                const formatArray = Object.values(movItems).flat();

                formatArray?.map((item, i)=>{
                    if(i != 0){
                        formatArray[i].saida = formatArray[i-1].saldo - formatArray[i].saldo > 0 ? formatArray[i-1].saldo - formatArray[i].saldo : 0
                    }
                })

                setData(formatArray);
                
                setLoading(false)
            },
            error=>{
                setLoading(false)
            }
        )
    }
    
    function formatDate(dataISO){
        const data = new Date(dataISO);
        const dia = data.getUTCDate().toString().padStart(2, '0');  // Obter o dia com dois dígitos
        const mes = (data.getUTCMonth() + 1).toString().padStart(2, '0');  // Obter o mês (começa em 0, por isso +1)
        const formatoDDMM = `${dia}/${mes}`;
        return formatoDDMM
    }

    return (
        <>
            <Stack 
                component={Paper} 
                spacing={3} 
                sx={{
                    p: 3, 
                    borderRadius: 2
                }}
            >
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <IconButton size="small" sx={{borderRadius: 1, backgroundColor: purple[50], color: purple[600]}}>
                            <Icon icon="mi:bar-chart-alt"/>
                        </IconButton>
                        <Stack direction="row" spacing={1}>
                            <Typography variant="h4">Produto</Typography>
                            <Typography variant="h4" sx={{fontWeight: 600}}>{categoria?.descricao || 'Selecionado'}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <FormGroup fullWidth sx={{minWidth: '35ch'}}>
                            <Typography>Categoria</Typography>
                            <Autocomplete
                                options={categoriaList}
                                getOptionLabel={(option) => option.descricao}
                                value={categoriaList.find(cat => cat === categoria) || null}
                                onChange={(event, newValue) => {
                                    setCategoria(newValue ? newValue : null);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                noOptionsText={'Não encontrado'}
                            />
                        </FormGroup>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1}>
                    {nivel1 && nivel1List && (
                        <FormControl sx={{minWidth: 100}}>
                            <InputLabel id="nivel-1-label">Nivel 1</InputLabel>
                            <Select
                                labelId="nivel-1-label"
                                id="nivel-1-select"
                                label="Nivel 1"
                                sx={{borderRadius: 100}}
                                value={nivel1}
                                onChange={e=>{setNivel1(e.target.value)}}
                            >
                                {nivel1List.map((item, index)=>(
                                    <MenuItem key={index} value={item._id}>{item.descricao}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    <FormControl sx={{display: nivel1 ? "": "none", minWidth: 100}}>
                        <InputLabel id="demo-simple-select-label">Nivel 2</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Nivel 2"
                            sx={{borderRadius: 100}}
                            value={nivel2}
                            onChange={e=>{setNivel2(e.target.value)}}
                        >
                            <MenuItem value={null}></MenuItem>
                            {nivel2List.map((item, index)=>(
                                <MenuItem value={item._id}>{item.descricao}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{display: nivel2 ? "": "none", minWidth: 100}}>
                        <InputLabel id="demo-simple-select-label">Nivel 3</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Nivel 3"
                            sx={{borderRadius: 100}}
                            value={nivel3}
                            onChange={e=>{setNivel3(e.target.value)}}
                        >
                            <MenuItem value={null}></MenuItem>
                            {nivel3List.map((item, index)=>(
                                <MenuItem value={item._id}>{item.descricao}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{display: nivel3 ? "": "none", minWidth: 100}}>
                        <InputLabel id="demo-simple-select-label">Nivel 4</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Nivel 4"
                            sx={{borderRadius: 100}}
                            value={nivel4}
                            onChange={e=>{setNivel4(e.target.value)}}
                        >
                            <MenuItem value={null}></MenuItem>
                            {nivel4List.map((item, index)=>(
                                <MenuItem value={item._id}>{item.descricao}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>

                <LineChart width="100%" propsData={data && data.length ? data : null} series={['saldo', 'entrada', 'saida']} propsColors={['#664af5', '#01c3c3', '#f5b432']} dashedIndex={[2]}/>
            </Stack>

            <Loading show={loading}/>
        </>
    )
}