import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import secureLocalStorage from 'react-secure-storage';

import { grey } from '@mui/material/colors';

import { Stack, Typography, Button, Grid, Box, IconButton, TextField, FormGroup, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { Icon } from '@iconify/react/dist/iconify.js';

import api from "../../services/api"

import emailjs from 'emailjs-com'
import Alert from '../../components/ui/Alert';

//-----------------------------------------------------------------

export default function SendData() {
    const [loading, setLoading] = useState(false) 

    const data = secureLocalStorage.getItem('transport-user')

    const email = data?.inventory?.email

    const [locate, setLocate] = useState()

    const [nomeRecebedor, setNomeRecebedor] = useState()

    const [docRecebedor, setDocRecebedor] = useState()

    const [entregaFinal, setEntregaFinal] = useState(false)

    const [message, setMessage] = useState()

    const navigate = useNavigate()

    useEffect(()=>{
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocate({
                    "lat": position.coords.latitude,
                    "long": position.coords.longitude
                });
            },
            (error) => {
                // Erro ao obter a localização
                console.error(error);
            }
          );          
    }, [])

    function handleSave(){
        const tracker = {
            "entregador": data.usuario,
            "telefone": data.telefone,
            "recebedor_documento": docRecebedor,
            "recebedor_nome": nomeRecebedor,
            ...locate        
        }

        save(tracker)
    }    

    function save(propsData){
        setLoading(true)

        const option = {
            headers: { 'Content-Type': ['application/json'] }
        }

        api.post(`/save_jpeg/${data?.inventory?.id_conta}`, [{foto: data.photo}], option)
        .then(
            response=>{
                    
                const uploadData = data.inventory;
            
                if (uploadData?.rastreamento === "" || !uploadData?.rastreamento) {
                    uploadData.rastreamento = [];
                }

                uploadData.id_registro = uploadData._id

                delete uploadData._id;
            
                propsData.imagem = response.data[0].id_foto

                uploadData.rastreamento.push(propsData);

                uploadInventory(uploadData)
            },
            error=>{
                setLoading(false)
            }
        )
    }

    function uploadInventory(propsData){
        setLoading(true)

        const option = {
            headers: { 'Content-Type': ['application/json'] }
        }

        api.post(`/registro?tr=icd3vatgr@23!`, [propsData], option)
        .then(
            response=>{
                setLoading(false)
                setMessage("success")
                console.log(entregaFinal)
                console.log(email)

                if(entregaFinal){
                    sendEmail()
                }
            },
            error=>{
                setLoading(false)
                setMessage("error")

            }
        )
    }

    function sendEmail(){
        const message = {
            nomeRecebedor: nomeRecebedor,
            data: new Date().toLocaleString('pt-BR', { day: '2-digit', hour: '2-digit', year: "numeric", minute: '2-digit', month: "long" }),
            cliente_email: email,
            send_to: email
        }
        emailjs.send('service_buqiwn9', 'template_6q1lkc9', message, 'R0Z9hwQKUdXVUD7Rx')
    }

    return (
        <>
            <Stack justifyContent="space-between" sx={{minWidth: {xs: '100%', md: '30vw'}, height: '80vh'}} spacing={3}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <IconButton onClick={e=>{navigate('/transport/upload-foto')}}>
                        <Icon icon="iconamoon:arrow-left-2-bold"/>
                    </IconButton>
                    <Typography variant="h1">Confirmar Dados</Typography>
                </Stack>

                <Stack alignItems="center">
                    <Box
                        component="img"
                        src={data?.photo}
                        sx={{
                            marginTop: 2,
                            borderRadius: 3, 
                            width: '300px', 
                            height: '300px'
                        }}
                    ></Box>
                </Stack>

                <Grid container rowSpacing={2}>
                    <Grid item md={3}>Identificador</Grid>
                    <Grid item xs={12} md={9} sx={{fontWeight: 'bold'}}>{data?.inventory?.identificador}</Grid>
                    <Grid item md={3}>Nome</Grid>
                    <Grid item xs={12} md={9} sx={{fontWeight: 'bold'}}>{data?.usuario}</Grid>
                    <Grid item md={3}>Telefone</Grid>
                    <Grid item xs={12} md={9} sx={{fontWeight: 'bold'}}>{data?.telefone}</Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h2">Recebedor</Typography>
                    </Grid>
                    <Grid item xs={11} md>
                        <FormGroup>
                            <Typography>Nome</Typography>
                            <TextField size="small" value={nomeRecebedor} onChange={e=>{setNomeRecebedor(e.target.value)}}/>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={11} md>
                        <FormGroup>
                            <Typography>Documento</Typography>
                            <TextField size="small" value={docRecebedor} onChange={e=>{setDocRecebedor(e.target.value)}}/>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="entrega-final-check">Essa entrega está sendo feita ao cliente final?</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="entrega-final-check"
                                name="row-radio-buttons-group"
                                value={entregaFinal}
                                onChange={e=>{setEntregaFinal(e.target.value);}}
                            >
                                    <FormControlLabel value={true} control={<Radio />} label="Sim" />
                                    <FormControlLabel value={false} control={<Radio />} label="Não" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                <Stack justifyContent="center">
                    <Button
                        variant="contained"
                        startIcon={<Icon icon="fa:send" width={12}/>}
                        sx={{
                            borderRadius: 5
                        }}
                        onClick={e=>{handleSave()}}
                    >
                        Salvar
                    </Button>
                </Stack>
                {message ? 
                    <Alert type={message} onClose={e=>{setMessage(); navigate('/transport/qrcode')}}/>
                : ''}
            </Stack>
        </>
    );
}
