import { useEffect, useState } from "react";

import { t } from "i18next";

import { Icon } from "@iconify/react/dist/iconify.js";

import { Box, Button, Container, Paper, Stack, Typography, MenuItem, Select, TextField, styled, FormGroup, Grid, Backdrop, CircularProgress } from "@mui/material";

import { cyan, lightGreen, purple, red, teal, yellow } from "@mui/material/colors";

import api from "../../../services/api";

import Alert from "../../../components/ui/Alert";

//----------------------------------------------------------------

const statusList = {
    "Excedente":{
        "backgroundcolor": purple[50],
        "color": purple[600]
    },
    "Outro_Ambiente":{
        "backgroundcolor": yellow[50],
        "color": yellow[600]
    },
    "Auditado":{
        "backgroundcolor": teal[50],
        "color": teal[600]
    },
    "Encontrado":{
        "backgroundcolor": lightGreen[50],
        "color": lightGreen[600]
    },
    "Encontrados":{
        "backgroundcolor": lightGreen[50],
        "color": lightGreen[600]
    },
    "Ausente":{
        "backgroundcolor": red[50],
        "color": red[600]
    },
    "Incorporado":{
        "backgroundcolor": cyan[50],
        "color": cyan[600]
    }
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: '100%',
});

export default function EditItem({item, status, infoComplLabels, onChange}){
    const [loading, setLoading] = useState(false)

    const [message, setMessage] = useState()

    // Info dos itens
    const [categoria, setCategoria] = useState(item?.id_categoria?._id)

    const [marca, setMarca] = useState(item?.id_marca)

    const [modelo, setModelo] = useState(item?.id_modelo)

    const [parceiro, setParceiro] = useState(item?.id_parceiro?._id)

    const [tag, setTag] = useState(item?.tag)

    const [IDExterno, setIDExterno] = useState(item?.id_externo)

    const [ativo, setAtivo] = useState(item?.ativo)

    const [infoCompl1, setInfoCompl1] = useState(item?.inf_compl_1 ?? [])

    const [infoCompl2, setInfoCompl2] = useState(item?.inf_compl_2 ?? [])

    const [infoCompl3, setInfoCompl3] = useState(item?.inf_compl_3 ?? [])

    const [infoCompl4, setInfoCompl4] = useState(item?.inf_compl_4 ?? [])

    const [infoCompl5, setInfoCompl5] = useState(item?.inf_compl_5 ?? [])

    const [infoCompl6, setInfoCompl6] = useState(item?.inf_compl_6 ?? [])

    const [infoCompl7, setInfoCompl7] = useState(item?.inf_compl_7 ?? [])

    const [infoCompl8, setInfoCompl8] = useState(item?.inf_compl_8 ?? [])

    const [infoCompl9, setInfoCompl9] = useState(item?.inf_compl_9 ?? [])

    const [infoCompl10, setInfoCompl10] = useState(item?.inf_compl_10 ?? [])

    const [nivel1, setNivel1] = useState(item?.id_nivel_loc_1?._id)

    const [nivel2, setNivel2] = useState(item?.id_nivel_loc_2?._id)

    const [nivel3, setNivel3] = useState(item?.id_nivel_loc_3?._id)

    const [nivel4, setNivel4] = useState(item?.id_nivel_loc_4?._id)

    const [latitude, setLatitude] = useState(item?.latitude ?? [])

    const [longitude, setLongitude] = useState(item?.longitude ?? [])

    const [radius, setRadius] = useState(item?.raio)

    const [valor, setValor] = useState(item?.valor)

    const [registro, setRegistro] = useState(item?.id_registro ?? [])

    const [observacao, setObservacao] = useState(item?.observacao)

    const [foto, setFoto]  = useState(item?.foto)

    // List //
    const [categoriaList, setCategoriaList] = useState([])

    const [marcaList, setMarcaList] = useState([])

    const [modeloList, setModeloList] = useState([])

    const [parceiroList, setParceiroList] = useState([])

    const [nivel1List, setNivel1List] = useState([])

    const [nivel2List, setNivel2List] = useState([])

    const [nivel3List, setNivel3List] = useState([])

    const [nivel4List, setNivel4List] = useState([])

    useState(()=>{
        function categoriaGet() {
            api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
            ).then(response => {setCategoriaList(response.data)})
        }

        categoriaGet()
    }, [])

    useState(()=>{
        function getMarcas(){
            api.get(`/infs_compl/${sessionStorage.getItem('idConta')}?id_categoria=${categoria}&marca=$&modelo=`).then(
                response => {
                    setMarcaList(response.data)

                    if(marca){
                        const selectedMarca = response.data.find(item => item._id === marca);
                        setModeloList(selectedMarca.modelo || [])
                    }
                    
                }
            )
        }

        if(categoria){
            getMarcas()
        }
    }, [categoria])

    useEffect(() => {
        parceiroGet()
    }, [])

    useEffect(() => {
        nivel1Get()
    }, [])

    useEffect(() => {
        nivel2Get(nivel1)
    }, [nivel1])

    useEffect(() => {
        nivel3Get(nivel2)
    }, [nivel2])
    
    useEffect(() => {
        nivel4Get(nivel3)
    }, [nivel3])

    function nivel1Get() {
        api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(
            response => {
                setNivel1List(response.data)
            }
        )
    }

    function nivel2Get(nivel1) {
        if (nivel1 && nivel1 !== "*") {
            const idConta = sessionStorage.getItem('idConta');
            const nivel1Path = nivel1 || '*';
    
            api.get(`/nivel_loc2/${idConta}/${nivel1Path}/*/*/*/*`)
            .then(
                response => {
                    setNivel2List(response.data)
                    if(!response?.data.find(item => item._id === nivel2)){
                        setNivel2()
                        setNivel3()
                        setNivel4()
                    }
                }
            )
        } else {
            setNivel2List([]);
        }
    } 

    function nivel3Get(nivel2) {
        if (nivel2 && nivel2 !== "*") {
            const idConta = sessionStorage.getItem('idConta');
            const nivel2Path = nivel2 || '*';
    
            api.get(`/nivel_loc3/${idConta}/${nivel2Path}/*/*/*/*`)
                .then(response => {
                    setNivel3List(response.data);
                    if(!response?.data.find(item => item._id === nivel3)){
                        setNivel3()
                        setNivel4()
                    }
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                    // Optional: handle error state, show user feedback, etc.
                });
        } else {
            setNivel3List([]);
        }
    }
    
    function nivel4Get(nivel3) {
        if (nivel3 && nivel3 !== "*") {
            const idConta = sessionStorage.getItem('idConta');
            const nivel3Path = nivel3 || '*';
    
            api.get(`/nivel_loc4/${idConta}/${nivel3Path}/*/*/*/*`)
                .then(response => {
                    setNivel4List(response.data);
                })
        } else {
            setNivel4List([]);
        }
    }    

    function parceiroGet() {
        api.get(`/parceiro/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setParceiroList(response.data)})
    }

    function save(){
        setLoading(true)

        const id_conta = sessionStorage.getItem('idConta')

        const id_usuario = sessionStorage.getItem('idUser')
        
        const reg = [{
            ativo: ativo || 1,
            foto: foto,
            id_categoria: categoria,
            id_conta: id_conta,
            id_usuario: id_usuario,
            id_externo: IDExterno,
            id_item_vinculado: null,
            id_nivel_loc_1: nivel1,
            id_nivel_loc_2: nivel2,
            id_nivel_loc_3: nivel3,
            id_nivel_loc_4: nivel4,
            id_parceiro: parceiro,
            id_registro: item?.id_registro?._id,
            inf_compl_1: infoCompl1,
            inf_compl_2: infoCompl2,
            inf_compl_3: infoCompl3,
            inf_compl_4: infoCompl4,
            inf_compl_5: infoCompl5,
            inf_compl_6: infoCompl6,
            inf_compl_7: infoCompl7,
            inf_compl_8: infoCompl8,
            inf_compl_9: infoCompl9,
            inf_compl_10: infoCompl10,
            status_estoque: item?.status_estoque,
            latitude: latitude || "",
            longitude: longitude || "",
            raio: radius || "",
            lido_registro: 1,
            observacao: observacao,
            tag: tag,
            valor: Number(valor),
            _id: item._id || 0, 
            id_marca: marca,
            id_modelo: modelo
        }]

        const option = {
            headers: { 'Content-Type': ['application/json'] }
        }

        const rota = item?._id ? '/itemBO' : `/itemCreateInv/${id_conta}/${id_usuario}/${nivel1}/${nivel2}/${nivel3}/${nivel4}`
        api.post(rota, reg, option
        ).then(
            response=> { 
                setMessage({
                    "type": "success",
                    "message": t('messages.success_save'),
                    "title": t('messages.success')
                })
            },
            error =>{
                setMessage({
                    "type": "error",
                    "message": t('messages.errors.500'),
                    "title": t('messages.errors.error')
                })
            }
        ).finally(
            () => {
                setLoading(false)
            }
        )
    }

    return (
        <>
            <Container sx={{my: 2}}>
                <Stack spacing={3}>
                    <Box>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h1">{t('items.manage_itens')}</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button variant="outlined" color="dark" onClick={e=>{onChange(2)}}>{t('actions.exit')}</Button>
                                <Button variant="contained" onClick={e=>{save()}}>{t('actions.save')}</Button>
                            </Stack>
                        </Stack>

                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography><Icon icon="ri:loader-line"/> Status</Typography>
                            <Stack direction="row" alignItems="center"
                                sx={{
                                    borderRadius: 2,
                                    p:0.5,
                                    fontWeight: 900,
                                    color: statusList[status].color,
                                    backgroundColor: statusList[status].backgroundcolor
                                }}
                            >
                                <Typography sx={{color: statusList[status].color, pr: 0.5}}><Icon icon="codicon:circle-filled" width="12"/></Typography>                            
                                <Typography width="12" sx={{fontWeight: 600}}>{status}</Typography>
                            </Stack>
                        </Stack>
                    </Box>
                    <Stack sx={{p: 2}} as={Paper}>
                        <Grid container rowSpacing={2} sx={{p: 2, py: 3}}>
                            <Typography variant="h3">Informações</Typography>

                            <Grid item container spacing={3}>
                                <Grid item xs={12} md={9}>
                                    <FormGroup>
                                        <Typography>Tag</Typography>
                                        <TextField size="small" value={tag} onChange={e=>{setTag(e.target.value)}}/>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormGroup>
                                        <Typography>Ativo</Typography>
                                        <Select size="small" value={ativo} onChange={e=>{setAtivo(e.target.value)}}>
                                            <MenuItem value={1}>{t('messages.yes')}</MenuItem>
                                            <MenuItem value={0}>{t('messages.no')}</MenuItem>
                                        </Select>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormGroup>
                                        <Typography>Categoria</Typography>
                                        <Select size="small" value={categoria} onChange={e=>{setCategoria(e.target.value)}}>
                                            {categoriaList.map((category)=>(
                                                <MenuItem value={category._id}>{category.descricao}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormGroup>
                                        <Typography>Marca</Typography>
                                        <Select size="small" value={marca} onChange={e=>{setMarca(e.target.value)}}>
                                            {marcaList.map((marcas)=>(
                                                <MenuItem value={marcas._id}>{marcas.marca}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormGroup>
                                        <Typography>Modelo</Typography>
                                        <Select size="small" value={modelo} onChange={e=>{setModelo(e.target.value)}}>
                                            {modeloList.map((modelos)=>(
                                                <MenuItem value={modelos._id}>{modelos.descricao}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Grid>                    
                                <Grid item xs={12} md>
                                    <FormGroup>
                                        <Typography>{t('common.partners')}</Typography>
                                        <Select size="small" value={parceiro} onChange={e=>{setParceiro(e.target.value)}}>
                                            {parceiroList.map((parceiros)=>(
                                                <MenuItem value={parceiros._id}>{parceiros.nome}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md>
                                    <FormGroup>
                                        <Typography>{t('common.value')} R$</Typography>
                                        <TextField size="small" value={valor} onChange={e=>{setValor(e.target.value)}}/>
                                    </FormGroup>
                                </Grid>
                            </Grid>

                            
                            <Grid item>
                                <Typography variant="h3">Informações Complementares</Typography>
                            </Grid>

                            <Grid item container columnSpacing={3}>
                                <Grid item xs={12} md>
                                    <FormGroup>
                                        <Typography>{ infoComplLabels[0] &&  infoComplLabels[0] != '' ?  infoComplLabels[0] : 'Inf. Complementar 1'}</Typography>
                                        <TextField size="small" value={infoCompl1} onChange={e=>{setInfoCompl1(e.target.value)}}/>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md>
                                    <FormGroup>
                                        <Typography>{ infoComplLabels[1] &&  infoComplLabels[1] != '' ?  infoComplLabels[1] : 'Inf. Complementar 2'}</Typography>
                                        <TextField size="small" value={infoCompl2} onChange={e=>{setInfoCompl2(e.target.value)}}/>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md>
                                    <FormGroup>
                                        <Typography>{ infoComplLabels[2] &&  infoComplLabels[2] != '' ?  infoComplLabels[2] : 'Inf. Complementar 3'}</Typography>
                                        <TextField size="small" value={infoCompl3} onChange={e=>{setInfoCompl3(e.target.value)}}/>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md>
                                    <FormGroup>
                                        <Typography>{ infoComplLabels[3] &&  infoComplLabels[3] != '' ?  infoComplLabels[3] : 'Inf. Complementar 4'}</Typography>
                                        <TextField size="small" value={infoCompl4} onChange={e=>{setInfoCompl4(e.target.value)}}/>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md>
                                    <FormGroup>
                                        <Typography>{ infoComplLabels[4] &&  infoComplLabels[4] != '' ?  infoComplLabels[4] : 'Inf. Complementar 5'}</Typography>
                                        <TextField size="small" value={infoCompl5} onChange={e=>{setInfoCompl5(e.target.value)}}/>
                                    </FormGroup>
                                </Grid>
                            </Grid>

                            <Grid item container columnSpacing={3}>
                                <Grid item xs={12} md>
                                    <FormGroup>
                                        <Typography>{ infoComplLabels[6] &&  infoComplLabels[6] != '' ?  infoComplLabels[6] : 'Inf. Complementar 6'}</Typography>
                                        <TextField size="small" value={infoCompl6} onChange={e=>{setInfoCompl6(e.target.value)}}/>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md>
                                    <FormGroup>
                                        <Typography>{ infoComplLabels[6] &&  infoComplLabels[6] != '' ?  infoComplLabels[6] : 'Inf. Complementar 7'}</Typography>
                                        <TextField size="small" value={infoCompl7} onChange={e=>{setInfoCompl7(e.target.value)}}/>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md>
                                    <FormGroup>
                                        <Typography>{ infoComplLabels[7] &&  infoComplLabels[7] != '' ?  infoComplLabels[7] : 'Inf. Complementar 8'}</Typography>
                                        <TextField size="small" value={infoCompl8} onChange={e=>{setInfoCompl8(e.target.value)}}/>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md>
                                    <FormGroup>
                                        <Typography>{ infoComplLabels[8] &&  infoComplLabels[8] != '' ?  infoComplLabels[8] : 'Inf. Complementar 9'}</Typography>
                                        <TextField size="small" value={infoCompl9} onChange={e=>{setInfoCompl9(e.target.value)}}/>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md>
                                    <FormGroup>
                                        <Typography>{ infoComplLabels[9] &&  infoComplLabels[9] != '' ?  infoComplLabels[9] : 'Inf. Complementar 10'}</Typography>
                                        <TextField size="small" value={infoCompl10} onChange={e=>{setInfoCompl10(e.target.value)}}/>
                                    </FormGroup>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Typography variant="h3">{t('common.locate')}</Typography>
                            </Grid>

                            <Grid item container columnSpacing={3}>
                                <Grid item xs={12} md>
                                    <FormGroup>
                                        <Typography>{t('common.level')} 1</Typography>
                                        <Select size="small" value={nivel1} onChange={e=>{setNivel1(e.target.value)}}>
                                            {nivel1List.map((nivel)=>(
                                                <MenuItem value={nivel._id}>{nivel.descricao}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md>
                                    <FormGroup>
                                        <Typography>{t('common.level')} 2</Typography>
                                        <Select size="small" value={nivel2} onChange={e=>{setNivel2(e.target.value)}}>
                                            {nivel2List.map((nivel)=>(
                                                <MenuItem value={nivel._id}>{nivel.descricao}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md>
                                    <FormGroup>
                                        <Typography>{t('common.level')} 3</Typography>
                                        <Select size="small" value={nivel3} onChange={e=>{setNivel3(e.target.value)}}>
                                            {nivel3List.map((nivel)=>(
                                                <MenuItem value={nivel._id}>{nivel.descricao}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md>
                                    <FormGroup>
                                        <Typography>{t('common.level')} 4</Typography>
                                        <Select size="small" value={nivel4} onChange={e=>{setNivel4(e.target.value)}}>
                                            {nivel4List.map((nivel)=>(
                                                <MenuItem value={nivel._id}>{nivel.descricao}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Grid>
                            </Grid>

                            <Grid item container columnSpacing={3}>
                                <Grid item xs={12} md={3}>
                                    <FormGroup>
                                        <Typography>{t('common.latitude')}</Typography>
                                        <TextField size="small" value={latitude} onChange={e=>{setLatitude(e.target.value)}}/>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormGroup>
                                        <Typography>{t('common.longitude')}</Typography>
                                        <TextField size="small" value={longitude} onChange={e=>{setLongitude(e.target.value)}}/>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormGroup>
                                        <Typography>{t('common.radius')}</Typography>
                                        <TextField size="small" value={radius} onChange={e=>{setRadius(e.target.value)}}/>
                                    </FormGroup>
                                </Grid>
                            </Grid>

                            <Grid item container spacing={3}>
                                <Grid item xs>
                                    <Box
                                        component="label"
                                        role={undefined}
                                        variant="standard"
                                        tabIndex={-1}
                                        sx={{
                                            height: '100%',
                                            border: 1,
                                            borderStyle: "dashed",
                                            borderColor: "grey.400",
                                            color: "grey.500",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <Icon icon="heroicons-outline:upload" width="32"/>
                                        <Typography textAlign="center" textTransform="capitalize" sx={{mt: 2}}>Upload file</Typography>
                                        <VisuallyHiddenInput type="file" />
                                    </Box>
                                </Grid>

                                <Grid item xs={10}>
                                    <FormGroup>
                                        <Typography>{t('common.observation')}</Typography>
                                        <TextField size="small" value={observacao} onChange={e=>{setObservacao(e.target.value)}} rows={1} multiline/>
                                    </FormGroup>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Stack>
                </Stack>
            </Container>

            <Backdrop 
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {message ? 
                <Alert
                    type={message.type}
                    title={message.title}
                    message={message.message}
                    onClose={e=>{
                        setMessage(e)
                        onChange(2)
                    }}
                />
            : ''}
        </>
    )
}