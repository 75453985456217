import React, {useState, useEffect} from 'react'
import ReactLoading from 'react-loading'

import Swal from 'sweetalert2'

import {FaFileCsv, FaFilePdf} from 'react-icons/fa'
import {FiTrash2, FiFilter, FiChevronsLeft, FiChevronsRight, FiRefreshCcw, FiArrowLeft, FiPlus, FiSave, FiX, FiUpload, FiMap} from 'react-icons/fi'

import api from '../../services/api'
import Menu from '../../components/Menu'

import ExportCSV from '../../components/exportCSV'
import ExportPDF from '../../components/exportPDF'

import './style.css'
import { Container, Row, Col, Button, Table, Form, InputGroup } from 'react-bootstrap';

import { useTranslation } from 'react-i18next'

function Readers() {
    const { t } = useTranslation();

    const [items, setItems] = useState([])
    const [ativo, setAtivo] = useState('1')
    const [nome, setNome] = useState('*')
    const [serial, setSerial] = useState('*')
    const [leitorId, setLeitorId] = useState('0')
    const [nomeNew, setNomeNew] = useState('')
    const [serialNew, setSerialNew] = useState('')
    const [ativoNew, setAtivoNew] = useState('1')
    const [periodoNew, setPeriodoNew] = useState('')
    const [inf1New, setInf1New] = useState('')
    const [inf2New, setInf2New] = useState('')
    const [nivel1List, setNivel1List] = useState([])
    const [nivel2List, setNivel2List] = useState([])
    const [nivel3List, setNivel3List] = useState([])
    const [nivel4List, setNivel4List] = useState([])
    const [nivel1New, setNivel1New] = useState('')
    const [nivel2New, setNivel2New] = useState('')
    const [nivel3New, setNivel3New] = useState('')
    const [nivel4New, setNivel4New] = useState('')
    const [finalidadeNew, setFinalidadeNew] = useState('1')
    const [destNivel1List, setDestNivel1List] = useState([])
    const [destNivel2List, setDestNivel2List] = useState([])
    const [destNivel3List, setDestNivel3List] = useState([])
    const [destNivel4List, setDestNivel4List] = useState([])
    const [destNivel1New, setDestNivel1New] = useState('')
    const [destNivel2New, setDestNivel2New] = useState('')
    const [destNivel3New, setDestNivel3New] = useState('')
    const [destNivel4New, setDestNivel4New] = useState('')
    const [observacaoNew, setObservacaoNew] = useState('')
    const [saidaNivel1List, setsaidaNivel1List] = useState([])
    const [saidaNivel2List, setsaidaNivel2List] = useState([])
    const [saidaNivel3List, setsaidaNivel3List] = useState([])
    const [saidaNivel4List, setsaidaNivel4List] = useState([])
    const [saidaNivel1New, setsaidaNivel1New] = useState('')
    const [saidaNivel2New, setsaidaNivel2New] = useState('')
    const [saidaNivel3New, setsaidaNivel3New] = useState('')
    const [saidaNivel4New, setsaidaNivel4New] = useState('')

    const [modeloL, setModeloL] = useState('')
    const [serialL, setSerialL] = useState('')
    const [potenciaL, setPotenciaL] = useState('')
    const [seccaoL, setSeccaoL] = useState('')
    const [estadoL, setEstadoL] = useState('')
    const [impressoraL, setImpressoraL] = useState('')

    const [padraoI, setPadraoI] = useState('')
    const [verificadorInicialI, setVerificadorInicialI] = useState('')
    const [posicaoI, setPosicaoI] = useState('')
    const [comprimentoI, setComprimentoI] = useState('')

    const [reversoNew, setReversoNew] = useState('0')
    const [parceiroIdNew, setParceiroIdNew] = useState('')
    const [destParceiroIdNew, setDestParceiroIdNew] = useState('')
    const [regInventarioNew, setRegInventarioNew] = useState(false)
    const [change, setChange] = useState(0)
    const [editing, setEditing] = useState(0)
    const [itemsLoaded, setItemsLoaded] = useState(true)

    const [disableItems, setDisableItems] = useState(false)

    const headersCSV = [
        "NOME",
        "SERIAL",
        "ATIVO",
        "REGISTRO",
    ]

    useEffect(() => {
        leitoresGet()
    },[nome, serial, ativo])

    useEffect(() => {
        nivel1Get()
    },[])

    useEffect(()=> {
        if(nivel1New != '') {
            nivel2Get(nivel1New, 'orig')
        }
    },[nivel1New])
    useEffect(()=> {
        if(nivel2New != '') {
            nivel3Get(nivel2New, 'orig')
        }
    },[nivel2New])
    useEffect(()=> {
        if(nivel3New != '') {
            nivel4Get(nivel3New, 'orig')
        }
    },[nivel3New])
    useEffect(()=> {
        if(destNivel1New != '') {
            nivel2Get(destNivel1New, 'dest')
        }
    },[destNivel1New])
    useEffect(()=> {
        if(destNivel2New != '') {
            nivel3Get(destNivel2New, 'dest')
        }
    },[destNivel2New])
    useEffect(()=> {
        if(destNivel3New != '') {
            nivel4Get(destNivel3New, 'dest')
        }
    },[destNivel3New])
    useEffect(()=> {
        if(saidaNivel1New != '') {
            nivel2Get(saidaNivel1New, 'saida')
        }
    },[saidaNivel1New])
    useEffect(()=> {
        if(saidaNivel2New != '') {
            nivel3Get(saidaNivel2New, 'saida')
        }
    },[saidaNivel2New])
    useEffect(()=> {
        if(saidaNivel3New != '') {
            nivel4Get(saidaNivel3New, 'saida')
        }
    },[saidaNivel3New])

    function leitoresGet() {
        setItemsLoaded(false)
        api.get(`/leitor/${sessionStorage.getItem('idConta')}/*/${nome}/${serial}/${ativo}`
        ).then(
            response => {
                setItems(response.data)
            },
            response => {
                Swal.fire({
                    title: "Ops... !",
                    text: 'Algo deu errado, por favor tente novamente mais tarde',
                    icon: 'error',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }
        ).finally(
            () => {setItemsLoaded(true)}
        )
    }

    function nivel1Get() {
        api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(
            response => {
                const nivel1 = response.data
                setNivel1List(nivel1)
                setDestNivel1List(nivel1)
                setsaidaNivel1List(nivel1)
            },
            response => {
                Swal.fire({
                    title: "Ops... !",
                    text: 'Algo deu errado, por favor tente novamente mais tarde',
                    icon: 'error',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }
        )
    }
    function nivel2Get(nivel1, pos) {
        if(nivel1 != "*") {
            api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/${nivel1}/*/*/*/*`, {}
            ).then(
                response => {
                    if(pos == 'orig'){
                        setNivel2List(response.data)
                    }else if(pos == 'dest'){
                        setDestNivel2List(response.data)
                    }else if(pos==='saida'){
                        setsaidaNivel2List(response.data)
                    }
                }
            )
        }else {
            if(pos == 'orig'){
                setNivel2List([])
            }else if(pos == 'dest'){
                setDestNivel2List([])
            }else if(pos==='saida'){
                setsaidaNivel2List([])
            }
        }
    }
    function nivel3Get(nivel2, pos) {
        if(nivel2 != "*") {
            api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/${nivel2}/*/*/*/*`, {}
            ).then(
                response => {
                    if(pos == 'orig'){
                        setNivel3List(response.data)
                    }else if(pos == 'dest'){
                        setDestNivel3List(response.data)
                    }else if(pos==='saida'){
                        setsaidaNivel3List(response.data)
                    }
                }
            )
        }else {
            if(pos == 'orig'){
                setNivel3List([])
            }else if(pos == 'dest'){
                setDestNivel3List([])
            }else if(pos==='saida'){
                setsaidaNivel3List([])
            }
        }
    }
    function nivel4Get(nivel3, pos) {
        if(nivel3 != "*") {
            api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/${nivel3}/*/*/*/*`, {}
            ).then(
                response => {
                    if(pos == 'orig'){
                        setNivel4List(response.data)
                    }else if(pos == 'dest'){
                        setDestNivel4List(response.data)
                    }else if(pos==='saida'){
                        setsaidaNivel4List(response.data)
                    }
                }
            )
        }else {
            if(pos == 'orig'){
                setNivel4List([])
            }else if(pos == 'dest'){
                setDestNivel4List([])
            }else if(pos==='saida'){
                setsaidaNivel4List([])
            }
        }
    }

    //
    function csv() {
        let body = items.map((item, index) => {
            return [
                item.nm_leitor ? item.nm_leitor : '',
                item.serial ? item.serial : '',
                item.ativo ? item.ativo : '',
                item.reg_inventario ? item.reg_inventario : ''
            ]
        })
        let body2 = body.filter((item) => {
            if(item) {
                return item
            }
        })
        return [...body2]
    }
    //

    function changeFunction(key) {
        switch (key) {
            case 0:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <Container fluid>
                            <Row>
                                <Col className="d-flex">
                                    <h3>{t('common.readers')}</h3>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <Button variant="light" className="me-2" onClick={e => setChange(6)}><FaFilePdf className="me-2"/>{t('actions.generate')} PDF</Button>
                                    <Button variant="light" onClick={e => setChange(5)}><FaFileCsv className="me-2"/>{t('actions.generate')} CSV</Button>
                                    <Button variant="custom-primary" className="ms-2" onClick={e => setChange(1)}>
                                        <span className="me-2">+</span>
                                        {t('actions.create')} {t('common.readers')}
                                    </Button>    
                                </Col>
                            </Row>
                            <Col className="border rounded bg-white p-4">
                                <Row className="bg-primary-light rounded p-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>Ativo</Form.Label>
                                        <Form.Select
                                            value={ativo}
                                            onChange={e=> {setAtivo(e.target.value)}}
                                        >
                                            <option value={'*'}>Todos</option>
                                            <option value={'1'}>Sim</option>
                                            <option value={'0'}>Não</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={e=> {e.target.value == '' ? setNome('*') : setNome(e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Serial</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={e=> {e.target.value == '' ? setSerial('*') : setSerial(e.target.value)}}
                                        />
                                    </Form.Group>
                                </Row>
                                {!itemsLoaded ?
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                    </div>
                                :
                                    <>
                                        <Table className='mt-3'>
                                            <thead>
                                                <tr id="header">
                                                    <th>#</th>
                                                    <th>NOME</th>
                                                    <th>SERIAL</th>
                                                    <th>REGISTRO</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {items.map((item, index) => (
                                                    <tr className="edit" key={index} onClick={e=> {editItem(item)}}>
                                                        <th>{index + 1}</th>
                                                        <th>{item.nm_leitor}</th>
                                                        <th>{item.serial}</th>
                                                        <th>{item.reg_inventario}</th>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </>
                                }
                            </Col>
                        </Container>
                    </div>
                )
                break
            case 1:
                return(
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <Container fluid>
                            <Row>
                                <Col><h3>Leitores</h3></Col>
                                <Col className="d-flex justify-content-end">
                                    {editing == 1 ? (<Button variant="light" className="m-1" onClick={e=> {remove()}}><FiTrash2 className="me-2"/>EXCLUIR</Button>): ''}
                                    <Button variant="custom-danger" className="m-1 text-uppercase" onClick={e=> {save()}}><FiSave className="me-2"/> {t('actions.save')}</Button>
                                    <Button variant="light" className="m-1 text-uppercase" onClick={e => {cleaner()}}><FiArrowLeft className="me-2"/> {t('actions.exit')}</Button>
                                </Col>
                            </Row>
                            <Col>
                                <Row className='mb-3'>
                                    <Form.Group as={Col}>
                                        <Form.Label>Nome Leitor</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={nomeNew}
                                            onChange={e=> {setNomeNew(e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Serial</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={serialNew}
                                            onChange={e=> {setSerialNew(e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Ativo</Form.Label>
                                        <Form.Select
                                            value={ativoNew}
                                            onChange={e=> {setAtivoNew(e.target.value)}}
                                        >
                                            <option value="0">Não</option>
                                            <option value="1">Sim</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col}>
                                        <Form.Label>Periodo - Dias</Form.Label>
                                        <Form.Control
                                            type="number"
                                            min='0'
                                            value={periodoNew}
                                            onChange={e=> {setPeriodoNew(e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Inf. Compl 1</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={inf1New}
                                            onChange={e=> {setInf1New(e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Inf. Compl 2</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={inf2New}
                                            onChange={e=> {setInf2New(e.target.value)}}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col}>
                                        <Form.Label>Modelo</Form.Label>
                                        <Form.Select value={modeloL} onChange={e=> {setModeloL(e.target.value)}}>
                                            <option value=""></option>
                                            <option value="blu">Bluebird</option>
                                            <option value="dot">Dot 900</option>
                                            <option value="301">i300</option>
                                            <option value="bu1">Clock B1</option>
                                            <option value="tsl">Tsl 1128</option>
                                            <option value="imp">Impinj</option>
                                            <option value="inn">InovaCode</option>
                                            <option value="mdl">Midleware</option>
                                            <option value="nfc">NFC</option>  
                                            <option value="ebc">eBeacon</option> 
                                            <option value="ter">Terminal</option> 
                                            <option value="csv">CSV</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Potência</Form.Label>
                                        <Form.Select value={potenciaL} onChange={e=> {setPotenciaL(e.target.value)}}>
                                            <option value=""></option>
                                            <option value="5">Baixa</option>
                                            <option value="20">Média</option>
                                            <option value="30">Alta</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col}>
                                        <Form.Label>Seção</Form.Label>
                                        <Form.Select value={seccaoL} onChange={e=> {setSeccaoL(e.target.value)}}>
                                            <option value=""></option>
                                            <option value="0">Seção 0</option>
                                            <option value="1">Seção 1</option>
                                            <option value="2">Seção 2</option>
                                            <option value="3">Seção 3</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Estado</Form.Label>
                                        <Form.Select value={estadoL} onChange={e=> {setEstadoL(e.target.value)}}>
                                            <option value=""></option>
                                            <option value="0">A</option>
                                            <option value="1">A~B</option>
                                            <option value="2">B</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group>
                                        <Form.Label>Impressora</Form.Label>
                                        <Form.Control type="text" value={impressoraL} onChange={e=> {setImpressoraL(e.target.value)}}/>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col}>
                                        <Form.Label>Padrão</Form.Label>
                                        <Form.Select value={padraoI} onChange={e=> {setPadraoI(e.target.value)}}>
                                            <option value=""></option>
                                            <option value="manual">Manual</option>
                                            <option value="gtin">GTIN</option>
                                            <option value="sgtin">sGTIN</option>
                                            <option value="one">InovaOne</option>
                                            <option value="epc">EPC API</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Verificador Inicial</Form.Label>
                                        <Form.Control type="text" value={verificadorInicialI} onChange={e=> {setVerificadorInicialI(e.target.value)}}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Posição</Form.Label>
                                        <Form.Control type="text" value={posicaoI} onChange={e=> {setPosicaoI(e.target.value)}}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Comprimento</Form.Label>
                                        <Form.Control type="text" value={comprimentoI} onChange={e=> {setComprimentoI(e.target.value)}}/>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3 g-3'>
                                    <Form.Group>
                                        <Form.Label as={Col}>Local do Leitor</Form.Label>
                                        <Form.Select
                                            value={nivel1New}
                                            onChange={e=> {setNivel1New(e.target.value)}}
                                        >
                                            <option value=''>Nenhum</option>
                                            {nivel1List.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Nivel 2</Form.Label>
                                        <Form.Select
                                            value={nivel2New}
                                            onChange={e=> {setNivel2New(e.target.value)}}
                                        >
                                            <option value=''>Nenhum</option>
                                            {nivel2List.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Nivel 3</Form.Label>
                                        <Form.Select
                                            value={nivel3New}
                                            onChange={e=> {setNivel3New(e.target.value)}}
                                        >
                                            <option value=''>Nenhum</option>
                                            {nivel3List.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Nivel 4</Form.Label>
                                        <Form.Select
                                            value={nivel4New}
                                            onChange={e=> {setNivel4New(e.target.value)}}
                                        >
                                            <option value=''>Nenhum</option>
                                            {nivel4List.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col}>
                                        <Form.Label>Finalidade</Form.Label>
                                        <Form.Select
                                            value={finalidadeNew}
                                            onChange={e=> {setFinalidadeNew(e.target.value)}}
                                        >
                                            <option value='1'>Coletas</option>
                                            <option value='0'>Inventários</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Desabilitar Items</Form.Label>
                                        <Form.Select
                                            value={disableItems}
                                            onChange={e=> {setDisableItems(e.target.value)}}
                                        >
                                            <option value={true}>Sim</option>
                                            <option value={false}>Não</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                {finalidadeNew == 1 ? 
                                    <Row className='mb-3'>
                                        <Form.Group as={Col}>
                                            <Form.Label>Destino das Leituras</Form.Label>
                                            <Form.Select
                                                value={destNivel1New}
                                                onChange={e=> {setDestNivel1New(e.target.value)}}
                                            >
                                                <option value=''>Nenhum</option>
                                                {destNivel1List.map((item, index) => (
                                                    <option key={index} value={item._id}>{item.descricao}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nivel 2</Form.Label>
                                            <Form.Select
                                                value={destNivel2New}
                                                onChange={e=> {setDestNivel2New(e.target.value)}}
                                            >
                                                <option value=''>Nenhum</option>
                                                {destNivel2List.map((item, index) => (
                                                    <option key={index} value={item._id}>{item.descricao}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nivel 3</Form.Label>
                                            <Form.Select
                                                value={destNivel3New}
                                                onChange={e=> {setDestNivel3New(e.target.value)}}
                                            >
                                                <option value=''>Nenhum</option>
                                                {destNivel3List.map((item, index) => (
                                                    <option key={index} value={item._id}>{item.descricao}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nivel 4</Form.Label>
                                            <Form.Select
                                                value={destNivel4New}
                                                onChange={e=> {setDestNivel4New(e.target.value)}}
                                            >
                                                <option value=''>Nenhum</option>
                                                {destNivel4List.map((item, index) => (
                                                    <option key={index} value={item._id}>{item.descricao}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>
                                    : 
                                    <Row className='mb-3'>
                                        <Form.Group>
                                            <Form.Label>Reverso</Form.Label>
                                            <Form.Select
                                                value={reversoNew}
                                                onChange={e=> {setReversoNew(e.target.value)}}
                                            >
                                                <option value='0'>Não</option>
                                                <option value='1'>Sim</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>
                                }
                                <Row className='mb-3'>
                                    <h6>Saída</h6>
                                    <Form.Group as={Col}>
                                        <Form.Label>Nivel 1</Form.Label>
                                        <Form.Select
                                            value={saidaNivel1New}
                                            onChange={e=> {setsaidaNivel1New(e.target.value)}}
                                        >
                                            <option value=''>Nenhum</option>
                                            {saidaNivel1List.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Nivel 2</Form.Label>
                                        <Form.Select
                                            value={saidaNivel2New}
                                            onChange={e=> {setsaidaNivel2New(e.target.value)}}
                                        >
                                            <option value=''>Nenhum</option>
                                            {saidaNivel2List.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Nivel 3</Form.Label>
                                        <Form.Select
                                            value={saidaNivel3New}
                                            onChange={e=> {setsaidaNivel3New(e.target.value)}}
                                        >
                                            <option value=''>Nenhum</option>
                                            {saidaNivel3List.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Nivel 4</Form.Label>
                                        <Form.Select
                                            value={saidaNivel4New}
                                            onChange={e=> {setsaidaNivel4New(e.target.value)}}
                                        >
                                            <option value=''>Nenhum</option>
                                            {saidaNivel4List.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group>
                                        <Form.Label>Observação</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={observacaoNew}
                                            onChange={e=> {setObservacaoNew(e.target.value)}}
                                        />
                                    </Form.Group>
                                </Row>
                            </Col>
                        </Container>
                    </div>
                )
                break
            case 5:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <Container fluid>
                            <Col className="d-flex justify-content-between" id="colection">
                                <h3>{t('actions.generate')} CSV</h3>
                                <Button variant="light" className="text-uppercase" onClick={e => {setChange(0)}}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                            </Col>
                            <ExportCSV headers={headersCSV} listItens={csv()} modulo={'leitores'}/>
                        </Container>                  
                    </div>
                )
                break
            case 6:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <Container fluid>
                            <Col className="d-flex justify-content-between" id="colection">
                                <h3>{t('actions.generate')} CSV</h3>
                                <Button variant="light" className="text-uppercase" onClick={e => {setChange(0)}}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                            </Col>
                            <ExportPDF headers={headersCSV} listItens={csv()} modulo={'leitores'}/>
                        </Container>                  
                    </div>
                )
                break
            default:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>Error</div>
                )
                break
        }
    }

    function editItem(item) {
        setChange(1)
        setEditing(1)
        setLeitorId(item._id)
        setNomeNew(item.nm_leitor)
        setSerialNew(item.serial)
        setAtivoNew(item.ativo)
        setPeriodoNew(item.periodo ? item.periodo : '')
        setInf1New(item.inf_compl_1 ? item.inf_compl_1 : '')
        setInf2New(item.inf_compl_2 ? item.inf_compl_2 : '')
        if(item.id_nivel_loc_2 != ''){
            nivel2Get(item.id_nivel_loc_1, 'orig')
        }
        if(item.id_nivel_loc_3 != ''){
            nivel3Get(item.id_nivel_loc_2, 'orig')
        }
        if(item.id_nivel_loc_4 != ''){
            nivel4Get(item.id_nivel_loc_3, 'orig')
        }
        setNivel1New(item.id_nivel_loc_1)
        setNivel2New(item.id_nivel_loc_2)
        setNivel3New(item.id_nivel_loc_3)
        setNivel4New(item.id_nivel_loc_4)
        setFinalidadeNew(item.reg_coleta)
        setDisableItems(item?.inativar_itens || false)
        if(item.reg_coleta == '1'){
            if(item.dest_id_nivel_loc_2 != ''){
                nivel2Get(item.dest_id_nivel_loc_1, 'dest')
            }
            if(item.dest_id_nivel_loc_3 != ''){
                nivel3Get(item.dest_id_nivel_loc_2, 'dest')
            }
            if(item.dest_id_nivel_loc_4 != ''){
                nivel4Get(item.dest_id_nivel_loc_3, 'dest')
            }
        }
        setDestNivel1New(item.dest_id_nivel_loc_1)
        setDestNivel2New(item.dest_id_nivel_loc_2)
        setDestNivel3New(item.dest_id_nivel_loc_3)
        setDestNivel4New(item.dest_id_nivel_loc_4)
        setsaidaNivel1New(item.saida_dest_id_nivel_loc_1)
        setsaidaNivel2New(item.saida_dest_id_nivel_loc_2)
        setsaidaNivel3New(item.saida_dest_id_nivel_loc_3)
        setsaidaNivel4New(item.saida_dest_id_nivel_loc_4)
        setReversoNew(item.inventario_reverso)
        setObservacaoNew(item.observacao)
        setParceiroIdNew(item.id_parceiro)
        setDestParceiroIdNew(item.dest_id_parceiro)
        setRegInventarioNew(item.reg_inventario)
        setModeloL(item.leitor_modelo ? item.leitor_modelo : '')
        setSerialL(item.leitor_serial ? item.leitor_serial : '')
        setPotenciaL(item.leitor_potencia ? item.leitor_potencia : '')
        setSeccaoL(item.leitor_secao ? item.leitor_secao : '')
        setEstadoL(item.leitor_estado ? item.leitor_estado : '')
        setImpressoraL(item.impressora_serial ? item.impressora_serial : '')
        setPadraoI(item.item_padrao ? item.item_padrao : '')
        setVerificadorInicialI(item.item_verif_inicial ? item.item_verif_inicial : '')
        setPosicaoI(item.item_posicao ? item.item_posicao : '')
        setComprimentoI(item.item_comprimento ? item.item_comprimento : '')
    }

    function cleaner() {
        setChange(0)
        setEditing(0)
        setLeitorId('0')
        setNomeNew('')
        setSerialNew('')
        setAtivoNew('1')
        setPeriodoNew('')
        setInf1New('')
        setInf2New('')
        setNivel2List([])
        setNivel3List([])
        setNivel4List([])
        setNivel1New('')
        setNivel2New('')
        setNivel3New('')
        setNivel4New('')
        setFinalidadeNew('1')
        setDestNivel2List([])
        setDestNivel3List([])
        setDestNivel4List([])
        setDestNivel1New('')
        setDestNivel2New('')
        setDestNivel3New('')
        setDestNivel4New('')
        setReversoNew('0')
        setObservacaoNew('')
        setParceiroIdNew('')
        setDestParceiroIdNew('')
        setRegInventarioNew(false)
        
        setModeloL('')
        setSerialL('')
        setPotenciaL('')
        setSeccaoL('')
        setEstadoL('')
        setImpressoraL('')
        setPadraoI('')
        setVerificadorInicialI('')
        setPosicaoI('')
        setComprimentoI('')
    }

    function getID(){
        return 'xxxxxxxx-yxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
        });
    }
    
    function save() {
        Swal.fire({
            title: "Atenção !",
            text: leitorId == '0' ? "Deseja realmente registrar este leitor ?" : "Deseja realmente editar este leitor ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        }).then(
            result => {
                if(result.isConfirmed) {
                    if(nomeNew == ''){
                        Swal.fire({
                            title: "Ops... !",
                            text: 'Informe o nome do Leitor',
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }else{
                        let option = {
                            headers: { 'Content-Type': ['application/json'] }
                        }
                
                        let reg=[]
                        let generateId = leitorId == '0' ? getID() : leitorId
                        reg.push({
                            ativo: ativoNew,
                            dest_id_nivel_loc_1: finalidadeNew == '0' ? "" : destNivel1New,
                            dest_id_nivel_loc_2: finalidadeNew == '0' ? "" : destNivel2New,
                            dest_id_nivel_loc_3: finalidadeNew == '0' ? "" : destNivel3New,
                            dest_id_nivel_loc_4: finalidadeNew == '0' ? "" : destNivel4New,
                            saida_dest_id_nivel_loc_1: saidaNivel1New ?? "",
                            saida_dest_id_nivel_loc_2: saidaNivel2New ?? "",
                            saida_dest_id_nivel_loc_3: saidaNivel3New ?? "",
                            saida_dest_id_nivel_loc_4: saidaNivel4New ?? "",
                            dest_id_parceiro: destParceiroIdNew,
                            id_conta: sessionStorage.getItem('idConta'),
                            _id: generateId,
                            id_nivel_loc_1: nivel1New == '' ? '' : nivel1New,
                            id_nivel_loc_2: nivel2New == '' ? '' : nivel2New,
                            id_nivel_loc_3: nivel3New == '' ? '' : nivel3New,
                            id_nivel_loc_4: nivel4New == '' ? '' : nivel4New,
                            id_parceiro: parceiroIdNew,
                            inventario_reverso: reversoNew,
                            nm_leitor: nomeNew,
                            observacao: observacaoNew,
                            reg_coleta: finalidadeNew,
                            inativar_itens: disableItems,
                            reg_inventario: regInventarioNew,
                            serial: serialNew,
                            periodo: periodoNew,
                            inf_compl_1: inf1New,
                            inf_compl_2: inf2New,
            
                            leitor_modelo: modeloL,
                            leitor_serial: serialNew,
                            leitor_potencia: potenciaL,
                            leitor_secao: seccaoL,
                            leitor_estado: estadoL,
                            impressora_serial: impressoraL,
                            item_padrao: padraoI,
                            item_verif_inicial: verificadorInicialI,
                            item_posicao: posicaoI,
                            item_comprimento: comprimentoI,
                        })
                        
                        let noReader = true
                        let myReader = false
                        let registredBy = ''
                        api.get(`/leitor/*/*/*/${serialNew}/*`).then(
                            search => {
                                if(search.data.length != 0){
                                    let inTheList = false
                                    search.data.map((item) => {
                                        if(item.serial == serialNew) {
                                            inTheList = true
                                            registredBy = item.id_conta
                                        }
                                    })
                                    noReader = !inTheList
                                }
                            }
                        ).finally(
                            result => {
                                if(noReader || (leitorId != '0' && registredBy == sessionStorage.getItem('idConta'))) {
                                    api.post('/leitor', reg, option
                                    ).then(
                                        response => {
                                            leitoresGet()
                                            Swal.fire({
                                                title: "Sucesso !",
                                                text: 'Leitor salvo com sucesso',
                                                icon: 'success',
                                                showConfirmButton: false,
                                                timerProgressBar: true,
                                                timer: '2500'
                                            })
                                            cleaner()
                                        },
                                        response => {
                                            Swal.fire({
                                                title: "Ops... !",
                                                text: 'Algo deu errado, por favor tente novamente mais tarde',
                                                icon: 'error',
                                                showConfirmButton: false,
                                                timerProgressBar: true,
                                                timer: '2500'
                                            })
                                        }
                                    )
                                }else{
                                    api.get(`/conta/${registredBy}/*/*/*`).then(
                                        response => {
                                            Swal.fire({
                                                title: "Ops... !",
                                                text: `O serial deste leitor já foi registrado em uma conta...\nConsultar o email: ${response.data[0].email}`,
                                                icon: 'error',
                                                showConfirmButton: false,
                                                timerProgressBar: true,
                                                timer: '5000'
                                            })
                                        }
                                    )
                                }
                            }
                        )
                    }
                }
            }
        )
        
    }

    function remove() {
        Swal.fire({
            title: "Atenção !",
            html: `Leitor: ${nomeNew}<br/>Deseja realmente excluir este leitor ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        }).then(
            result => {
                if(result.isConfirmed) {
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
                    api.delete(`/leitor/${leitorId}`, option
                    ).then(
                        response => {
                            leitoresGet()
                            Swal.fire({
                                title: "Sucesso !",
                                text: 'Leitor foi excluido de sua base',
                                icon: 'success',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                            cleaner()
                        },
                        response => {
                            Swal.fire({
                                title: "Ops... !",
                                text: 'Algo deu errado, por favor tente novamente mais tarde',
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        }
                    )
                }
            }
        )
    }

    return (
        <div className="mainContainer"
        style={JSON.parse(sessionStorage.getItem('collors'))}
        >
            <Menu/>
            <div id="background">
                {changeFunction(change)}
            </div>
        </div>
    )
}

export default Readers