import { Box, Button, Chip, Container, Paper, Stack, Typography } from "@mui/material";

import { t } from "i18next";

import { DataGrid, GridColDef, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid';

import { deepPurple, green, red } from "@mui/material/colors";
import { height } from "@mui/system";

const columns = [
    { 
        field: 'id', 
        headerName: 'ID', 
        width: 90
    },
    {
      field: 'categoria',
      headerName: 'Categoria',
      width: 200  
    },
    {
        field: 'tag',
        headerName: 'Tag',
        width: 300
    },
    {
        field: 'dt_movimentacao',
        headerName: 'Data',
        type: 'dateTime',
        width: 200,
        valueGetter: (value, row) => new Date(row.dt_movimentacao),
    },
    {
        field: 'local',
        headerName: 'Localização',
        width: 300,
        renderCell: (params) => (
            <>
                {params.row.nivel_1} <br />
                {params.row.nivel_2} <br />
                {params.row.nivel_3} <br />
                {params.row.nivel_4}
            </>
        )   
    },
    {
        field: 'qtde_lido',
        headerName: 'Quantidade Lida',
        hide: true
    },
    {
        field: 'nm_leitor',
        headerName: 'Nome do leitor',
        hide: true,
        width: 300
    },
    {
        field: 'serial',
        headerName: 'Serial do Leitor',
        hide: true,
        width: 100
    }
  ];

//--------------------------------------------------------

export default function TableTracking({movList = [], categoriaList}){
    function CustomToolbar() {
        return (
          <GridToolbarContainer sx={{p: 1}}>
            <GridToolbarColumnsButton
              slotProps={{
                tooltip: { title: 'Colunas' },
                button: { variant: 'outlined' },
              }}
            />
          </GridToolbarContainer>
        );
    }

    function getCategory(_id){
        const category = categoriaList.find(categoria => categoria._id === _id)?.descricao || 'Sem descrição'
        return category
    }

    const rows = movList.map((item, index) => ({
        ...item,
        ...item.id_item,
        ...item.id_leitor,
        categoria: getCategory(item.id_item.id_categoria),
        nivel_1: item?.id_nivel_loc1?.descricao || '',
        nivel_2: item?.id_nivel_loc2?.descricao || '',
        nivel_3: item?.id_nivel_loc3?.descricao || '',
        nivel_4: item?.id_nivel_loc4?.descricao || '',
        id: index + 1
    }));


    return (
        <>
            <Stack 
                component={Paper} 
                sx={{
                    p: 1,
                    borderRadius: 2,
                    "& .MuiDataGrid-cell": {
                        display: "flex",
                        alignItems: "center"
                    }
                }}
            >
                <DataGrid
                    rows={rows}
                    columns={columns}
                    density="comfortable"
                    disableRowSelectionOnClick={true}
                    sx={{
                        border: 0,
                        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                            outline: "none"
                        }
                    }}
                    getRowHeight={() => 'auto'}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'dt_movimentacao', sort: 'desc' }],
                        },
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                        columns: {
                            columnVisibilityModel: {
                                qtde_lido: false,
                                serial: false,
                                nm_leitor: false
                            }
                        }
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    slots={{ toolbar: CustomToolbar }}
                    slotProps={{
                        columnsManagement: {
                            toggleAllMode: 'filteredOnly', 
                        },
                    }}
                />
            </Stack>
        </>
    )
}