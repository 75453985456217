import { useEffect, useState } from "react";

import { Grid, Stack, Typography } from "@mui/material";

import { common } from "@mui/material/colors";

import WidgetItemsByLevel from "../../Widgets/WidgetItemsByLevel.js";

import TableTracking from "./TableTracking.js";

import ItemsByLevels from "./ItemsByLevels.js";

import ItemsByDays from "./ItemsByDays.js";

import api from "../../../../services/api.js";

//------------------------------------------------------------------------------

export default function CBA(){
    const [loading, setLoading] = useState(false)

    const [movList, setMovList] = useState([])

    const [categoriaList, setCategoriaList] = useState([])

    useEffect(()=>{
        function getData(){
            setLoading(true)
            api.get(`/reg_mov_itens/${sessionStorage.getItem('idConta')}/*/*/*/*/*/*/*?dt_registro_inicial=*&dt_registro_final=*`).then(
                response=>{
                    setMovList(response.data)
                    setLoading(false)
                }
            )
        }

        getData()
    },[])

    useEffect(()=>{
        function getCategorias(){
            setLoading(true)

            api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/*`).then(
                response=>{
                    response.data.pop()
                    if(response.data.length){
                        setCategoriaList(response.data)
                        setLoading(false)
                    }
                }
            )
        }

        getCategorias()
    },[])

    return (
        <>
            <Stack spacing={3}>

                <Typography variant="h1">Dashboard</Typography>

                <Grid container justifyContent="space-between" spacing={1}>
                    <Grid item md={4}>
                        <ItemsByDays/>
                    </Grid>
                    <Grid item md={8}>
                        <ItemsByLevels/>
                    </Grid>
                </Grid>

                <TableTracking movList={movList} categoriaList={categoriaList}/>
            </Stack>
        </>
    )
}